import React, { useState } from "react";
import { useBalance } from "eth-hooks";
import { RPC_POLL_TIME } from "../constants";
const { utils } = require("ethers");

/**
  ~ What it does? ~

  Displays a balance of given address in ether & dollar

  ~ How can I use? ~

  <MyBalance
    address={address}
    provider={mainnetProvider}
    price={price}
  />

  ~ If you already have the balance as a bignumber ~
  <MyBalance
    balance={balance}
    price={price}
  />

  ~ Features ~

  - Provide address={address} and get balance corresponding to given address
  - Provide provider={mainnetProvider} to access balance on mainnet or any other network (ex. localProvider)
  - Provide price={price} of ether and get your balance converted to dollars
**/

export default function MyBalance(props) {
  const [dollarMode, setDollarMode] = useState(props.initialDollarMode || false);

  let floatBalance = 0.0;

  if (props.balance) {
    const etherBalance = utils.formatEther(props.balance);
    parseFloat(etherBalance).toFixed(2);
    floatBalance = parseFloat(etherBalance);
  }

  if (props.balanceSinceUpdate) {
    floatBalance += props.balanceSinceUpdate;
  }

  let displayBalance = floatBalance.toFixed(2);

  const price = props.price || props.dollarMultiplier || 1;

  if (dollarMode) {
    displayBalance = "$" + (floatBalance * price).toFixed(2);
  }
  else {
    displayBalance = floatBalance.toFixed(2) + " ETH";
  }

  return (
    <span
      style={{
        verticalAlign: "middle",
        fontSize: props.size ? props.size : 48,
        padding: 8,
        cursor: "pointer",
      }}
      onClick={() => {
        setDollarMode(!dollarMode);
      }}
    >
      {displayBalance}
    </span>
  );
}
