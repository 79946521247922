import { Button, Card, DatePicker, Divider, Input, Progress, Slider, Spin, Switch, Row, Col, Typography, Tooltip } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { BigNumber, utils } from "ethers";
import { SyncOutlined } from "@ant-design/icons";

import { Address, Balance, Events, TokenBalance } from "../components";
import { useContractReader } from "eth-hooks";

import Confetti from 'react-dom-confetti';

export default function JsonPage({
  tokenIdFromURL,
  purpose,
  address,
  mainnetProvider,
  localProvider,
  yourLocalBalance,
  price,
  tx,
  readContracts,
  writeContracts,
}) {
  //const [newPurpose, setNewPurpose] = useState("loading...");

  // Read the SPNY balance of the current player
  //const spnyBalance = useContractReader(readContracts, "MerlinsGambitQ1", "balanceOf", [address]);
  const spnyBalance = useContractReader(readContracts, "MerlinsGambitQ1", "balanceOfBatch", [[address], [1]]);

  //const totalSupply = useContractReader(readContracts, "MerlinsGambitQ1", "totalSupply");
  const totalSilverPenniesSupply = useContractReader(readContracts, "MerlinsGambitQ1", "getTotalSilverPenniesSupply");

  const [attemptingPull, setAttemptingPull] = useState(false);
  const [regularMiningRewardWon, setRegularMiningRewardWon] = useState(false);
  const [grandMiningRewardWon, setGrandMiningRewardWon] = useState(false);
  const [randomMiningRewardWon, setRandomMiningRewardWon] = useState(false);
  const [tokensMinted, setTokensMinted] = useState(false);
  const [swordStaysPut, setSwordStaysPut] = useState(false);

  const [NFTMinted, setNFTMinted] = useState(false);

  const [nftName, setNFTName] = useState('');
  const [nftNameOffensiveSword, setNFTNameOffensiveSword] = useState('');
  const [nftNameDefensiveSword, setNFTNameDefensiveSword] = useState('');

  //const [currentMessageIndex, setCurrentMessageIndex] = useState(0);  //Squirrel  
  //const [currentMessageIndexBird, setCurrentMessageIndexBird] = useState(0);
  //const [birdMessagesFinished, setBirdMessagesFinished] = useState(false);  

  const [currentMessageIndex, setCurrentMessageIndex] = useState(-1);   //Squirrel 
  const [currentMessageIndexBird, setCurrentMessageIndexBird] = useState(-1);

  const [displaySquirrelMessage, setDisplaySquirrelMessage] = useState(false);
  const [displayBirdMessage, setDisplayBirdMessage] = useState(false);

  const [mintReplicaTransactionSubmitted, setMintReplicaTransactionSubmitted] = useState(false);

  const [loadingNFT, setLoadingNFT] = useState(false);
  const [loadingOnchainNFT, setLoadingOnchainNFT] = useState(false);

  //const excaliburSwordData = '000';

  const [backgroundMusic, setBackgroundMusic] = useState(null);
  const [isMuted, setIsMuted] = useState(true);


  const [sharpnessData, setSharpnessData] = useState(null);
  const [sharpnessDataOffensiveSword, setSharpnessDataOffensiveSword] = useState(null);
  const [sharpnessDataDefensiveSword, setSharpnessDataDefensiveSword] = useState(null);

  const getSharpnessData = async (tokenId, swordType = null) => {
    if (readContracts && readContracts.MerlinsGambitQ1) {
      try {
        const sharpnessString = await readContracts.MerlinsGambitQ1.getSharpnessString(tokenId);

        if (swordType) {
          if (swordType === "OffensiveSword") {
            setSharpnessDataOffensiveSword(sharpnessString);
            // if (tokenIdOffensiveSword && tokenIdDefensiveSword) {
            //   await getDuelMatchUpRating(tokenIdOffensiveSword, tokenIdDefensiveSword);
            //  }
          } else if (swordType === "DefensiveSword") {
            setSharpnessDataDefensiveSword(sharpnessString);
          } else {
            // Handle error: Invalid swordType
            console.error("Invalid swordType:", swordType);
          }
        } else {
          setSharpnessData(sharpnessString);
          console.log(`Sharpness for token ID ${tokenId}:`, sharpnessString);
        }

      } catch (error) {
        console.error(`Error fetching sharpness for token ID ${tokenId}:`, error);
      }
    }
  };




  const [swordRatingData, setSwordRatingData] = useState(null);
  const [swordRatingDataOffensiveSword, setSwordRatingDataOffensiveSword] = useState(null);
  const [swordRatingDataDefensiveSword, setSwordRatingDataDefensiveSword] = useState(null);

  const getSwordRatingData = async (tokenId, swordType = null) => {
    if (readContracts && readContracts.MerlinsGambitQ1) {
      try {
        const swordRating = await readContracts.MerlinsGambitQ1.getSwordRatingFromTokenId(tokenId);
        const swordRatingString = swordRating.toString();  // convert BigNumber to string

        if (swordType) {
          if (swordType === "OffensiveSword") {
            setSwordRatingDataOffensiveSword(swordRatingString);
          } else if (swordType === "DefensiveSword") {
            setSwordRatingDataDefensiveSword(swordRatingString);
          } else {
            // Handle error: Invalid swordType
            console.error("Invalid swordType:", swordType);
          }
        } else {
          setSwordRatingData(swordRatingString);
          console.log(`Sword rating for token ID ${tokenId}:`, swordRatingString);
        }

      } catch (error) {
        console.error(`Error fetching sword rating for token ID ${tokenId}:`, error);
      }
    }
  };





  const [tokenData, setTokenData] = useState('');

  {/*
  const getTokenData = async (tokenId) => {
    if (readContracts && readContracts.MerlinsGambitQ1) {
      try {
        const data = await readContracts.MerlinsGambitQ1.tokenData(tokenId);
        //const data = await readContracts.MerlinsGambitQ1.NFT_DATA();
        setTokenData(data);
        console.log("Token Data Print Statement: ", data);
        setLoadingOnchainNFT(false);
        setShowCanvas(true); // Set showCanvas state to true
        setColorString(data);
        drawColorGrid(data);
      } catch (error) {
        console.error(`Error fetching token data for token ID ${tokenId}:`, error);
        setShowCanvas(false); // Set showCanvas state to true
        setLoadingOnchainNFT(false);
        setTransactionErrorCheck(true);
      }
    }
    else {
      setShowCanvas(false); // Set showCanvas state to true
      setLoadingOnchainNFT(false);
    }
  };
*/}

  const [swordImageFile, setSwordImageFile] = useState('');
  const [swordImageFileOffensiveSword, setSwordImageFileOffensiveSword] = useState('');
  const [swordImageFileDefensiveSword, setSwordImageFileDefensiveSword] = useState('');

  const [swordImageFileBackUp, setSwordImageFileBackUp] = useState('');

  const [transactionErrorCheck, setTransactionErrorCheck] = useState('');
  const [showTransactionErrorMessage, setShowTransactionErrorMessage] = useState(false);

  const [filenameState, setFilenameState] = useState("");



  const getSwordDigitKeyData = async (tokenId, swordType = null) => {
    if (readContracts && readContracts.MerlinsGambitQ1) {
      try {
        const data = await readContracts.MerlinsGambitQ1.swordDigitKey(tokenId);
        // Convert the data array to a string, with no delimiter, and create the file path
        const filename = `sword/${data.join('')}.png`;

        setFilenameState(filename);

        if (swordType) {
          if (swordType === "OffensiveSword") {
            setSwordImageFileOffensiveSword(filename);
          } else if (swordType === "DefensiveSword") {
            setSwordImageFileDefensiveSword(filename);
          } else {
            // Handle error: Invalid swordType
            console.error("Invalid swordType:", swordType);
          }
        } else {
          setSwordImageFile(filename);
          console.log("Sword image file path: ", filename);
          setSwordImageFileBackUp(filename);
        }

      } catch (error) {
        console.error(`Error fetching sword digit key data for token ID ${tokenId}:`, error);
      }
    }
  };

  const getDuelsWonById = async (tokenId) => {
    if (readContracts && readContracts.MerlinsGambitQ1) {
      try {
        const duelsWon = await readContracts.MerlinsGambitQ1.getDuelsWon(tokenId);
        // console.log(`Duels won by token ID ${tokenId}: ${duelsWon.toString()}`);
        //setDuelsWonById(duelsWon.toString());
      } catch (error) {
        console.error(`Error fetching duels won data for token ID ${tokenId}:`, error);
      }
    }
  };

  const [duelCount, setDuelCount] = useState(0);
  const [duelCountOffensiveSword, setDuelCountOffensiveSword] = useState(0);
  const [duelCountDefensiveSword, setDuelCountDefensiveSword] = useState(0);

  const getDuelCountByTokenId = async (tokenId, swordType = null) => {
    if (readContracts && readContracts.MerlinsGambitQ1) {
      try {
        const count = await readContracts.MerlinsGambitQ1.getDuelCount(tokenId);
        const countString = count.toString();  // convert the BigNumber to a string

        if (swordType) {
          if (swordType === "OffensiveSword") {
            setDuelCountOffensiveSword(countString);
          } else if (swordType === "DefensiveSword") {
            setDuelCountDefensiveSword(countString);
          } else {
            // Handle error: Invalid swordType
            console.error("Invalid swordType:", swordType);
          }
        } else {
          setDuelCount(countString);
          console.log("Duel Count Print Statement: ", countString);
        }

      } catch (error) {
        console.error(`Error fetching duel count of token ID ${tokenId}:`, error);
      }
    }
  };




  const [tokenOwner, setTokenOwner] = useState('');
  const [tokenOwnerOffensiveSword, setTokenOwnerOffensiveSword] = useState('');
  const [tokenOwnerDefensiveSword, setTokenOwnerDefensiveSword] = useState('');

  const getTokenOwner = async (tokenId, swordType = null) => {
    if (readContracts && readContracts.MerlinsGambitQ1) {
      try {
        const owner = await readContracts.MerlinsGambitQ1.ownerOf(tokenId);

        if (swordType) {
          if (swordType === "OffensiveSword") {
            setTokenOwnerOffensiveSword(owner);
          } else if (swordType === "DefensiveSword") {
            setTokenOwnerDefensiveSword(owner);
          } else {
            // Handle error: Invalid swordType
            console.error("Invalid swordType:", swordType);
          }
        } else {
          setTokenOwner(owner);
          setAddressNFTOwner(owner);
          console.log("Token Owner Print Statement: ", owner);
        }

      } catch (error) {
        console.error(`Error fetching owner of token ID ${tokenId}:`, error);
      }
    }
  };



  const [swordHilt, setSwordHilt] = useState();
  const [swordGrip, setSwordGrip] = useState();
  const [swordBlade, setSwordBlade] = useState();
  const [swordJewel, setSwordJewel] = useState();

  const [swordHiltOffensiveSword, setSwordHiltOffensiveSword] = useState();
  const [swordGripOffensiveSword, setSwordGripOffensiveSword] = useState();
  const [swordBladeOffensiveSword, setSwordBladeOffensiveSword] = useState();
  const [swordJewelOffensiveSword, setSwordJewelOffensiveSword] = useState();

  const [swordHiltDefensiveSword, setSwordHiltDefensiveSword] = useState();
  const [swordGripDefensiveSword, setSwordGripDefensiveSword] = useState();
  const [swordBladeDefensiveSword, setSwordBladeDefensiveSword] = useState();
  const [swordJewelDefensiveSword, setSwordJewelDefensiveSword] = useState();

  const getSwordProperties = async (tokenId, swordType = null) => {
    if (readContracts && readContracts.MerlinsGambitQ1) {
      try {
        const properties = await readContracts.MerlinsGambitQ1.getSwordPropertiesByTokenId(tokenId);

        if (swordType) {
          if (swordType === "OffensiveSword") {
            setSwordHiltOffensiveSword(properties[0]);
            setSwordGripOffensiveSword(properties[1]);
            setSwordBladeOffensiveSword(properties[2]);
            setSwordJewelOffensiveSword(properties[3]);
          } else if (swordType === "DefensiveSword") {
            setSwordHiltDefensiveSword(properties[0]);
            setSwordGripDefensiveSword(properties[1]);
            setSwordBladeDefensiveSword(properties[2]);
            setSwordJewelDefensiveSword(properties[3]);
          } else {
            // Handle error: Invalid swordType
            console.error("Invalid swordType:", swordType);
          }
        } else {
          setSwordHilt(properties[0]);
          setSwordGrip(properties[1]);
          setSwordBlade(properties[2]);
          setSwordJewel(properties[3]);
        }

      } catch (error) {
        console.error(`Error fetching sword properties of token ID ${tokenId}:`, error);
      }
    }
  };


  const processTokenId = async (tokenId, swordType = null, fromDuelSlider = false) => {
    console.log("processTokenId: ", tokenId);
    console.log("tokenId: ", tokenId);
    let name;
    if (tokenId != 2) {
      name = 'Replica ' + tokenId;
      //getTokenData(tokenId);
      getSwordDigitKeyData(tokenId, swordType);
      getSwordProperties(tokenId, swordType);
      getSharpnessData(tokenId, swordType);
      getSwordRatingData(tokenId, swordType);
      getTokenOwner(tokenId, swordType);
      getDuelCountByTokenId(tokenId, swordType);
      setTokenIdNFT(tokenId);
      setNFTName(name);

    } else {
      setNFTName('Excalibur');
      console.log("nftName: ", nftName);
      setSwordHilt('Gold');
      setSwordGrip('Leather');
      setSwordBlade('Merlin Steel');
      setSwordJewel('Green Emerald');
      setSharpnessData('Honed');
      setSwordRatingData('100');
      setSwordImageFile(`sword/0000.png`);
      setSwordImageFileBackUp(`sword/0000.png`);
      setDuelCount('100');
    }
  };


  const [ownedTokenIds, setOwnedTokenIds] = useState([]);

  const [addressNFTOwner, setAddressNFTOwner] = useState('');

  const [tokenIdNFT, setTokenIdNFT] = useState();



  // Function to fetch tokenIds owned by the user
  const fetchOwnedTokenIds = async (address) => {
    //await getExcaliburFreedStatus();
    if (readContracts && readContracts.MerlinsGambitQ1) {
      try {
        processTokenId(tokenIdFromURL);
        //processTokenId(4);
      } catch (error) {
        console.error(`Error processTokenId for json page`);
      }
    }
  };




  useEffect(() => {
    fetchOwnedTokenIds(address);
  }, [readContracts.MerlinsGambitQ1, address]);








  let jsonOutput = null;
  if (nftName && swordHilt && swordGrip && swordBlade && swordJewel && filenameState) {
    const jsonNFTData = {
      "name": nftName,
      "image": `https://merlinsgambit.com/` + filenameState,
      "attributes": [
        {
          "trait_type": "Hilt",
          "value": swordHilt
        },
        {
          "trait_type": "Grip",
          "value": swordGrip
        },
        {
          "trait_type": "Blade",
          "value": swordBlade
        },
        {
          "trait_type": "Jewel",
          "value": swordJewel
        }
      ]
    };
    jsonOutput = <div><pre>{JSON.stringify(jsonNFTData, null, 2)}</pre></div>;
  } else {
    //  jsonOutput = <div>Fetching data...</div>;
  }


  return (
    <div style={{ overflowX: 'hidden' }}>


      <div>
        {jsonOutput}
      </div>


    </div >
  );
}