import React, { useState, useEffect } from "react";
import { useBalance } from "eth-hooks";
import { RPC_POLL_TIME } from "../constants";
const { utils } = require("ethers");

/**
  ~ What it does? ~

  Displays a balance of given address in ether & dollar

  ~ How can I use? ~

  <Balance
    address={address}
    provider={mainnetProvider}
    price={price}
  />

  ~ If you already have the balance as a bignumber ~
  <Balance
    balance={balance}
    price={price}
  />

  ~ Features ~

  - Provide address={address} and get balance corresponding to given address
  - Provide provider={mainnetProvider} to access balance on mainnet or any other network (ex. localProvider)
  - Provide price={price} of ether and get your balance converted to dollars
**/

export default function Balance(props) {
  const [dollarMode, setDollarMode] = useState(false);
  const [isBold, setIsBold] = useState(false); // New state for bold style

  let localProviderPollingTime = RPC_POLL_TIME;

  const balance = useBalance(props.provider, props.address, localProviderPollingTime);
  let floatBalance = parseFloat("0.00");
  let usingBalance = balance;

  if (typeof props.balance !== "undefined") usingBalance = props.balance;
  if (typeof props.value !== "undefined") usingBalance = props.value;

  if (usingBalance) {
    const etherBalance = utils.formatEther(usingBalance);
    parseFloat(etherBalance).toFixed(3);
    floatBalance = parseFloat(etherBalance);
  }

  let displayBalance = floatBalance.toFixed(3);

  const price = props.price || props.dollarMultiplier || 1;

  if (dollarMode) {
    displayBalance = "$" + (floatBalance * price).toFixed(2);
  }
  else {
    displayBalance = floatBalance.toFixed(3) + " ETH";
  }

  // useEffect to detect balance changes
  useEffect(() => {
    if (balance) {
      setIsBold(true);
      const timer = setTimeout(() => setIsBold(false), 3000); // Revert after 3 seconds

      return () => clearTimeout(timer); // Cleanup timeout
    }
  }, [balance]); // Dependency array with balance

  // Check if props.address is null or undefined
  if (props.address == null) {
    return null; // Or return an alternative JSX element if needed
  }

  const spanStyle = {
    verticalAlign: 'middle',
    fontSize: props.size ? props.size : 48,
    padding: 8,
    cursor: 'pointer',
    fontWeight: isBold ? 'bold' : 'normal',
    display: 'inline-block',
    width: '115px', // Adjust this width as necessary
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  };

  return (
    <span
      style={spanStyle}
      onClick={() => {
        setDollarMode(!dollarMode);
      }}
    >
      {displayBalance}
    </span>
  );
}
