import React from "react";
import { Typography } from "antd";

const { Title, Text } = Typography;

// displays a page header

export default function Header({ link, title, subTitle, ...props }) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", padding: "1.2rem", background: "transparent" }}>
      <div style={{ display: "flex", flexDirection: "column", flex: 1, alignItems: "start" }}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span style={{
            margin: "0 0.5rem 0 0",
            fontSize: "24px",
          }}>
            🗡️
          </span>
          <span style={{
            margin: "0 0.5rem 0 0",
            fontSize: "24px",
            fontFamily: 'Righteous', // Use the 'Bangers' font
            textShadow: '1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000',
            color: '#d3e1d7',
          }}>
            {title}
          </span>
        </a>
        <Text type="secondary" style={{ textAlign: "left" }}>
          {subTitle}
        </Text>
      </div>
      {props.children}
    </div>
  );
}

Header.defaultProps = {
  link: "/",
  title: "Merlin's Gambit",
  //subTitle: "Pull ETH out this sword in the stone",
};
