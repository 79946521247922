// MY INFURA_ID, SWAP IN YOURS FROM https://infura.io/dashboard/ethereum
export const INFURA_ID = process.env.REACT_APP_INFURA_KEY;
export const INFURA_ETH_ID = process.env.REACT_APP_ETH_ALCHEMY_KEY;
// My Alchemy Key, swap in yours from https://dashboard.alchemyapi.io/
export const ALCHEMY_KEY = process.env.REACT_APP_ALCHEMY_KEY;
// My Alchemy fully qualified
export const PROVIDER = process.env.REACT_APP_PROVIDER;
export const ETH_PROVIDER = process.env.REACT_APP_ETH_PROVIDER;
// MY ETHERSCAN_ID, SWAP IN YOURS FROM https://etherscan.io/myapikey
export const ETHERSCAN_KEY = process.env.REACT_APP_ETHERSCAN_API_KEY ?? "DNXJA8RX2Q3VZ4URQIWP7Z68CJXQZSC6AW";
// blockexplorer
export const BLOCK_EXPLORER = process.env.REACT_APP_BLOCK_EXPLORER;
// Network
export const APP_NETWORK = process.env.REACT_APP_APP_NETWORK;
// BLOCKNATIVE ID FOR Notify.js:
export const BLOCKNATIVE_DAPPID = process.env.REACT_APP_BLOCKNATIVE_DAPP_ID ?? "0b58206a-f3c0-4701-a62f-73c7243e8c77";
// Docker Hardhat Host
export const HARDHAT_HOST = process.env.REACT_APP_HARDHAT_HOST ?? "http://localhost";
// MerlinsGambitUI things
export const DEBUG = process.env.REACT_APP_DEBUG;
export const SILVER_PENNY_MINT_PRICE = parseFloat(process.env.REACT_APP_SILVER_PENNY_MINT_PRICE)
export const MINT_REPLICA_SWORD_PRICE = parseFloat(process.env.REACT_APP_MINT_REPLICA_SWORD_PRICE)
export const COST_TO_CHALLENGE_ETH = parseFloat(process.env.REACT_APP_COST_TO_CHALLENGE_ETH)
export const MINING_REWARD_REGULAR = parseFloat(process.env.REACT_APP_MINING_REWARD_REGULAR)
export const EXCALIBUR_RANDOMNESS_FUND_COST = parseFloat(process.env.REACT_APP_EXCALIBUR_RANDOMNESS_FUND_COST)
// not a number, duh
export const OPEN_SEA_BASE_URL = process.env.REACT_APP_OPEN_SEA_BASE_URL


/*
Decrease the number of RPC calls by passing this value to hooks
with pollTime (useContractReader, useBalance, etc.).
Set it to 0 to disable it and make RPC calls "onBlock".
Note: this is not used when you are in the local hardhat chain.
*/
export const RPC_POLL_TIME = 6000;

const localRpcUrl = process.env.REACT_APP_CODESPACES
  ? `https://${window.location.hostname.replace("3000", "8545")}`
  : "http://" + (global.window ? window.location.hostname : "localhost") + ":8545";

export const NETWORKS = {
  localhost: {
    name: "localhost",
    color: "#666666",
    chainId: 31337,
    blockExplorer: "",
    rpcUrl: localRpcUrl,
  },
  mainnet: {
    name: "mainnet",
    color: "#ff8b9e",
    chainId: 1,
    rpcUrl: `https://eth-mainnet.g.alchemy.com/v2/${INFURA_ETH_ID}`,
    blockExplorer: "https://etherscan.io/",
  },
  sepolia: {
    name: "sepolia",
    color: "#87ff65",
    chainId: 11155111,
    faucet: "https://sepoliafaucet.com/",
    blockExplorer: "https://sepolia.etherscan.io/",
    rpcUrl: `https://sepolia.infura.io/v3/${INFURA_ID}`,
  },
  sepoliaArbitrum: {
    name: "sepoliaArbitrum",
    color: "#28a0f0",
    chainId: 421614,
    blockExplorer: "https://sepolia.arbiscan.io/",
    rpcUrl: `https://arbitrum-sepolia.infura.io/v3/${INFURA_ID}`,
  },
  goerliArbitrum: {
    name: "goerliArbitrum",
    color: "#28a0f0",
    chainId: 421613,
    blockExplorer: "https://goerli.arbiscan.io/",
    rpcUrl: `https://arbitrum-goerli.infura.io/v3/${INFURA_ID}`,
  },
  arbitrum: {
    name: "arbitrum",
    color: "#28a0f0",
    chainId: 42161,
    blockExplorer: "https://arbiscan.io/",
    rpcUrl: "https://arb1.arbitrum.io/rpc",
  },
  goerli: {
    name: "goerli",
    color: "#0975F6",
    chainId: 5,
    faucet: "https://goerli-faucet.slock.it/",
    blockExplorer: "https://goerli.etherscan.io/",
    rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`,
  },
};

export const NETWORK = chainId => {
  for (const n in NETWORKS) {
    if (NETWORKS[n].chainId === chainId) {
      return NETWORKS[n];
    }
  }
};
