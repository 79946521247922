import { Row, Col, Tooltip } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { utils, ethers } from "ethers";

import { Address, MyBalance, MyBalancePopUp } from "../components";
import {
  DEBUG,
  SILVER_PENNY_MINT_PRICE,
  MINT_REPLICA_SWORD_PRICE,
  COST_TO_CHALLENGE_ETH,
  MINING_REWARD_REGULAR,
  EXCALIBUR_RANDOMNESS_FUND_COST,
  OPEN_SEA_BASE_URL,
} from "../constants";
import Confetti from 'react-dom-confetti';
import { parseEther } from "ethers/lib/utils";

export default function MerlinsGambitUI({
  address,
  provider,
  price,
  readContracts,
  writeContracts,
  blockExplorer,
  selectedChainId,
}) {

  const [totalSilverPenniesSupply, setTotalSilverPenniesSupply] = useState(null);
  const [totalSilverPenniesSupplyChangeSinceUpdate, setTotalSilverPenniesSupplyChangeSinceUpdate] = useState(0);
  const [attemptingPull, setAttemptingPull] = useState(false);
  const [regularMiningRewardWon, setRegularMiningRewardWon] = useState(false);
  const [tryAgainOrCongrats, setTryAgainOrCongrats] = useState(false);
  const [grandMiningRewardWon, setGrandMiningRewardWon] = useState(false);
  const [randomMiningRewardWon, setRandomMiningRewardWon] = useState(false);
  const [tokensMinted, setTokensMinted] = useState(false);
  const [tryAgainMessage, setTryAgainMessage] = useState(false);
  const [swordStaysPut, setSwordStaysPut] = useState(false);
  const [NFTMinted, setNFTMinted] = useState(false);
  const [nftName, setNFTName] = useState('');
  const [nftNameOffensiveSword, setNFTNameOffensiveSword] = useState('');
  const [nftNameDefensiveSword, setNFTNameDefensiveSword] = useState('');
  const [currentMessageIndex, setCurrentMessageIndex] = useState(-1);   //Squirrel 
  const [currentMessageIndexBird, setCurrentMessageIndexBird] = useState(-1);
  const [displaySquirrelMessage, setDisplaySquirrelMessage] = useState(false);
  const [displayBirdMessage, setDisplayBirdMessage] = useState(false);
  const [mintReplicaTransactionSubmitted, setMintReplicaTransactionSubmitted] = useState(false);
  const [loadingNFT, setLoadingNFT] = useState(false);
  const [loadingOnchainNFT, setLoadingOnchainNFT] = useState(false);
  const [backgroundMusic, setBackgroundMusic] = useState(null);
  const [isMuted, setIsMuted] = useState(true);
  const [loadingNFTSound, setLoadingNFTSound] = useState(null);
  const [buttonClickedSound, setButtonClickedSound] = useState(null);
  const [coinSound, setCoinSound] = useState(null);
  const [corkPop, setCorkPop] = useState(null);
  const [gruntingSound, setGruntingSound] = useState(null);
  const [hmmSound, setHmmSound] = useState(null);
  const [swordDuelSound, setSwordDuelSound] = useState(null);
  const [swordCreationSound, setSwordCreationSound] = useState(null);
  const [successTrumpetsSound, setSuccessTrumpetsSound] = useState(null);
  const [mode, setMode] = useState('View');
  const [buttonHovered, setButtonHovered] = useState(null);
  const [selectedOffensiveSword, setSelectedOffensiveSword] = useState(0);
  const [selectedDefensiveSword, setSelectedDefensiveSword] = useState(2);
  const [winningPlayerAddress, setWinningPlayerAddress] = useState('');
  const borderResetTimeoutRef = useRef(null);
  const [duelsWonById, setDuelsWonById] = useState('');
  const [attemptingDuel, setAttemptingDuel] = useState(false);
  const [notEnoughFunds, setNotEnoughFunds] = useState(false);
  const [attemptingMint, setAttemptingMint] = useState(false);
  const [showSwordExamples, setShowSwordExamples] = useState(false);
  const [showFinePrint, setShowFinePrint] = useState(false);
  const [showBook, setShowBook] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [isFlashing, setIsFlashing] = useState(true);
  const [showGettingStarted, setShowGettingStarted] = useState(false);
  const [showExcaliburBountyDetails, setShowExcaliburBountyDetails] = useState(false);
  const [showRulesSection, setShowRulesSection] = useState(true);
  const [showCreditsSection, setShowCreditsSection] = useState(false);
  const [hoveredSection, setHoveredSection] = useState(null);
  const [swordDigitKeyData, setSwordDigitKeyData] = useState([]);
  const [swordDigitKeyDataTotal, setSwordDigitKeyDataTotal] = useState(0);
  const [totalSwordPulls, setTotalSwordPulls] = useState(0);
  const instructionsDivRef = useRef(null);
  const instructionsTimeoutRef = useRef(null);
  const buttonTimeoutRef = useRef(null);
  const [swordPullCounter, setSwordPullCounter] = useState(0);
  const [latestBlockNumber, setLatestBlockNumber] = useState(null);
  const [ethWonCounter, setEthWonCounter] = useState(0);
  const [mintCounterNFT, setMintCounterNFT] = useState(0);
  const [sharpnessData, setSharpnessData] = useState(null);
  const [sharpnessDataOffensiveSword, setSharpnessDataOffensiveSword] = useState(null);
  const [sharpnessDataDefensiveSword, setSharpnessDataDefensiveSword] = useState(null);
  const [easterEggOpacity, setEasterEggOpacity] = useState(0);
  const [easterEggRevealed, setEasterEggRevealed] = useState(false);
  const easterEggHoverTime = useRef(null);
  const hoverInterval = useRef(null);
  const [swordImageFile, setSwordImageFile] = useState('');
  const [swordImageFileOffensiveSword, setSwordImageFileOffensiveSword] = useState('');
  const [swordImageFileDefensiveSword, setSwordImageFileDefensiveSword] = useState('');
  const [swordImageFileBackUp, setSwordImageFileBackUp] = useState('');
  const [transactionErrorCheck, setTransactionErrorCheck] = useState('');
  const [showTransactionErrorMessage, setShowTransactionErrorMessage] = useState(false);
  const [currentContractData, setCurrentContractData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentSpnyBalance, setCurrentSpnyBalance] = useState(0);
  const [penniesMintedSinceUpdate, setPenniesMintedSinceUpdate] = useState(0);
  const [contractBalance, setContractBalance] = useState(0);
  const [contractBalanceSinceUpdate, setContractBalanceSinceUpdate] = useState(0);
  const [ownedTokenIds, setOwnedTokenIds] = useState([]);
  const [addressNFTOwner, setAddressNFTOwner] = useState('');
  const [tokenIdNFT, setTokenIdNFT] = useState();
  const [tokenIdOffensiveSword, setTokenIdOffensiveSword] = useState();
  const [tokenIdDefensiveSword, setTokenIdDefensiveSword] = useState();
  const [gameWinnings, setGameWinnings] = useState(0);
  const [gameWinningsSinceUpdate, setGameWinningsSinceUpdate] = useState(0);

  const [showInitialPopup, setShowInitialPopup] = useState(false);
  const [popUpCurrentPage, setPopUpCurrentPage] = useState(0);
  const [popupButtonHovered, setPopupButtonHovered] = useState(''); // State to manage which popup button is hovered

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const BREAKPOINT = 1000;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Conditional style for responsive design
  const gameAddressStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: windowWidth <= BREAKPOINT ? 'center' : 'flex-start',
  };

  const gameAddressTextStyle = {
    fontSize: windowWidth <= BREAKPOINT ? '10px' : '12px', // Adjust font size based on screen width
    textAlign: 'left',
    color: 'rgba(96, 161, 96)',
  };

  const gameValueTextStyle = {
    fontSize: '12px',
    textAlign: 'right',
    color: 'rgba(147, 144, 183)',
  };

  const bountyPopUpTextStyle = {
    fontSize: '12px',
    color: 'white',
  };

  const gameFundsStyle = {
    fontSize: '12px',
    color: '#3498db',
  };

  const randomnessStyle = {
    fontSize: '12px',
    color: '#e74c3c',
  };

  const nftSalesStyle = {
    fontSize: '12px',
    color: '#2ecc71',
  };

  const prizeStyle = {
    fontSize: '14px',
    color: '#db34c5',
  };

  const oddsStyle = {
    fontSize: '12px',
    color: 'orange',
  };

  const auditInfoStyle = {
    fontSize: '10px',
    color: 'grey',
  };

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: '6% 46% 18% 30%', // Add a new column for emojis and adjust the widths
    gap: '0px', // Maintain tight spacing
  };


  const gridItemStyle = {
    display: 'flex',
    alignItems: 'center',
    margin: '0', // Ensure no extra margin
    padding: '0', // Ensure no extra padding
    gap: '0px', // Minimal gap between items within each grid cell for compactness
  };


  const carouselPages = [
    {
      title: "Merlin's Gambit",
      content: (
        <div style={{ paddingTop: '10px' }}>
          <div>✅ Verified Contract <span style={auditInfoStyle}>(Audit Pending)</span></div>
          <div>✅ Bounty is not accessible to team</div>
          <div>✅ Built on Scaffold-ETH</div>
          <div>✅ 0% Creator Take Rate <span style={auditInfoStyle}>(1.5% to Chainlink)</span></div>
          <div>✅ Provably Fair Randomness via Chainlink</div>
        </div>
      ),
      image: "/swordImagePopup.png",
    },
    {
      title: "Rules",
      content: (
        <div>
          <h3>Each Excalibur Pull can win:</h3>
          <div style={gridContainerStyle}>
            {/* Grand Prize Row */}
            <div style={gridItemStyle}>🏆</div>
            <div style={{ ...gridItemStyle, justifyContent: 'flex-center' }}><b style={prizeStyle}>Grand Prize:</b></div>
            <div style={gridItemStyle}><MyBalancePopUp size="14px" balance={contractBalance} balanceSinceUpdate={contractBalanceSinceUpdate} price={price} /></div>
            <div style={gridItemStyle}><span style={oddsStyle}> (Odds 1 : Million)</span></div>

            {/* Knight's Bounty Row */}
            <div style={gridItemStyle}>🛡️</div>
            <div style={{ ...gridItemStyle, justifyContent: 'flex-center' }}><b style={prizeStyle}>Knight's Bounty:</b></div>
            <div style={gridItemStyle}>0.20 ETH</div>
            <div style={gridItemStyle}><span style={oddsStyle}> (Odds 1 : 25)</span></div>

            {/* Round Table Reward Row */}
            <div style={gridItemStyle}>⚔</div>
            <div style={{ ...gridItemStyle, justifyContent: 'flex-center' }}><b style={prizeStyle}>Round Table Reward:</b></div>
            <div style={gridItemStyle}>0.01 ETH</div>
            <div style={gridItemStyle}><span style={oddsStyle}> (Odds 1 : 25)</span></div>
          </div>



          {/* <div>🏆<b style={prizeStyle}>Grand Prize:</b><MyBalancePopUp size="14px" balance={contractBalance} balanceSinceUpdate={contractBalanceSinceUpdate} price={price} /><span style={oddsStyle}> (Odds 1 : Million)</span></div>
          <div>🛡️<b style={prizeStyle}>Knight's Bounty:</b> 0.20 ETH<span style={oddsStyle}> (Odds 1 : 25)</span></div>
          <div>⚔<b style={prizeStyle}>Round Table Reward:</b> 0.01 ETH<span style={oddsStyle}> (Odds 1 : 25)</span>
            <center>
              <div>to a random previous player</div>
            </center>
          </div> */}
          <div
            style={{ cursor: 'pointer', color: '#007bff', paddingLeft: '19px', paddingTop: '10px' }} // Added paddingLeft here
            onClick={() => {
              setShowInitialPopup(false);
              setShowRulesSection(true);
              setShowAbout(true);
            }}
          >
            read more details
          </div>

        </div>

      ),
      image: "/knight_1.png",
    },
    {
      title: "Network Selection",
      content: (
        <div>
          <h3> Connect to Arbitrum One</h3>
          <div>Open your wallet application (e.g., MetaMask).</div>
          <div>Navigate to the network selection dropdown.</div>
          <div>Choose "Arbitrum One" from the list. 💙🧡</div>
        </div>
      ),
      image: "/liveOnArb.png",
    },
    {
      title: "Excalibur Bounty",
      content: (
        <div>
          <div>
            💸 <b style={gameFundsStyle}>Game Funds Distribution:</b> <span style={bountyPopUpTextStyle}>98.5% of all game funds are directly channeled to the players.</span>
          </div>
          <div>
            🎲 <b style={randomnessStyle}>Ensuring Randomness:</b> <span style={bountyPopUpTextStyle}>1.5% fee is dedicated to maintaining randomness, powered by Chainlink.</span>
          </div>
          <div>
            🖼️ <b style={nftSalesStyle}>NFT Sales Contribution:</b> <span style={bountyPopUpTextStyle}>10% of all NFT sales proceeds are donated to the Excalibur Bounty.</span>
          </div>
        </div>
      ),
      image: "/anvil.png",
    },
  ];


  const navigationDotContainerStyle = { textAlign: 'center', marginTop: '10px' }; // Example style for dot container
  const navigationDotStyle = (isActive) => ({
    height: '10px',
    width: '10px',
    margin: '5px',
    backgroundColor: isActive ? 'white' : 'grey',
    borderRadius: '50%',
    display: 'inline-block',
  });


  const handleNext = () => {
    setPopUpCurrentPage((prevPage) => prevPage + 1); // Increment page
  };

  const [showDuelInfo, setShowDuelInfo] = useState(false);
  const [hasDuelBeenActivated, setHasDuelBeenActivated] = useState(false);

  const [isContinueButtonHovered, setIsContinueButtonHovered] = useState(false);

  const handleContinueButtonMouseEnter = () => setIsContinueButtonHovered(true);
  const handleContinueButtonMouseLeave = () => setIsContinueButtonHovered(false);

  const [nftDropdownHovered, setNftDropdownHovered] = useState(false);

  const tweet = encodeURIComponent(`Thanks @MerlinsGambit! 🎉 Won .2 ETH and feeling legendary. Your turn to pull the sword! 🤞 #ChainlinkVRF #Arbitrum ⚔️💲`);

  const shareTweet = () => {
    const url = `https://twitter.com/intent/tweet?text=${tweet}`;
    window.open(url, '_blank');
  };

  const [currentBirdAdviceIndex, setCurrentBirdAdviceIndex] = useState(0);
  const [displayBirdAdvice, setDisplayBirdAdvice] = useState(false);

  const birdAdvice = [
    "Congratulations on the win! Tell your friends."
    // Add more advice messages as needed
  ];


  // Read the SPNY balance of the current player
  // const spnyBalance = useContractReader(readContracts, "MerlinsGambitQ1", "balanceOfBatch", [[address], [1]], providerPollingTime);

  //iPhone check
  const [isIphoneUser, setIsIphoneUser] = useState(false);

  function isAppleDevice() {
    return /iPhone|iPod|iPad|Macintosh|MacIntel/i.test(navigator.userAgent);
  }

  useEffect(() => {
    setIsIphoneUser(isAppleDevice());
  }, []);

  // Ref for the new popup, if needed
  const initialPopupRef = useRef(null);

  // Effect to show the initial popup once when the site loads
  useEffect(() => {
    setShowInitialPopup(true);
    //setShowAbout(true);
  }, []);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsFlashing(false); // Stop flashing after 3 seconds
  //   }, 60000);

  useEffect(() => {
    // Function to handle the flashing logic
    const handleFlashing = () => {
      // Turn on the flash
      setIsFlashing(true);

      // Set a timeout to turn off the flash after 1 second
      setTimeout(() => {
        setIsFlashing(false);
      }, 1200);
    };

    // Call the flashing function immediately to start the effect
    handleFlashing();

    // Set an interval to call the flashing function every 3 seconds
    const flashInterval = setInterval(() => {
      handleFlashing();
    }, 3200); // 1.2 second on, 2 seconds off

    // Set a timeout to stop the flashing effect after 60 seconds
    const stopFlashingTimer = setTimeout(() => {
      clearInterval(flashInterval); // Clear the flashing interval
      setIsFlashing(false); // Ensure the flashing is stopped and stays off
    }, 12000); //10 seconds

    // Cleanup function to clear timers when the component unmounts
    return () => {
      clearTimeout(stopFlashingTimer);
      clearInterval(flashInterval);
    };
  }, []);



  const handleSectionClick = (section) => {
    console.log("🖱 handleSectionClick");
    switch (section) {
      case "GettingStarted":
        setShowGettingStarted(true);
        setShowExcaliburBountyDetails(false);
        setShowRulesSection(false);
        setShowCreditsSection(false);
        break;
      case "ExcaliburBounty":
        setShowGettingStarted(false);
        setShowExcaliburBountyDetails(true);
        setShowRulesSection(false);
        setShowCreditsSection(false);
        break;
      case "Rules":
        setShowGettingStarted(false);
        setShowExcaliburBountyDetails(false);
        setShowRulesSection(true);
        setShowCreditsSection(false);
        break;
      case "Credits":
        setShowGettingStarted(false);
        setShowExcaliburBountyDetails(false);
        setShowRulesSection(false);
        setShowCreditsSection(true);
        break;
      default:
        break;
    }
  }

  const changeInstructionsDivBorder = () => {
    const div = instructionsDivRef.current;
    if (!div) return;

    if (instructionsTimeoutRef.current) {
      clearTimeout(instructionsTimeoutRef.current);
    }

    div.style.boxShadow = '0 0 0 4px rgb(232, 222, 79)';

    instructionsTimeoutRef.current = setTimeout(() => {
      div.style.boxShadow = '0 0 0 1px black';
    }, 2000);
  };

  const changeButtonBorder = (buttonId) => {
    const button = document.getElementById(buttonId);
    button.style.border = '1px solid rgb(0, 255, 0)';

    if (buttonTimeoutRef.current) {
      clearTimeout(buttonTimeoutRef.current);
    }

    buttonTimeoutRef.current = setTimeout(() => {
      button.style.border = '1px solid black';
    }, 1000);
  };

  const changeMode = async (newMode) => {
    setMode(newMode);

    if (newMode === 'Duel' && !hasDuelBeenActivated) {
      setShowDuelInfo(true);
      setHasDuelBeenActivated(true);
    }

    if (newMode === 'View') {
      if (displayOwnedNfts) {
        const contractDataByID = await readContracts.MerlinsGambitQ1.getContractDataByID(ownedTokenIds[displayNftCounter])
        getCurrentTokenId();
        getSharpnessData(contractDataByID);
        getDuelCountByTokenId(contractDataByID);
        getSwordDigitKeyData(contractDataByID);
      } else {
        const contractDataByID = await readContracts.MerlinsGambitQ1.getContractDataByID(displayNftCounterAll)
        getCurrentTokenId();
        getSharpnessData(contractDataByID);
        getDuelCountByTokenId(contractDataByID);
        getSwordDigitKeyData(contractDataByID);
      }
    }
  };

  const toggleMute = () => {
    if (isMuted) {
      // Toggle the isMuted state before playing or pausing the audio
      setIsMuted(false);
      backgroundMusic.play();
    } else {
      setIsMuted(true);
      backgroundMusic.pause();
    }
  };

  const [currentAdviceIndex, setCurrentAdviceIndex] = useState(0);

  const squirrelAdvice = [
    "Wow 0.2 ETH! Give up while you're ahead and snag a Replica NFT.",
    "So you got 0.2 ETH from that old sword? Not bad. But let's see if you're smart enough to pick up a Replica NFT now."
  ];

  const handleSquirrelAdvice = () => {
    console.log("🐿 handleSquirrelAdvice");
    const randomIndex = Math.floor(Math.random() * squirrelAdvice.length);
    setCurrentAdviceIndex(randomIndex);  // This line might be optional if you don't need to track the index anymore
    displayMessage('squirrelAdvice', randomIndex, 15000);
  };


  const squirrelTalk = [
    "Don't be fooled by the allure of the sword in the stone.",
    "Buy a Replica for .1 ETH, they are onchain generative NFTs.",
    "If you think yanking at that sword will make you a fortune, think again – you'd be far better off with the quality blades I offer.",
    "Don't squander your ETH attempting to free the sword from the stone; all you'll end up with are those worthless Silver Pennies.",
    "The odds of pulling the sword from the stone are nearly impossible, you'd have to be one in a million.",
    "Don't waste your ETH on a feat that offers nothing but disappointment.",
    "Those Silver Pennies will never amount to anything of value.",
    "Trust me, my swords are far more reliable than that old relic stuck in stone.",
    "That rusty blade in the stone won't hold a candle to the fine craftsmanship of my swords.",
    "Why bother with that ancient hunk of metal when you can have a shiny, new sword crafted to perfection?",
    "The Silver Pennies you might get from that stone are nothing compared to the value of a well-crafted sword.",
    "Trying to pull the sword from the stone won't bring you any profits; all you get are some Silver Pennies that won't be worth a dime.",
    "Why bother with the sword in the stone when you can have a reliable weapon forged by a true artisan?",
    "Silver Pennies won't buy you anything worthwhile, but a fine sword crafted by a skilled blacksmith will never let you down.",
    "Those Silver Pennies won't help you when you need a real blade.",
    "There's no sense in spending your ETH."
  ];

  const birdTalk = [
    "Welcome to Merlin's Gambit.",
    "Try your hand at freeing Excalibur.",
  ];

  // Add these two state variables to store the timers for each speaker
  const [birdTimer, setBirdTimer] = useState(null);
  const [squirrelTimer, setSquirrelTimer] = useState(null);
  const [displaySquirrelAdvice, setDisplaySquirrelAdvice] = useState(false);
  const [birdAdviceTimeout, setBirdAdviceTimeout] = useState(null);

  const handleBirdAdvice = () => {
    console.log("🐦 handleBirdAdvice");
    //const randomIndex = Math.floor(Math.random() * birdAdvice.length);
    //setCurrentBirdAdviceIndex(randomIndex);
    setCurrentBirdAdviceIndex(0);
    //displayMessage('birdAdvice', randomIndex, 15000);
    displayMessage('birdAdvice', 0, 15000);

    // Clear any existing timeout
    if (birdAdviceTimeout) clearTimeout(birdAdviceTimeout);

    // Set a new timeout
    const timeoutId = setTimeout(() => {
      setDisplayBirdAdvice(false);
    }, 30000); // 30 seconds

    setBirdAdviceTimeout(timeoutId);
  };

  useEffect(() => {
    return () => {
      if (birdAdviceTimeout) clearTimeout(birdAdviceTimeout);
    };
  }, [birdAdviceTimeout]);


  const displayMessage = (speaker, index, duration) => {
    console.log("🗣 displayMessage");
    if (speaker === 'bird') {
      setCurrentMessageIndexBird(index);
      setDisplayBirdMessage(true);

      // Clear the previous timer and set a new one
      if (birdTimer) clearTimeout(birdTimer);
      const newBirdTimer = setTimeout(() => {
        setDisplayBirdMessage(false);
      }, duration);
      setBirdTimer(newBirdTimer);
    } else if (speaker === 'birdAdvice') {
      setCurrentBirdAdviceIndex(index);
      setDisplayBirdAdvice(true);
      // Clear the previous timer and set a new one for advice
      if (birdTimer) clearTimeout(birdTimer);
      const newBirdTimer = setTimeout(() => {
        setDisplayBirdAdvice(false);
      }, duration);
      setBirdTimer(newBirdTimer);
    } else if (speaker === 'squirrel') {
      setCurrentMessageIndex(index);
      setDisplaySquirrelMessage(true);

      // Clear the previous timer and set a new one
      if (squirrelTimer) clearTimeout(squirrelTimer);
      const newSquirrelTimer = setTimeout(() => {
        setDisplaySquirrelMessage(false);
      }, duration);
      setSquirrelTimer(newSquirrelTimer);
    } else if (speaker === 'squirrelAdvice') {
      setCurrentAdviceIndex(index);
      setDisplaySquirrelAdvice(true);  // Set to display squirrel advice message

      // Clear the previous timer and set a new one for advice
      if (squirrelTimer) clearTimeout(squirrelTimer);
      const newSquirrelTimer = setTimeout(() => {
        setDisplaySquirrelAdvice(false);  // Hide the advice message after duration
      }, duration);
      setSquirrelTimer(newSquirrelTimer);
    }
  };

  const handleSquirrelMouseOver = () => {
    console.log("🐿 handleSquirrelMouseOver");
    const nextMessageIndex = (currentMessageIndex + 1) % squirrelTalk.length;
    displayMessage('squirrel', nextMessageIndex, 15000);
  };

  const handleBirdMouseOver = () => {
    console.log("🐦 handleBirdMouseOver");
    const nextMessageIndex = (currentMessageIndexBird + 1) % birdTalk.length;
    displayMessage('bird', nextMessageIndex, 15000);
  };

  const [swordButtonHovered, setSwordButtonHovered] = useState(false);

  const buttonBackgroundColor = 'rgba(37, 89, 62, 0.64)';
  const buttonTextColor = 'rgb(255, 255, 255)';
  const buttonHoverTextColor = 'rgb(255, 255, 255)';
  const buttonHoverBackgroundColor = 'rgba(37, 89, 62, 0.84)';
  const buttonBackgroundColorReplica = 'rgb(137, 32, 192, 0.64)';
  const buttonHoverBackgroundColorReplica = 'rgba(137, 32, 192, 0.84)';

  const copyrightStyle = {
    position: 'fixed',
    bottom: 0,
    right: '15px',
    padding: '2px',
    backgroundColor: 'transparent',
    zIndex: 1000,
    fontSize: '12px',                // Set the font size
    color: 'rgb(110, 110, 122)',
    fontFamily: 'Arial, sans-serif', // Set the font family, this is just an example
    fontWeight: 'normal',           // Set the font weight to normal
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)' // Adds a subtle shadow to the text
  };

  const finePrintStyle = {
    position: 'fixed',
    bottom: 0,
    left: '15px',
    padding: '2px',
    backgroundColor: 'transparent',
    zIndex: 1000,
    fontSize: '12px',                // Set the font size
    color: 'rgb(110, 110, 122)',
    fontFamily: 'Arial, sans-serif', // Set the font family, this is just an example
    fontWeight: 'normal',           // Set the font weight to normal
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)' // Adds a subtle shadow to the text
  };

  const mediumArticleLinkStyle = {
    cursor: 'pointer',
    fontFamily: 'Righteous',
    textShadow: ownedTokenIds.length > 0 ? '1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000' :
      isFlashing ? '1px 1px 0 blue, -1px -1px 0 blue, 1px -1px 0 blue, -1px 1px 0 blue' :
        '1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000',
    position: 'fixed',
    top: '29px',
    left: '337px',
    padding: '2px',
    backgroundColor: 'transparent',
    zIndex: 1000,
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#d3e1d7',
  };

  const aboutStyle = {
    cursor: 'pointer',
    fontFamily: 'Righteous',
    textShadow: ownedTokenIds.length > 0 ? '1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000' :
      isFlashing ? '1px 1px 0 blue, -1px -1px 0 blue, 1px -1px 0 blue, -1px 1px 0 blue' :
        '1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000',
    position: 'fixed',
    top: '29px',
    left: '260px',
    padding: '2px',
    backgroundColor: 'transparent',
    zIndex: 1000,
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#d3e1d7',
  };

  const aboutSectionSubtitleStyle = {
    color: '#e96868',
    fontSize: '16px',                // Optional: Set the font size if you need to
    fontFamily: 'Arial, sans-serif', // Set the font family like your example, or you can change it
    fontWeight: 'normal',            // Set the font weight to normal
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)' // Optional: Adds a subtle shadow to the text, adjust if needed
  };

  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: "20",
    elementCount: 70,
    dragFriction: 0.12,
    duration: "4000",
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ['#ed1c23', '#ff7d27', '#eef200', '#22b14d', '#00a2e8']
  };

  const confettiWrapperStyle = {
    position: 'absolute',
    top: '44px',
    left: '30px',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    zIndex: 2,
  };

  const knightStyle1 = {
    position: 'absolute',
    zIndex: 3,
    left: 'calc(50% + 30px)',
    bottom: '34px',
    height: '360px',
    width: 'auto',
    cursor: 'pointer'
  };

  const knightStyle2 = {
    position: 'absolute',
    zIndex: 3,
    left: 'calc(50% - 40px)',
    bottom: '34px',
    height: '360px',
    width: 'auto',
    cursor: 'pointer'
  };

  const squirrelStyle = {
    position: 'absolute',
    left: '280px',
    bottom: '132px',
    width: 'auto',
    height: '80px',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
  };

  const squirrelMessageStyle = {
    position: 'absolute',
    left: '380px',
    bottom: '220px',
    transform: 'translateX(-50%)',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#333',
    maxWidth: '300px',
    backgroundColor: '#F5F5F5',
    padding: '8px',
    borderRadius: '10px', // Adjust this value for more or less rounded corners
  };

  const squirrelMessageTailStyle = {
    position: 'absolute',
    top: '100%',
    left: '30%',
    width: '0',
    height: '0',
    borderStyle: 'solid',
    borderWidth: '10px 10px 0',
    borderColor: '#F5F5F5 transparent transparent',
  };

  const birdStyle = {
    position: 'absolute',
    left: '30%',
    bottom: '60%',
    width: 'auto',
    height: '64px',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
  };

  const birdMessageStyle = {
    position: 'absolute',
    left: '29%',
    bottom: '67%',
    transform: 'translateX(-50%)',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#333',
    maxWidth: '24%',
    backgroundColor: '#F5F5F5',
    padding: '8px',
    borderRadius: '10px', // Adjust this value for more or less rounded corners
  };

  const birdMessageTailStyle = {
    position: 'absolute',
    top: '100%',
    left: '72%',
    width: '0',
    height: '0',
    borderStyle: 'solid',
    borderWidth: '10px 10px 0',
    borderColor: '#F5F5F5 transparent transparent',
  };

  const extraInfoStyle = {
    marginTop: '100vh',
    display: 'none'
  };

  const twitterShareButtonStyle = {
    padding: '10px',
    backgroundColor: 'black', // Make sure this variable is defined
    borderRadius: '10px',
    border: '1px solid black',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '90px',
    height: '45px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: attemptingPull ? 'default' : 'pointer', // Ensure attemptingPull is defined
    color: buttonTextColor, // Make sure this variable is defined
  };

  //Check Event Log for stuck duels
  // Refs and State for Duel Attempts
  const duelCheckTimeoutRef = useRef();
  const [duelRestartCount, setDuelRestartCount] = useState(0);
  const attemptingDuelRef = useRef(false); // assuming attemptingDuel is a state variable for dueling attempt

  useEffect(() => {
    attemptingDuelRef.current = attemptingDuel; // assuming attemptingDuel is a state variable for dueling attempt
  }, [attemptingDuel]);

  const maybeSomethingWentWrongWithDuel = () => {
    console.log("⚔️ Maybe something went wrong with Duel function");
    setAttemptingDuel(false);
    // Handle additional state updates or error handling for duel attempt failure
    clearTimeout(duelCheckTimeoutRef.current);
  };

  const fetchDuelEvents = async (transactionBlockNumber) => {
    console.log("⚔️ Fetching events for Duel...");
    const fromBlock = Math.max(transactionBlockNumber, 0); // Start from the transaction block number
    // Update the query filter to fetch duel events
    const events = await readContracts.MerlinsGambitQ1.queryFilter({
      address: readContracts.MerlinsGambitQ1.address, // Update if needed
      topics: [ /* appropriate topics for duel events */]
    }, fromBlock, 'latest');

    console.log(`📜 Fetched ${events.length} events for Duel check.`);
    events.forEach((event, index) => {
      console.log(`Inspecting Duel event ${index}:`, event);
    });

    return events;
  };

  const findKnownDuelEventType = (events) => {
    console.log(`🧐 Inspecting ${events.length} events for Duel event types...`);
    let identifiedEvents = [];
    const eventTypesHex = {
      "53776f72644475656c526573756c7473": "SwordDuelResults",
    };

    events.forEach((event, index) => {
      if (event && event.data) {
        for (const [hex, eventType] of Object.entries(eventTypesHex)) {
          if (event.data.includes(hex)) {
            const tokenId1 = event.args?.tokenId1?.toString();
            const tokenIdDefensiveSword = event.args?.tokenIdDefensiveSword?.toString();
            const tokenIdWinningSword = event.args?.tokenIdWinningSword?.toString();

            identifiedEvents.push({
              eventType: eventType,
              eventIndex: index,
              blockNumber: event.blockNumber,
              tokenId1: tokenId1,
              tokenIdDefensiveSword: tokenIdDefensiveSword,
              tokenIdWinningSword: tokenIdWinningSword
            });

            console.log(`✅ Identified Duel Event: ${eventType} at index ${index}`);
            console.log(`🗂️ Event Index: ${index}`);
            console.log(`🚩 Block Number: ${event.blockNumber}`);
            console.log(`🗡️ Offense Token ID: ${tokenId1}`);
            console.log(`🛡️ Defense Token ID: ${tokenIdDefensiveSword}`);
            console.log(`🏆 Winning Token ID: ${tokenIdWinningSword}`);
          }
        }
      }
    });

    return identifiedEvents;
  };

  const determineDuelOutcome = (identifiedEvents, transactionBlockNumber) => {
    console.log("⚔️ determineDuelOutcome function initiated");
    console.log(`🔢 Transaction Block Number for reference: ${transactionBlockNumber}`);

    let isStillAttempting = true;
    let lastKnownEvent = null;
    let tokenId1 = undefined;
    let tokenIdDefensiveSword = undefined;
    let tokenIdWinningSword = undefined;

    identifiedEvents.forEach((event, index) => {
      console.log(`🔎 Inspecting event at index ${index}:`, event);
      console.log(`📅 Event Block Number: ${event.blockNumber}, Transaction Block Number: ${transactionBlockNumber}`);
      console.log(`🔍 Event Type: ${event.eventType}, Expected: 'SwordDuelResults'`);

      if (event.eventType === "SwordDuelResults" && event.blockNumber > transactionBlockNumber) {
        console.log(`🛑 Stopping attempt due to SwordDuelResults at block ${event.blockNumber}`);
        isStillAttempting = false;
        lastKnownEvent = event.eventType;
        tokenId1 = event.tokenId1;
        tokenIdDefensiveSword = event.tokenIdDefensiveSword;
        tokenIdWinningSword = event.tokenIdWinningSword;
      } else if (event.eventType === "SwordDuelResults") {
        console.log(`🔄 SwordDuelResults found at block ${event.blockNumber}, but not after the transaction block. Continuing to check...`);
      } else {
        console.log(`🤷 Event at index ${index} is not a SwordDuelResults or does not meet the block number condition. Continuing to check...`);
      }
    });

    console.log(`🏁 Duel checking complete. isStillAttempting: ${isStillAttempting}, Last Known Event: ${lastKnownEvent}`);
    return {
      isStillAttempting,
      lastKnownEvent,
      tokenId1,
      tokenIdDefensiveSword,
      tokenIdWinningSword
    };
  };


  const analyzeDuelEvents = (events, transactionBlockNumber) => {
    console.log("🔍 Analyzing Duel events...");
    const identifiedEvents = findKnownDuelEventType(events);
    const outcome = determineDuelOutcome(identifiedEvents, transactionBlockNumber);
    console.log(`🏁 Duel Analysis complete. isStillAttempting: ${outcome.isStillAttempting}, Last Known Event: ${outcome.lastKnownEvent}`);
    return outcome;
  };

  const handleDuelContinuedAttempt = (transactionBlockNumber) => {
    console.log("🟨 handleDuelContinuedAttempt function");
    if (duelRestartCount >= 3) {
      console.log("❗ Exceeded maximum Duel retries. Maybe something went wrong.");
      maybeSomethingWentWrongWithDuel();
      setDuelRestartCount(0);
    } else {
      console.log(`🔄 Restarting Duel event check after 18 seconds (${duelRestartCount + 1}/3)`);
      setDuelRestartCount(duelRestartCount + 1);
      duelCheckTimeoutRef.current = setTimeout(() => checkDuelSequence(transactionBlockNumber), 18000);
    }
  };

  const handleDuelCompletedAttempt = (outcome) => {
    const { lastKnownEvent, tokenId1, tokenIdDefensiveSword, tokenIdWinningSword } = outcome;
    console.log("🛑 Ending Duel attempt - Duel completed.");
    console.log(`🎖 Duel results: Winner Sword ID: ${tokenIdWinningSword}, Offensive Sword ID: ${tokenId1}, Defensive Sword ID: ${tokenIdDefensiveSword}`);

    // Trigger any specific functions or state updates needed on duel completion
    swordDuelResultsTriggerFromEventHistory(tokenId1, tokenIdDefensiveSword, tokenIdWinningSword);

    setDuelRestartCount(0);
  };

  const handleDuelOutcome = (outcome) => {
    const { isStillAttempting, transactionBlockNumber } = outcome;
    console.log(`🔔 Handling Duel Outcome. isStillAttempting: ${isStillAttempting}, Last Known Event: ${outcome.lastKnownEvent}`);
    if (isStillAttempting) {
      handleDuelContinuedAttempt(transactionBlockNumber);
    } else {
      handleDuelCompletedAttempt(outcome);
    }
  };

  const checkDuelSequence = async (transactionBlockNumber) => {
    console.log("⚔️ Checking Duel Status");
    if (!attemptingDuelRef.current) {
      console.log("🚫 No longer attempting Duel. Exiting check sequence.");
      return;
    }
    const events = await fetchDuelEvents(transactionBlockNumber);
    const outcome = analyzeDuelEvents(events, transactionBlockNumber);
    handleDuelOutcome({ ...outcome, transactionBlockNumber });
  };


  //Check Event Log for stuck NFT mint
  const nftMintCheckTimeoutRef = useRef();
  const [nftMintRestartCount, setNftMintRestartCount] = useState(0);

  const attemptingMintRef = useRef(attemptingMint);
  useEffect(() => {
    attemptingMintRef.current = attemptingMint;
  }, [attemptingMint]);

  const maybeSomethingWentWrongWithNFT = () => {
    console.log("🟥 Maybe something went wrong NFT function");
    setAttemptingMint(false); // Update to reflect NFT minting state
    setTransactionErrorCheck(true); // Set an error state that might display an error message or similar feedback
    clearTimeout(nftMintCheckTimeoutRef.current); // Clear the timeout for checking NFT minting events
  };

  const fetchNFTMintEvents = async (transactionBlockNumber) => {
    console.log("🔄 Fetching events for NFT mint...");
    const currentBlock = await provider.getBlockNumber();
    console.log("Current Block Number: ", currentBlock);
    // Adjust the lookback period as necessary - 50 blocks might be too much or too little depending on block time
    const fromBlock = Math.max(transactionBlockNumber, 0);  // Start from the transaction block number

    console.log("User Address: ", address);

    const events = await readContracts.MerlinsGambitQ1.queryFilter({
      address: readContracts.MerlinsGambitQ1.address,
      topics: [
        null, // for any event type; consider filtering by specific event signature if possible
        ethers.utils.hexZeroPad(address, 32)  // Filter by user's address
      ]
    }, fromBlock, 'latest');  // Fetch events from transaction block to latest

    console.log(`📜 Fetched ${events.length} events for NFT mint check.`);

    events.forEach((event, index) => {
      console.log(`Inspecting NFT mint event ${index}:`, event);
    });

    return events;
  };

  const findKnownNFTEventType = (events) => {
    console.log(`🧐 Inspecting ${events.length} events for NFT Minting event types...`);
    let identifiedEvents = [];

    // Hexadecimal identifiers for each NFT related event type
    const eventTypesHex = {
      "4e46544d696e746564": "NFTMinted", // Add any other NFT related events if necessary
    };

    events.forEach((event, index) => {
      if (event && event.data) {
        console.log(`🔎 Checking event ${index}: Data field`);

        for (const [hex, eventType] of Object.entries(eventTypesHex)) {
          if (event.data.includes(hex)) {
            console.log(`✅ Found known NFT event type: ${eventType}`);
            identifiedEvents.push({
              eventType: eventType,
              eventIndex: index,
              blockNumber: event.blockNumber // Include the blockNumber here
            });
            break;  // Only identify the first matching type per event
          }
        }
      } else {
        console.log(`⚠️ Event or event.data is undefined at index ${index}, Event details:`, event);
      }
    });

    return identifiedEvents;  // Returns an array of objects with eventType and eventIndex
  };

  const determineNFTMintOutcome = (identifiedEvents, transactionBlockNumber) => {
    console.log("🟧 determineNFTMintOutcome function");
    console.log("📦 identifiedEvents at start:", identifiedEvents);
    console.log("🏁 transactionBlockNumber at start:", transactionBlockNumber);
    // Assume by default that the minting is still ongoing until an NFTMinted event is found
    let isStillAttempting = true;
    let lastKnownEvent = null;

    // Iterate through identified events from most recent to oldest
    console.log("🔄 Starting to iterate identifiedEvents");
    for (let i = identifiedEvents.length - 1; i >= 0; i--) {
      const event = identifiedEvents[i];
      console.log(`🔍 Inspecting event at index ${i}:`, event);
      console.log(`🔢 Event Block Number at index ${i}:`, event.blockNumber);

      // If an NFTMinted event is found and it occurred after the transaction block number, close the attempt
      console.log("🟫 NFT Mint initiated at blockNumber: ", transactionBlockNumber);
      console.log("🟦 Found NFT Mint Event blockNumber: ", event.blockNumber);
      if (event.eventType === "NFTMinted" && event.blockNumber > transactionBlockNumber) {
        console.log("⬛ Determined NFT mint should have ended");
        isStillAttempting = false; // NFT minting attempt is completed
        lastKnownEvent = event.eventType; // Update last known event
        break; // Exit the loop as the most recent NFTMinted event has been found
      }
    }

    console.log(`🏁 Determined NFT mint outcome. isStillAttempting: ${isStillAttempting}, Last Known Event: ${lastKnownEvent}`);
    return { isStillAttempting, lastKnownEvent };
  };

  const analyzeNFTMintEvents = (events, transactionBlockNumber) => {
    console.log("🔍 Analyzing NFT mint events...");

    // First, identify known NFT event types among the fetched events
    const identifiedEvents = findKnownNFTEventType(events);

    // Next, determine the outcome based on the identified events and the transaction block number
    const outcome = determineNFTMintOutcome(identifiedEvents, transactionBlockNumber);

    console.log(`🏁 NFT Mint Analysis complete. isStillAttempting: ${outcome.isStillAttempting}, Last Known Event: ${outcome.lastKnownEvent}`);
    return outcome; // Return the entire outcome object
  };


  const handleNFTContinuedAttempt = (transactionBlockNumber) => {
    console.log("🟨 handleNFTContinuedAttempt function");
    if (nftMintRestartCount >= 3) {
      console.log("❗ Exceeded maximum NFT mint retries. Maybe something went wrong.");
      maybeSomethingWentWrongWithNFT();  // Define this function to handle NFT-specific errors
      setNftMintRestartCount(0);  // Reset the restart count for NFT minting
    } else {
      console.log(`🔄 Restarting NFT mint event check after 18 seconds (${nftMintRestartCount + 1}/3)`);
      setNftMintRestartCount(nftMintRestartCount + 1);
      nftMintCheckTimeoutRef.current = setTimeout(checkNFTMintSequence(transactionBlockNumber), 18000);  // UNCOMMENT THIS
    }
  };

  const handleNFTCompletedAttempt = (lastEvent) => {
    console.log("🛑 Ending NFT mint attempt - triggering NFTMinted.");
    NFTMintedTriggerFromEventHistory();  // Replace with your actual function to handle NFT mint completion
    setNftMintRestartCount(0);  // Reset the restart count
    // Additional cleanup or state updates related to NFT minting can be added here
  };

  const handleNFTOutcome = ({ isStillAttempting, lastKnownEvent, transactionBlockNumber }) => {
    console.log(`🔔 Handling NFT Outcome. isStillAttempting: ${isStillAttempting}, Last Known Event: ${lastKnownEvent}`);

    if (isStillAttempting) {
      console.log("➡️ Continuing NFT mint attempt due to ongoing event.");
      handleNFTContinuedAttempt(transactionBlockNumber);
    } else {
      console.log(`➡️ Completed NFT mint attempt. Last known event was: ${lastKnownEvent}. Triggering handleNFTCompletedAttempt.`);
      handleNFTCompletedAttempt(lastKnownEvent);
    }
  };

  const checkNFTMintSequence = async (transactionBlockNumber) => {
    console.log("🖼 Checking NFT Mint Status");
    if (!attemptingMintRef.current) {
      console.log("🚫 No longer attempting NFT mint. Exiting check sequence.");
      return;
    }

    // Fetch events and analyze, now with the transactionBlockNumber
    const events = await fetchNFTMintEvents(transactionBlockNumber); // Updated to accept block number
    const outcome = analyzeNFTMintEvents(events, transactionBlockNumber); // Updated to accept block number

    handleNFTOutcome({ ...outcome, transactionBlockNumber });
  };



  //Check Event Log for stuck Pull
  const eventCheckTimeoutRef = useRef();
  const [restartCount, setRestartCount] = useState(0);
  const attemptingPullRef = useRef(attemptingPull); // Create a ref for attemptingPull

  useEffect(() => {
    attemptingPullRef.current = attemptingPull;
  }, [attemptingPull]);


  const maybeSomethingWentWrong = () => {
    setAttemptingPull(false);
    setTransactionErrorCheck(true);
    clearTimeout(eventCheckTimeoutRef.current);
  };

  const fetchEvents = async () => {
    console.log("🔄 Fetching events...");
    const currentBlock = await provider.getBlockNumber();
    console.log("Current Block Number: ", currentBlock);
    const fromBlock = Math.max(currentBlock - 50, 0);

    console.log("User Address: ", address);

    const events = await readContracts.MerlinsGambitQ1.queryFilter({
      address: readContracts.MerlinsGambitQ1.address,
      topics: [
        null, // for any event type
        ethers.utils.hexZeroPad(address, 32)
      ]
    }, fromBlock, 'latest');

    console.log(`📜 Fetched ${events.length} events.`);

    events.forEach((event, index) => {
      console.log(`Inspecting event ${index}:`, event);
    });

    return events;
  };

  const findKnownEventType = (events) => {
    console.log(`🧐 Inspecting ${events.length} events for known event types...`);
    let identifiedEvents = [];

    // Hexadecimal identifiers for each event type
    const eventTypesHex = {
      "546f6b656e734d696e746564": "TokensMinted",
      "53696c76657250656e6e794d696e74496e69746961746564": "SilverPennyMintInitiated",
      "53776f72644e6f4275646765": "SwordNoBudge",
      "526567756c61724d696e696e67526577617264576f6e": "RegularMiningRewardWon"
    };

    events.forEach((event, index) => {
      if (event && event.data) {
        console.log(`🔎 Checking event ${index}: Data field`);

        for (const [hex, eventType] of Object.entries(eventTypesHex)) {
          if (event.data.includes(hex)) {
            console.log(`✅ Found known event type: ${eventType}`);
            identifiedEvents.push({ eventType: eventType, eventIndex: index });
            break;  // Only identify the first matching type per event
          }
        }
      } else {
        console.log(`⚠️ Event or event.data is undefined at index ${index}, Event details:`, event);
      }
    });

    return identifiedEvents;  // Returns an array of objects with eventType and eventIndex
  };

  const determineEventOutcome = (identifiedEvents) => {
    let isStillAttempting = false;
    let lastKnownEvent = null;

    // Iterate through identified events from most recent to oldest
    for (let i = identifiedEvents.length - 1; i >= 0; i--) {
      const { eventType, eventIndex } = identifiedEvents[i];

      // Found an initiating event ("SilverPennyMintInitiated")
      if (eventType === "SilverPennyMintInitiated") {
        // Look for closing events in subsequent events (events after this initiating event)
        const subsequentEvents = identifiedEvents.slice(i + 1);

        // Initially look for any closing event
        let closingEvent = subsequentEvents.find(e => ["SwordNoBudge", "GrandMiningRewardWon", "RegularMiningRewardWon"].includes(e.eventType));

        // Specific logic if "SwordNoBudge" is found, look for "RegularMiningRewardWon" after it
        if (closingEvent && closingEvent.eventType === "SwordNoBudge") {
          const regularMiningEvent = subsequentEvents.slice(subsequentEvents.indexOf(closingEvent) + 1).find(e => e.eventType === "RegularMiningRewardWon");
          if (regularMiningEvent) {
            closingEvent = regularMiningEvent; // Override with "RegularMiningRewardWon" if it occurs after "SwordNoBudge"
          }
        }

        if (closingEvent) {
          // A closing event is found after the initiating event, attempt is completed
          isStillAttempting = false;
          lastKnownEvent = closingEvent.eventType; // Update last known event to the closing event
          break;  // Stop checking earlier events
        } else {
          // No closing event found after the initiating event, attempt is still ongoing
          isStillAttempting = true;
          lastKnownEvent = eventType; // Keep last known event as the initiating event
          break;  // Stop checking earlier events
        }
      }
    }

    console.log(`🏁 Determined event outcome. isStillAttempting: ${isStillAttempting}, Last Known Event: ${lastKnownEvent}`);
    return { isStillAttempting, lastKnownEvent };
  };


  const analyzeEvents = (events) => {
    console.log("🔍 Analyzing events...");
    const identifiedEvents = findKnownEventType(events);
    const outcome = determineEventOutcome(identifiedEvents);

    console.log(`🏁 Analysis complete. isStillAttempting: ${outcome.isStillAttempting}, Last Known Event: ${outcome.lastKnownEvent}`);
    return outcome; // Return the entire outcome object
  };

  const handleContinuedAttempt = () => {
    if (restartCount >= 3) {
      console.log("❗ Exceeded maximum retries. Maybe something went wrong.");
      maybeSomethingWentWrong();
      setRestartCount(0);
    } else {
      console.log(`🔄 Restarting event check after 30 seconds (${restartCount + 1}/3)`);
      setRestartCount(restartCount + 1);
      eventCheckTimeoutRef.current = setTimeout(checkEventsSequence, 18000);
    }
  };

  const handleCompletedAttempt = (lastEvent) => {
    if (lastEvent === "RegularMiningRewardWon") {
      console.log("🛑 Ending attempt - triggering RegularMiningRewardWon.");
      regularMiningRewardWonTriggerFromEventHistory();
    } else {
      // Assuming any other lastEvent defaults to triggering swordNoBudge
      console.log("🛑 Ending attempt - triggering swordNoBudge.");
      swordNoBudgeTriggerFromEventHistory();
    }
    setRestartCount(0);
  };


  const handleOutcome = ({ isStillAttempting, lastKnownEvent }) => {  // Make sure variables match
    console.log(`🔔 Handling Outcome. isStillAttempting: ${isStillAttempting}, Last Known Event: ${lastKnownEvent}`);

    if (isStillAttempting) {
      console.log("➡️ Continuing attempt due to ongoing event.");
      handleContinuedAttempt();
    } else {
      console.log(`➡️ Completed attempt. Last known event was: ${lastKnownEvent}. Triggering handleCompletedAttempt.`);
      handleCompletedAttempt(lastKnownEvent);  // Ensure this function expects the correct format
    }
  };

  const checkEventsSequence = async () => {
    console.log("✴ Checking if !attemptingPull");
    if (!attemptingPullRef.current) {
      console.log("🚫 No longer attempting. Exiting check sequence.");
      return;
    }

    console.log("🚀 Starting event check sequence...");
    const events = await fetchEvents();
    const outcome = analyzeEvents(events);  // store the entire outcome

    handleOutcome(outcome); // Pass the entire outcome object to handleOutcome
  };


  //MINT REPLICA
  async function handleMintReplica() {
    console.log("🗡✨ handleMintReplica");
    if (attemptingMint) {
      return;
    }

    setAttemptingMint(true);

    playButtonClickedSound();
    changeButtonBorder('mintReplicaButton');
    setTransactionErrorCheck(false);
    setShowCanvas(false);

    try {
      const mintReplicaSwordPrice = utils.parseEther(MINT_REPLICA_SWORD_PRICE.toString());

      // Update the mintReplicaTransactionSubmitted state
      setMintReplicaTransactionSubmitted(true);

      setLoadingNFT(true);
      playLoadingNFTSound();

      const tx = await writeContracts.MerlinsGambitQ1.mintReplicaSword({ value: mintReplicaSwordPrice });
      console.log("tx", tx);

      // Wait for the transaction receipt
      const receipt = await tx.wait();
      console.log("📜 receipt", receipt);
      if (receipt.status !== 0) {
        // Transaction was successful, start the check after a delay
        console.log("🟩 NFT transaction successfully intiated at blockNumber: ", receipt.blockNumber);
        nftMintCheckTimeoutRef.current = setTimeout(() => {
          checkNFTMintSequence(receipt.blockNumber);
        }, 18000); // Start checking after 18 seconds
      } else {
        console.log("⚠ Transaction Failed!");
        setMintReplicaTransactionSubmitted(false);
        setLoadingNFT(false);
        setTransactionErrorCheck(true);
        stopLoadingNFTSound();
        // setSwordImageFile(swordImageFileBackUp);
      }

    } catch (e) {
      console.log(e);
      setMintReplicaTransactionSubmitted(false);
      setLoadingNFT(false);
      setTransactionErrorCheck(true);
      stopLoadingNFTSound();
      setAttemptingMint(false);
      clearTimeout(nftMintCheckTimeoutRef.current);
    }
  }


  async function handleMineSilverPennies() {
    console.log("⛏ handleMineSilverPennies 💿");
    playButtonClickedSound();
    changeButtonBorder('swordPullButton');
    setAttemptingPull(true);
    try {
      const mintCost = utils.parseEther(SILVER_PENNY_MINT_PRICE.toString());
      const tx = await writeContracts.MerlinsGambitQ1.mineSilverPennies({ value: mintCost });
      console.log("tx", tx);
    } catch (e) {
      console.log(e);
      setAttemptingPull(false);
      clearTimeout(eventCheckTimeoutRef.current);
      setTransactionErrorCheck(true);
    }
  }


  function silverPennyMintInitiatedTrigger(event) {
    console.log("✉ SilverPennyMintInitiated event received 💿");

    if (event.args.player === address) {
      setAttemptingPull(true);
      // Clear any existing timeout to avoid multiple checks
      if (eventCheckTimeoutRef.current) {
        clearTimeout(eventCheckTimeoutRef.current);
      }

      // Set the timeout for 30 seconds to start checking the events
      eventCheckTimeoutRef.current = setTimeout(checkEventsSequence, 18000);

      // Set attemptingPull back to false after x seconds
      //setTimeout(() => {
      //  console.log("Setting ATTEMPTING PULL TO FALSE");
      //  setAttemptingPull(false);
      //  }, 4000);
    }
  }

  function regularMiningRewardWonTriggerFromEventHistory() {
    console.log("✉ RegularMiningRewardWon event received 🎁");
    setGameWinningsSinceUpdate(oldValue => oldValue + (SILVER_PENNY_MINT_PRICE * MINING_REWARD_REGULAR))
    // setContractBalance(contractBalance - .2 - .008);
    setContractBalanceSinceUpdate(oldValue => oldValue - ((SILVER_PENNY_MINT_PRICE * MINING_REWARD_REGULAR) - EXCALIBUR_RANDOMNESS_FUND_COST));

    setAttemptingPull(false);
    clearTimeout(eventCheckTimeoutRef.current);
    setTryAgainOrCongrats(true);

    setTimeout(() => {
      setRegularMiningRewardWon(true);
    }, 500);
    // Set regularMiningRewardWon back to false after x seconds
    setTimeout(() => {
      setRegularMiningRewardWon(false);
    }, 6000);

    setTimeout(() => {
      handleSquirrelAdvice();
    }, 18000);


  }


  function regularMiningRewardWonTrigger(event) {
    console.log("✉ RegularMiningRewardWon event received 🎁");
    setGameWinningsSinceUpdate(oldValue => oldValue + (SILVER_PENNY_MINT_PRICE * MINING_REWARD_REGULAR))
    // setContractBalance(contractBalance - .2 - .008);
    setContractBalanceSinceUpdate(oldValue => oldValue - ((SILVER_PENNY_MINT_PRICE * MINING_REWARD_REGULAR) - EXCALIBUR_RANDOMNESS_FUND_COST));
    if (event.args.player === address) {
      setAttemptingPull(false);
      clearTimeout(eventCheckTimeoutRef.current);
      setTryAgainOrCongrats(true);

      setTimeout(() => {
        setRegularMiningRewardWon(true);
      }, 500);
      // Set regularMiningRewardWon back to false after x seconds
      setTimeout(() => {
        setRegularMiningRewardWon(false);
      }, 6000);

      setTimeout(() => {
        handleBirdAdvice();
      }, 18000);

      setTimeout(() => {
        handleSquirrelAdvice();
      }, 34000);

    }
  }


  function grandMiningRewardWonTrigger(event) {
    console.log("✉ GrandMiningRewardWon event received 🏆");

    if (event.args.player === address) {
      setTimeout(() => {
        setAttemptingPull(false);
        clearTimeout(eventCheckTimeoutRef.current);
        setGrandMiningRewardWon(true);
      }, 4000);
      // Set grandMiningRewardWon back to false after x seconds
      setTimeout(() => {
        setGrandMiningRewardWon(false);
      }, 14000);
    }
  }


  function swordNoBudgeTriggerFromEventHistory() {
    console.log("✉ Sword No Budge event received from History 🚫🗡");

    setAttemptingPull(false);
    clearTimeout(eventCheckTimeoutRef.current);
    setTimeout(() => {
      setSwordStaysPut(true);
    }, 700);
    // Set tokensMinted back to false after x seconds
    setTimeout(() => {
      setSwordStaysPut(false);
    }, 4700);

    setTimeout(() => {
      tokensMintedTriggerFromEventHistory();
    }, 5000);

  }

  function swordNoBudgeTrigger(event) {
    console.log("✉ Sword No Budge event received 🚫🗡");

    if (event.args.player === address) {
      setAttemptingPull(false);
      clearTimeout(eventCheckTimeoutRef.current);
      setTimeout(() => {
        setSwordStaysPut(true);
      }, 700);
      // Set tokensMinted back to false after x seconds
      setTimeout(() => {
        setSwordStaysPut(false);
      }, 4700);

      setTimeout(() => {
        tokensMintedTrigger(event);
      }, 5000);

    }
  }

  function tokensMintedTriggerFromEventHistory() {
    console.log("✉ TokensMinted event received 💿");

    console.log("INCREMENTING SILVER PENNY BALANCE BY 100");
    setPenniesMintedSinceUpdate(oldValue => oldValue + 100);

    setRegularMiningRewardWon(false);
    setSwordStaysPut(false);
    setTokensMinted(true);
    setPage(2);

    // Set tokensMinted back to false after 4 seconds
    setTimeout(() => {
      setTokensMinted(false);
    }, 3000);

    setTimeout(() => {
      setTryAgainMessage(true);
    }, 3500);
    // Set tokensMinted back to false after x seconds
    setTimeout(() => {
      setTryAgainMessage(false);
      setTryAgainOrCongrats(false);
    }, 7000);

  }


  function tokensMintedTrigger(event) {
    console.log("✉ TokensMinted event received 💿");
    if (event.args.player === address) {
      console.log("INCREMENTING SILVER PENNY BALANCE BY 100");
      setPenniesMintedSinceUpdate(oldValue => oldValue + 100);

      setRegularMiningRewardWon(false);
      setSwordStaysPut(false);
      setTokensMinted(true);
      setPage(2);

      // Set tokensMinted back to false after 4 seconds
      setTimeout(() => {
        setTokensMinted(false);
      }, 3000);

      setTimeout(() => {
        setTryAgainMessage(true);
      }, 3500);
      // Set tokensMinted back to false after x seconds
      setTimeout(() => {
        setTryAgainMessage(false);
        setTryAgainOrCongrats(false);
      }, 7000);
    }
  }

  const [displayDuelResults, setDisplayDuelResults] = useState(false);
  const [duelResultsFromEvent, setDuelResultsFromEvent] = useState(null);


  async function swordDuelResultsTrigger(event) {
    clearTimeout(duelCheckTimeoutRef.current)
    console.log("✉ SwordDuelResults event received ⚔");

    if (DEBUG) console.log(`🟤 event.args.player: ${event.args.player}`);
    if (DEBUG) console.log(`🟤 Your Address: ${address}`);

    if (event.args.player === address) {
      setPage(3);
      // Save the event arguments into the state
      const contractDataTokenId1 = await readContracts.MerlinsGambitQ1.getContractDataByID(event.args.tokenId1);
      const contractDataTokenIdDefensiveSword = await readContracts.MerlinsGambitQ1.getContractDataByID(event.args.tokenIdDefensiveSword);

      getDuelsWonById(contractDataTokenId1);
      getDuelCountByTokenId(contractDataTokenId1);
      getDuelCountByTokenId(contractDataTokenId1, "OffensiveSword");
      getDuelCountByTokenId(contractDataTokenIdDefensiveSword, "DefensiveSword");

      setTimeout(() => {
        setAttemptingDuel(false);
      }, 2000);


      setDuelResultsFromEvent({
        offensiveSword: event.args.tokenId1.toString(),
        defensiveSword: event.args.tokenIdDefensiveSword.toString(),
        winningSword: event.args.tokenIdWinningSword.toString(),
      });


      setTimeout(() => {
        setDisplayDuelResults(true);
      }, 200);

      setTimeout(() => {
        setDisplayDuelResults(false);
        // Clear the duel results when they're no longer being displayed
        setDuelResultsFromEvent(null);
      }, 9000);
    }
  }

  async function swordDuelResultsTriggerFromEventHistory(tokenId1, tokenIdDefensiveSword, tokenIdWinningSword) {
    clearTimeout(duelCheckTimeoutRef.current)
    console.log("✉ SwordDuelResults event received ⚔");

    console.log(`🗡️ Offense Token ID: ${tokenId1}`);
    console.log(`🛡️ Defense Token ID: ${tokenIdDefensiveSword}`);
    console.log(`🏆 Winning Token ID: ${tokenIdWinningSword}`);


    setPage(3);
    // Save the event arguments into the state
    const contractDataTokenId1 = await readContracts.MerlinsGambitQ1.getContractDataByID(tokenId1);
    const contractDataTokenIdDefensiveSword = await readContracts.MerlinsGambitQ1.getContractDataByID(tokenIdDefensiveSword);

    getDuelsWonById(contractDataTokenId1);
    getDuelCountByTokenId(contractDataTokenId1);
    getDuelCountByTokenId(contractDataTokenId1, "OffensiveSword");
    getDuelCountByTokenId(contractDataTokenIdDefensiveSword, "DefensiveSword");

    setTimeout(() => {
      setAttemptingDuel(false);
    }, 2000);


    setDuelResultsFromEvent({
      offensiveSword: tokenId1.toString(),
      defensiveSword: tokenIdDefensiveSword.toString(),
      winningSword: tokenIdWinningSword.toString(),
    });


    setTimeout(() => {
      setDisplayDuelResults(true);
    }, 200);

    setTimeout(() => {
      setDisplayDuelResults(false);
      // Clear the duel results when they're no longer being displayed
      setDuelResultsFromEvent(null);
    }, 9000);

  }



  function randomMiningRewardWonTrigger(event) {
    console.log("✉ RandomMiningRewardWon event received 🍀");
    setGameWinningsSinceUpdate(oldValue => oldValue + SILVER_PENNY_MINT_PRICE)
    setContractBalanceSinceUpdate(oldValue => oldValue - SILVER_PENNY_MINT_PRICE);

    if (event.args.player === address) {
      setRandomMiningRewardWon(true);
      // Set randomMiningRewardWon back to false after 4 seconds
      setTimeout(() => {
        setRandomMiningRewardWon(false);
      }, 10000);
    }
  }


  function ExcaliburMintedTrigger(event) {
    console.log("✉ NFTMinted Excalibur event received 🖼");

    if (event.args.player === address) {
      setTokenIdNFT(2);
      console.log("🖼 NFTMinted TokenId: ", event.args.tokenId);
      setTimeout(() => {
        setNFTMinted(true);
      }, 14000);
      // Set NFTMinted back to false after 20 seconds
      setTimeout(() => {
        setNFTMinted(false);
      }, 20000);

      fetchContractDataByAddress(address);
      setDisplayNftCounter(0);

    }
  }

  async function NFTMintedTriggerFromEventHistory() {
    console.log("✉ NFTMinted event received FromEventHistory 🖼");
    //  console.log("📨 recipient: ", address);
    //  console.log("📨 address: ", address);

    //  console.log("🖼 NFTMinted TokenId: ", event.args.tokenId1);
    //  const tokenId = event.args.tokenId1;
    //  if (tokenId > 2) {
    //    const nftName = 'Replica ' + tokenId;
    //    setNFTName(nftName);
    //  }

    const tokenIds = await fetchContractDataByAddress(address);

    if (tokenIds.length > 0) {
      const contractDataByID = await readContracts.MerlinsGambitQ1.getContractDataByID(tokenIds[tokenIds.length - 1])
      setTimeout(() => {
        getSwordProperties(contractDataByID);
        getSharpnessData(contractDataByID);
        getDuelCountByTokenId(contractDataByID);
        getSwordRatingData(contractDataByID);
        getSwordDigitKeyData(contractDataByID);
      }, 200);  // 200ms 
    }

    setTimeout(() => {
      setLoadingNFT(false);
      stopLoadingNFTSound();
      setOwnedTokenIds(tokenIds);
      setDisplayNftCounter(tokenIds.length - 1);
      setDisplayOwnedNfts(true);
      //   setTokenIdNFT(tokenId);
      setAttemptingMint(false);
      clearTimeout(nftMintCheckTimeoutRef.current);
      //setNFTMinted(false);
    }, 200);  // 200ms 

    setTimeout(() => {
      setNFTMinted(false);
    }, 5000);  // 5 seconds 

    setTimeout(() => {
      setIsMuted((isMuted) => {
        console.log(`!isMuted: `, !isMuted);
        if (corkPop && !isMuted) {
          corkPop.play();
        }
        return isMuted;
      });
    }, 200); // 200ms 

    setAddressNFTOwner(address);
    setMintReplicaTransactionSubmitted(false);
    setNFTMinted(true);
  }

  async function NFTMintedTrigger(event) {
    console.log("✉ NFTMinted event received 🖼");
    console.log("📨 recipient: ", event.args.player);
    console.log("📨 address: ", address);

    console.log("🖼 NFTMinted TokenId: ", event.args.tokenId1);
    const tokenId = event.args.tokenId1;
    if (tokenId > 2) {
      const nftName = 'Replica ' + tokenId;
      setNFTName(nftName);
    }

    const tokenIds = await fetchContractDataByAddress(address);

    if (tokenIds.length > 0) {
      const contractDataByID = await readContracts.MerlinsGambitQ1.getContractDataByID(tokenIds[tokenIds.length - 1])
      setTimeout(() => {
        getSwordProperties(contractDataByID);
        getSharpnessData(contractDataByID);
        getDuelCountByTokenId(contractDataByID);
        getSwordRatingData(contractDataByID);
        getSwordDigitKeyData(contractDataByID);
      }, 200);  // 200ms 
    }

    setTimeout(() => {
      setLoadingNFT(false);
      stopLoadingNFTSound();
      setOwnedTokenIds(tokenIds);
      setDisplayNftCounter(tokenIds.length - 1);
      setDisplayOwnedNfts(true);
      setTokenIdNFT(tokenId);
      setAttemptingMint(false);
      clearTimeout(nftMintCheckTimeoutRef.current);
      //setNFTMinted(false);
    }, 200);  // 200ms 

    setTimeout(() => {
      setNFTMinted(false);
    }, 5000);  // 5 seconds 

    setTimeout(() => {
      setIsMuted((isMuted) => {
        console.log(`!isMuted: `, !isMuted);
        if (corkPop && !isMuted) {
          corkPop.play();
        }
        return isMuted;
      });
    }, 200); // 200ms 

    setAddressNFTOwner(address);
    setMintReplicaTransactionSubmitted(false);
    setNFTMinted(true);
  }

  // Fetch Contract Data
  useEffect(() => {
    fetchCurrentContractData();
  }, [readContracts, address]);

  useEffect(() => {
    console.log(`USE EFFECT fetchOwnedTokenIds`);
    console.log(`address: `, address);
    fetchOwnedTokenIds(address);
  }, [readContracts, address]);

  const playLoadingNFTSound = () => {
    if (loadingNFTSound && !isMuted) {
      loadingNFTSound.volume = 0.4;
      loadingNFTSound.loop = true;
      loadingNFTSound.play();
    }
  };

  const stopLoadingNFTSound = () => {
    if (loadingNFTSound) {
      loadingNFTSound.loop = false;
      loadingNFTSound.pause();
      // if you want to start the sound from the beginning next time it plays
      loadingNFTSound.currentTime = 0;
    }
  };

  const playButtonClickedSound = () => {
    if (buttonClickedSound && !isMuted) {
      buttonClickedSound.volume = 0.1;
      buttonClickedSound.currentTime = 0.1; //to make click seem quicker
      buttonClickedSound.play();
    }
  };

  const playCoinSound = () => {
    console.log("Inside of playCoinSound()");
    console.log("coinSound:", coinSound);
    console.log("isMuted:", isMuted);
    if (coinSound && !isMuted) {
      // if (coinSound) {
      console.log("Inside of playCoinSound() IF STATEMENT");
      coinSound.play();
    }
  };

  const playCorkPop = () => {
    if (corkPop && !isMuted) {
      corkPop.play();
    }
  };

  const playGruntingSound = () => {
    // Define the array of [start, duration] pairs
    const timePairs = [[0, 1.2], [1.3, 1.1], [2.5, 1.1], [3.7, 1.1], [4.9, 1.1], [6.1, 2], [8.2, 1.1], [9.4, 1.1], [10.6, 1.1], [11.7, 3]];

    if (gruntingSound && !isMuted) {
      // Pick a random pair from the array
      const randomPair = timePairs[Math.floor(Math.random() * timePairs.length)];

      gruntingSound.volume = 0.2;
      gruntingSound.currentTime = randomPair[0]; // Use the start time from the pair
      gruntingSound.play();

      // Stop playing after the duration from the pair
      setTimeout(() => {
        gruntingSound.pause();
        gruntingSound.currentTime = 0; // Optional: reset the clip to the start
      }, randomPair[1] * 1000); // Multiply by 1000 to convert seconds to milliseconds
    }
  };

  const playSwordDuelSound = () => {
    // Define the array of [start, duration] pairs
    const timePairs = [[16, 6]];

    if (swordDuelSound && !isMuted) {
      // Pick a random pair from the array
      const randomPair = timePairs[Math.floor(Math.random() * timePairs.length)];

      swordDuelSound.volume = 0.6;
      swordDuelSound.currentTime = randomPair[0]; // Use the start time from the pair
      swordDuelSound.play();

      // Stop playing after the duration from the pair
      setTimeout(() => {
        swordDuelSound.pause();
        swordDuelSound.currentTime = 0; // Optional: reset the clip to the start
      }, randomPair[1] * 1000); // Multiply by 1000 to convert seconds to milliseconds
    }
  };

  const playSuccessTrumpetsSound = () => {
    // Define the start and duration values directly
    const startTime = 0; // Replace with the actual start time of your sound clip
    const duration = 5;   // Replace with the actual duration of your sound clip

    if (successTrumpetsSound && !isMuted) {
      successTrumpetsSound.volume = 0.6;
      successTrumpetsSound.currentTime = startTime;
      successTrumpetsSound.play();

      // Stop playing after the duration
      setTimeout(() => {
        successTrumpetsSound.pause();
        successTrumpetsSound.currentTime = 0; // Optional: reset the clip to the start
      }, duration * 1000); // Multiply by 1000 to convert seconds to milliseconds
    }
  };

  const playSwordCreationSound = () => {
    // Define the array of [start, duration] pairs
    const timePairs = [[0, 1.2], [1.3, 1.1], [2.5, 1.1], [3.7, 1.1], [4.9, 1.1], [6.1, 2], [8.2, 1.1], [9.4, 1.1], [10.6, 1.1], [11.7, 3]];

    if (swordCreationSound && !isMuted) {
      // Pick a random pair from the array
      const randomPair = timePairs[Math.floor(Math.random() * timePairs.length)];

      swordCreationSound.volume = 0.2;
      swordCreationSound.currentTime = randomPair[0]; // Use the start time from the pair
      swordCreationSound.play();

      // Stop playing after the duration from the pair
      setTimeout(() => {
        swordCreationSound.pause();
        swordCreationSound.currentTime = 0; // Optional: reset the clip to the start
      }, randomPair[1] * 1000); // Multiply by 1000 to convert seconds to milliseconds
    }
  };

  const playSwordSharpenSound = () => {
    // Define the array of [start, duration] pairs
    const timePairs = [[3, 4]];

    if (swordCreationSound && !isMuted) {
      // Pick a random pair from the array
      const randomPair = timePairs[Math.floor(Math.random() * timePairs.length)];

      swordCreationSound.volume = 0.7;
      swordCreationSound.currentTime = randomPair[0]; // Use the start time from the pair
      swordCreationSound.play();

      // Stop playing after the duration from the pair
      setTimeout(() => {
        swordCreationSound.pause();
        swordCreationSound.currentTime = 0; // Optional: reset the clip to the start
      }, randomPair[1] * 1000); // Multiply by 1000 to convert seconds to milliseconds
    }
  };

  const playHmmSound = () => {
    // Define the array of [start, duration] pairs
    const timePairs = [[6, 1.5], [22, 1.2], [23.9, 1.4], [26.4, 2]];

    if (hmmSound && !isMuted) {
      // Pick a random pair from the array
      const randomPair = timePairs[Math.floor(Math.random() * timePairs.length)];

      hmmSound.volume = 0.5;
      hmmSound.currentTime = randomPair[0]; // Use the start time from the pair
      hmmSound.play();

      // Stop playing after the duration from the pair
      setTimeout(() => {
        hmmSound.pause();
        hmmSound.currentTime = 0; // Optional: reset the clip to the start
      }, randomPair[1] * 1000); // Multiply by 1000 to convert seconds to milliseconds
    }
  };



  useEffect(() => {
    if (attemptingPull && !isMuted) {
      playGruntingSound();
    }
  }, [attemptingPull, isMuted]);

  useEffect(() => {
    if (regularMiningRewardWon && !isMuted) {
      playSuccessTrumpetsSound();
    }
  }, [regularMiningRewardWon, isMuted]);

  useEffect(() => {
    if (swordStaysPut && !isMuted) {
      playHmmSound();
    }
  }, [swordStaysPut, isMuted]);

  useEffect(() => {
    if (tokensMinted && !isMuted) {
      playCoinSound();
    }
  }, [tokensMinted, isMuted]);

  useEffect(() => {
    const audio = new Audio('/birdSounds.mp3');
    audio.loop = true;

    // Load sounds
    const nftAudio = new Audio('/loadingNFT.mp3');
    const buttonAudio = new Audio('/buttonClicked.mp3');
    const coinAudio = new Audio('/coinSound.mp3');
    const corkPopAudio = new Audio('/corkPop.mp3');
    const gruntingSoundAudio = new Audio('/gruntingSound.mp3');
    const hmmSoundAudio = new Audio('/hmmSound.mp3');
    const swordDuelSoundAudio = new Audio('/swordDuelSound.mp3');
    const swordCreationSoundAudio = new Audio('/swordCreationSound.mp3');
    const successTrumpetsAudio = new Audio('/successTrumpets.mp3');



    audio.addEventListener('canplay', () => {
      setBackgroundMusic(audio);
      setLoadingNFTSound(nftAudio);
      setButtonClickedSound(buttonAudio);
      setCoinSound(coinAudio);
      setCorkPop(corkPopAudio);
      setGruntingSound(gruntingSoundAudio);
      setHmmSound(hmmSoundAudio);
      setSwordDuelSound(swordDuelSoundAudio);
      setSwordCreationSound(swordCreationSoundAudio);
      setSuccessTrumpetsSound(successTrumpetsAudio);
    });

    //audio.play();

    return () => {
      if (backgroundMusic) {
        backgroundMusic.pause();
        setBackgroundMusic(null);
      }

      if (loadingNFTSound) {
        loadingNFTSound.pause();
        setLoadingNFTSound(null);
      }

      if (buttonClickedSound) {
        buttonClickedSound.pause();
        setButtonClickedSound(null);
      }

      if (coinSound) {
        coinSound.pause();
        setCoinSound(null);
      }

      if (gruntingSound) {
        gruntingSound.pause();
        setGruntingSound(null);
      }

      if (corkPop) {
        corkPop.pause();
        setCorkPop(null);
      }

      if (hmmSound) {
        hmmSound.pause();
        setHmmSound(null);
      }

      if (swordDuelSound) {
        swordDuelSound.pause();
        setSwordDuelSound(null);
      }

      if (swordCreationSound) {
        swordCreationSound.pause();
        setSwordCreationSound(null);
      }

      if (successTrumpetsSound) {
        successTrumpetsSound.pause();
        setSuccessTrumpetsSound(null);
      }


    };
  }, []);


  useEffect(() => {
    if (!loadingNFT) { // If loadingNFT is set to false
      // Confetti will automatically trigger due to the active prop in the Confetti component
    } else {
      console.log("useEffect if not loadingNFT");
      setSwordImageFileBackUp(swordImageFile);
      let count = 10;
      const interval = setInterval(() => {
        setSwordImageFile(`loadingSwords/${count}.png`);
        count = ((count + 1) % 70) + 10;  // Increase the count by 1 and make it roll back to 10 after it reaches 100.
      }, 120); // Set the interval to 80 milliseconds

      // This cleanup function will be called when loadingNFT changes to stop the interval
      return () => {
        clearInterval(interval);
      };
    }
  }, [loadingNFT]);

  useEffect(() => {
    if (!loadingOnchainNFT) { // If loadingNFT is set to false
      // Confetti will automatically trigger due to the active prop in the Confetti component
    } else {
      console.log("useEffect if not loadingOnchainNFT");
      setSwordImageFileBackUp(swordImageFile);
      let count = 10;
      const interval = setInterval(() => {
        count = ((count + 1) % 70) + 10;  // Increase the count by 1 and make it roll back to 10 after it reaches 100.
        setSwordImageFile(`loadingOnchainSwords/${count}.png`);
      }, 120); // Set the interval to 80 milliseconds

      // This cleanup function will be called when loadingNFT changes to stop the interval
      return () => {
        clearInterval(interval);
      };
    }
  }, [loadingOnchainNFT]);


  useEffect(() => {
    if (transactionErrorCheck) {
      setShowTransactionErrorMessage(true);
      setTimeout(() => {
        setShowTransactionErrorMessage(false);
      }, 5000);
    }
  }, [transactionErrorCheck]);

  useEffect(() => {
    if (loadingOnchainNFT) {
      setShowLoadingOnchainNFTMessage(true);
      setTimeout(() => {
        setShowLoadingOnchainNFTMessage(false);
      }, 15000);
    }
  }, [loadingOnchainNFT]);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (showSwordExamples && !swordExamplesRef.current.contains(event.target)) {
        setShowSwordExamples(false);
      }
      if (showFinePrint && !finePrintRef.current.contains(event.target)) {
        setShowFinePrint(false);
      }
      if (showAbout && !aboutRef.current.contains(event.target)) {
        setShowAbout(false);
      }
      if (showBook && !bookRef.current.contains(event.target)) {
        setShowBook(false);
      }
      if (showInitialPopup && initialPopupRef.current && !initialPopupRef.current.contains(event.target)) {
        setShowInitialPopup(false);
      }
    }

    // Attach the listeners to the document
    document.addEventListener("mousedown", handleOutsideClick);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showSwordExamples, showFinePrint, showAbout, showBook, showInitialPopup]); // Re-run if any modal state changes 

  useEffect(() => {
    console.log('UseEffect triggered, current opacity is:', easterEggOpacity);
    if (easterEggOpacity >= 1) {
      setEasterEggRevealed(true);
      console.log('Easter egg revealed');
    }
  }, [easterEggOpacity]);


  const handleGameEventCounters = (player, eventType, tokenId1, tokenIdDefensiveSword, tokenIdWinningSword, event) => {

    switch (eventType) {
      case "SwordNoBudge":
        console.log(`🧮 gameEventListener Counters: SwordNoBudge`);
        break;
      case "RegularMiningRewardWon":
        console.log(`🧮 gameEventListener Counters: RegularMiningRewardWon`);
        setEthWonCounter(prevCounter => prevCounter + .2);
        break;
      case "SilverPennyMintInitiated":
        console.log(`🧮 gameEventListener Counters: SilverPennyMintInitiated COUNTER`);
        setSwordPullCounter(prevCounter => prevCounter + 1);
        break;
      case "GrandMiningRewardWon":
        console.log(`🧮 gameEventListener Counters: GrandMiningRewardWon`);
        setContractBalance(0);
        setContractBalanceSinceUpdate(0);
        break;
      case "TokensMinted":
        console.log(`🧮 gameEventListener Counters: TokensMinted`);
        setTotalSilverPenniesSupplyChangeSinceUpdate(oldValue => oldValue + 100);
        setContractBalanceSinceUpdate(oldValue => oldValue + SILVER_PENNY_MINT_PRICE);
        break;
      case "NFTMinted":
        console.log(`🧮 gameEventListener: NFTMinted`);
        setMintCounterNFT(prevCounter => prevCounter + 1);
        setContractBalanceSinceUpdate(oldValue => oldValue + SILVER_PENNY_MINT_PRICE);
        break;
      case "RandomMiningRewardWon":
        console.log(`🧮 gameEventListener: RandomMiningRewardWon`);
        setEthWonCounter(prevCounter => prevCounter + .01);
        randomMiningRewardWonTrigger(event);
        break;
      default:
        console.warn(`🧮 Unhandled game event type Counters: ${eventType}`);
    }
  };

  const handleGameEventForPlayer = (player, eventType, tokenId1, tokenIdDefensiveSword, tokenIdWinningSword, event) => {
    switch (eventType) {
      case "SwordNoBudge":
        console.log(`🎪⛰ gameEventListener: SwordNoBudge 🚫🗡`);
        if (player === address) {
          swordNoBudgeTrigger(event);  //THIS MUST BE UNCOMMENTED, comment to test fallback transaction catch
        }
        break;
      case "RegularMiningRewardWon":
        console.log(`🎪🎁 gameEventListener: RegularMiningRewardWon 🦄`);
        if (player === address) {
          regularMiningRewardWonTrigger(event); //THIS MUST BE UNCOMMENTED, comment to test fallback transaction catch
        }
        break;
      case "SilverPennyMintInitiated":
        console.log(`🎪🗡 gameEventListener: SilverPennyMintInitiated 🎲`);
        if (DEBUG) console.log(`🔵 Player Address: ${player}`);
        if (DEBUG) console.log(`🔵 Your Address: ${address}`);
        if (player === address) {
          silverPennyMintInitiatedTrigger(event);
        }
        break;
      case "GrandMiningRewardWon":
        console.log(`🎪🏆 gameEventListener: GrandMiningRewardWon`);
        setExcaliburFreed(true);
        if (player === address) {
          setGameWinner(true);
          grandMiningRewardWonTrigger(event);
          //getTokenOwner(2);
          //getSwordProperties(2);
          setSwordImageFile(`https://merlinsgambitblob.blob.core.windows.net/sword/0000.png`);
          setSwordImageFileBackUp(`https://merlinsgambitblob.blob.core.windows.net/sword/0000.png`);
          setSwordHilt('Gold');
          setSwordGrip('Leather');
          setSwordBlade('Merlin Steel');
          setSwordJewel('Green Emerald');
          setSharpnessData('Honed');
          setNFTName('Excalibur');
          setAddressNFTOwner(address)
          setSwordRatingData('100');
          setDuelCount('100');
        } else {
          setWinningPlayerAddress(player);
          console.log("nftName INSIDE GRAND: ", nftName);
          if (nftName === 'Excalibur') {
            setAddressNFTOwner(player);
          }
        }
        break;
      case "TokensMinted":
        console.log(`🎪💿 gameEventListener: TokensMinted ➕💯SPNY`);
        // if (player === address) {
        //   tokensMintedTrigger(event);
        //   setPage(2);
        // }
        break;
      case "SwordDuelResults":
        console.log(`⚔ gameEventListener: SwordDuelResults 🏁`);
        console.log(`📚 ownedTokenIds:`, ownedTokenIds);
        console.log(`🛡 tokenIdDefensiveSword:`, tokenIdDefensiveSword);

        if (player === address) {
          console.log(`Offensive sword`);
          setPenniesMintedSinceUpdate(oldValue => oldValue - 12)
        }

        if (ownedTokenIds.includes(tokenIdDefensiveSword)) {
          console.log(`Defensive sword`);
          setPenniesMintedSinceUpdate(oldValue => oldValue + 12)
        }

        if (player === address || ownedTokenIds.includes(tokenIdDefensiveSword)) {
          console.log(`Sword Duel Results: 
                      Offensive Sword = ${tokenId1}, 
                      Defensive Sword = ${tokenIdDefensiveSword}, 
                      Winning Sword = ${tokenIdWinningSword}`);
          swordDuelResultsTrigger(event);  //THIS LINE MUST BE UNCOMMENTED, but comment to test duel stuck catch
        }
        break;
      case "NFTMinted":
        console.log(`🖼 gameEventListener: NFTMinted`);
        if (player === address) {
          console.log(`NFT Minted: tokenId=${tokenId1}`);
          if (tokenId1 == 2) {
            ExcaliburMintedTrigger(event);
          } else {
            NFTMintedTrigger(event);  //THIS LINE MUST BE UNCOMMENTED, but comment to test NFT mint catch
          }
          setPage(1);
        }
        break;
      case "RandomMiningRewardWon":
        console.log(`🎪🍀 gameEventListener: RandomMiningRewardWon`);
        randomMiningRewardWonTrigger(event);
        break;
      default:
        console.warn(`Unhandled game event type: ${eventType}`);
    }
  };

  const processedEventIdsRef = useRef(new Set());
  const processedEventIdsRefAddressCheck = useRef(new Set());

  // Event listeners
  // calls MerlinsGambitQ1.provider.getBlockNumber()
  // turns on MerlinsGambitQ1 GameEvent
  useEffect(() => {
    console.log("👂 Setting up listeners with address:", address);

    const setupListeners = async () => {
      console.log(`🔉 setupListeners with gameEventListener fired 🔥`);
      if (readContracts && readContracts.MerlinsGambitQ1 && address) {

        console.log(`setupListeners creating gameEventListener`);
        const gameEventListener = (player, eventType, tokenId1, tokenIdDefensiveSword, tokenIdWinningSword, event) => {

          const eventId = event.transactionHash + "-" + event.logIndex;
          const eventIdAddressCheck = event.transactionHash + "-" + event.logIndex + "-" + address;

          if (DEBUG) console.log(`✉ Received event: ${eventType}`);
          if (DEBUG) console.log(`Transaction hash: ${event.transactionHash}`);
          if (DEBUG) console.log(`Log index: ${event.logIndex}`);
          if (DEBUG) console.log(`🆔 Event unique identifier (eventId): ${eventId}`);

          if (DEBUG) console.log(`🆔📭 Event unique identifier Address (eventIdAddressCheck): ${eventIdAddressCheck}`);

          // Check if the event has already been processed using the ref
          if (processedEventIdsRef.current.has(eventId)) {
            console.log("⚠🧮 Duplicate event detected and ignored:", eventId);
            //return;
          } else {
            // If not processed, add the event ID to the ref
            processedEventIdsRef.current.add(eventId);
            console.log(`🧮 Processing Game Counters (eventId): ${eventId}`);
            handleGameEventCounters(player, eventType, tokenId1, tokenIdDefensiveSword, tokenIdWinningSword, event);
          }

          // Check if the event has already been processed using the ref
          if (processedEventIdsRefAddressCheck.current.has(eventIdAddressCheck)) {
            console.log("⚠ Duplicate event detected and ignored:", eventIdAddressCheck);
            //return;
          } else {
            // If not processed, add the event ID to the ref
            processedEventIdsRefAddressCheck.current.add(processedEventIdsRefAddressCheck);
            console.log(`💻 Processing (eventIdAddressCheck): ${eventIdAddressCheck}`);
            handleGameEventForPlayer(player, eventType, tokenId1, tokenIdDefensiveSword, tokenIdWinningSword, event);
          }

        };
        console.log(`🎳 GameEvent: on 💡`);
        readContracts.MerlinsGambitQ1.on("GameEvent", gameEventListener);
        return () => {
          console.log(`🎳 GameEvent: off ⚫`);
          readContracts.MerlinsGambitQ1.off("GameEvent", gameEventListener);
        };
      }
    };
    console.log(`🎧 listening...`);
    setupListeners();
  }, [readContracts, address]);




  const getSharpnessData = async (contractDataByID, swordType = null) => {
    console.log("👓 getSharpnessData 🔪");
    try {
      const sharpnessString = contractDataByID.sharpnessString;
      console.log(`🔪 Sharpness: `, sharpnessString);

      if (swordType) {
        if (swordType === "OffensiveSword") {
          setSharpnessDataOffensiveSword(sharpnessString);
        } else if (swordType === "DefensiveSword") {
          setSharpnessDataDefensiveSword(sharpnessString);
        } else {
          // Handle error: Invalid swordType
          console.error("Invalid swordType:", swordType);
        }
      } else {
        setSharpnessData(sharpnessString);
      }

    } catch (error) {
      console.error(`Error fetching sharpness for token`);
    }
  };


  const updateSharpness = async (tokenId, swordType = null) => {
    console.log("🔪 updateSharpness 🔪");
    if (writeContracts && writeContracts.MerlinsGambitQ1) {
      try {
        // Call the updateSharpness function in the contract
        const updateSharpnessTx = await writeContracts.MerlinsGambitQ1.updateSharpness(tokenId);
        console.log("tx", updateSharpnessTx);

        // Wait for the transaction to be mined
        const receipt = await updateSharpnessTx.wait();

        console.log("💿 Going to update SPNY balance and supply 🔪");
        //Instead of doing these extra calls just to check the updated balances, we can just update them on the frontend
        setPenniesMintedSinceUpdate(oldValue => oldValue - 131)
        setTotalSilverPenniesSupplyChangeSinceUpdate(oldValue => oldValue - 131);

        const contractDataByID = await writeContracts.MerlinsGambitQ1.getContractDataByID(tokenId);
        // Refresh the sharpness data for the updated tokenId, using the provided swordType
        getSharpnessData(contractDataByID, swordType);
        playSwordSharpenSound();

        console.log(`🔪 Sharpness updated for token ID ${tokenId}. 📜 Transaction receipt:`, receipt);
      } catch (error) {
        console.error(`Error updating sharpness for token ID ${tokenId}:`, error);
        setTransactionErrorCheck(true);
      }
    }
  };

  //TODO REMOVE THIS OLD function
  const getTotalSwordPulls = async (currentContractData) => {
    console.log("👓 getTotalSwordPulls 🗡");
    try {
      const swordPullsCount = currentContractData.swordPullCounter;

      console.log("🗡 Total sword pulls:", swordPullsCount.toString());

      // Set the state with the retrieved data
      setTotalSwordPulls(swordPullsCount.toString());

    } catch (error) {
      console.error("Error fetching the total number of sword pulls:", error);
    }
  };

  const handleGetTokenUriClick = async () => {
    console.log("Fetching token URI");
    if (tokenIdNFT) { // Assuming you have a variable `tokenIdNFT` holding the token ID
      console.log(`Fetching URI for token ID:`, tokenIdNFT);
      await getTokenUri(tokenIdNFT); // Call getTokenUri function with the tokenId
    } else {
      console.error("Token ID is not available");
      // Handle case where tokenId is not available
    }
  };

  const handleViewOnchainClick = async () => {
    console.log("handleViewOnchainClick");
    if (tokenIdNFT) {
      setLoadingOnchainNFT(true);
      console.log(`handleViewOnchainClick`, tokenIdNFT);
      await getTokenData(tokenIdNFT); // Call getTokenData function with a tokenId      
    }
    // Include any other operations here
  };

  const handleSharpenSwordClick = async () => {
    console.log("handleSharpenSwordClick");
    playButtonClickedSound();
    changeButtonBorder('sharpenSwordButton');
    if (tokenIdNFT) {
      console.log(`handleSharpenSwordClick`, tokenIdNFT);
      await updateSharpness(tokenIdNFT);
    }
  };

  const handleSharpenOffensiveSwordClick = async () => {
    console.log("handleSharpenOffensiveSwordClick");
    playButtonClickedSound();
    changeButtonBorder('sharpenOffensiveSwordButton');
    if (tokenIdOffensiveSword) {
      console.log(`handleSharpenOffensiveSwordClick`, tokenIdOffensiveSword);
      await updateSharpness(tokenIdOffensiveSword, "OffensiveSword");
      if (tokenIdOffensiveSword && tokenIdDefensiveSword) {
        await getDuelMatchUpRating(tokenIdOffensiveSword, tokenIdDefensiveSword);
      }
    }
  };

  const [swordRatingData, setSwordRatingData] = useState(null);
  const [swordRatingDataOffensiveSword, setSwordRatingDataOffensiveSword] = useState(null);
  const [swordRatingDataDefensiveSword, setSwordRatingDataDefensiveSword] = useState(null);

  const getSwordRatingData = async (contractDataByID, swordType = null) => {
    console.log("👓 getSwordRatingData 📊");
    try {
      const swordRating = contractDataByID.swordRatingFromTokenId;
      const swordRatingString = swordRating.toString();  // convert BigNumber to string
      console.log("📊 getSwordRatingData: ", swordRatingString);

      if (swordType) {
        if (swordType === "OffensiveSword") {
          setSwordRatingDataOffensiveSword(swordRatingString);
        } else if (swordType === "DefensiveSword") {
          setSwordRatingDataDefensiveSword(swordRatingString);
        } else {
          // Handle error: Invalid swordType
          console.error("Invalid swordType:", swordType);
        }
      } else {
        setSwordRatingData(swordRatingString);
      }

    } catch (error) {
      console.error(`Error fetching sword rating`, error);
    }
  };

  const DUEL_CHALLENGE_COST = utils.parseEther(COST_TO_CHALLENGE_ETH.toString());

  const resetNotEnoughFunds = () => {
    console.log("resetNotEnoughFunds");
    setTimeout(() => {
      setNotEnoughFunds(false);
    }, 5000); // 5000 milliseconds = 5 seconds
  };


  const challengeToDuel = async (tokenIdOffense, tokenIdDefense) => {
    console.log("challengeToDuel");

    if (attemptingDuel) {
      return;
    }

    setNotEnoughFunds(false);

    //TODO: this is not the current value if events have happened
    if (currentSpnyBalance < 12) {
      setNotEnoughFunds(true); // set notEnoughFunds to true
      resetNotEnoughFunds();
      return; // return from the function
    }

    setAttemptingDuel(true);

    if (writeContracts && writeContracts.MerlinsGambitQ1) {
      try {
        const challengeTx = await writeContracts.MerlinsGambitQ1.challengeToDuel(tokenIdOffense, tokenIdDefense, { value: DUEL_CHALLENGE_COST });
        playSwordDuelSound();
        console.log("🚀 Transaction sent: ", challengeTx.hash);

        const receipt = await challengeTx.wait();
        console.log("✅ Transaction has been confirmed: ", receipt.blockNumber);

        if (receipt.status !== 0) {
          // Transaction was successful, start the check after a delay
          console.log("🟩 Duel transaction successfully initiated at blockNumber: ", receipt.blockNumber);
          duelCheckTimeoutRef.current = setTimeout(() => {
            checkDuelSequence(receipt.blockNumber);
          }, 18000); // Start checking after 18 seconds
        } else {
          console.log("⚠️ Duel Transaction Failed!");
          // Handle failed transaction
        }

      } catch (error) {
        setAttemptingDuel(false);
        clearTimeout(duelCheckTimeoutRef.current)
        console.error(`Error challenging duel for token ID ${tokenIdOffense} vs ${tokenIdDefense}:`, error);
        setTransactionErrorCheck(true);
      }
    }
  };

  const [tokenUri, setTokenUri] = useState('');

  const getTokenUri = async (tokenId) => {
    console.log("🔍 Fetching token URI for token ID:", tokenId);
    if (readContracts && readContracts.MerlinsGambitQ1) {
      try {
        const uri = await readContracts.MerlinsGambitQ1.uri(tokenId);
        console.log("🔗 Token URI: ", uri);
        setTokenUri(uri);
        // You can include additional logic here if needed
      } catch (error) {
        console.error(`Error fetching URI for token ID ${tokenId}:`, error);
        // Handle errors, e.g., update state to show error message
      }
    } else {
      // Handle the case where the contract is not available
      console.error("Contract not available");
    }
  };

  const [tokenData, setTokenData] = useState('');

  const getTokenData = async (tokenId) => {
    console.log("👓 getTokenData 🧬");
    if (readContracts && readContracts.MerlinsGambitQ1) {
      try {
        const data = await readContracts.MerlinsGambitQ1.tokenData(tokenId);
        console.log("🧬 getTokenData: ", data);
        setTokenData(data);
        setLoadingOnchainNFT(false);
        setShowCanvas(true); // Set showCanvas state to true
        setColorString(data);
        drawColorGrid(data);
      } catch (error) {
        console.error(`Error fetching token data for token ID ${tokenId}:`, error);
        setShowCanvas(false); // Set showCanvas state to true
        setLoadingOnchainNFT(false);
        setTransactionErrorCheck(true);
      }
    }
    else {
      setShowCanvas(false); // Set showCanvas state to true
      setLoadingOnchainNFT(false);
    }
  };


  const getSwordDigitKeyData = async (contractDataByID, swordType = null) => {
    console.log("👓 getSwordDigitKeyData 🎹");
    try {
      const data = contractDataByID.swordDigitKey;

      const total = data.reduce((accumulator, currentValue) => {
        const valueToConsider = currentValue.isZero() ? 10 : currentValue.toNumber();
        return accumulator + valueToConsider;
      }, 0);

      setSwordDigitKeyDataTotal(total);

      const stringData = data.map(item => item.toString());
      console.log("🎹 getSwordDigitKeyData: ", stringData);

      setSwordDigitKeyData(stringData);

      // Convert the data array to a string, with no delimiter, and create the file path
      const filename = `https://merlinsgambitblob.blob.core.windows.net/sword/${data.join('')}.png`;

      if (swordType) {
        if (swordType === "OffensiveSword") {
          setSwordImageFileOffensiveSword(filename);
        } else if (swordType === "DefensiveSword") {
          setSwordImageFileDefensiveSword(filename);
        } else {
          // Handle error: Invalid swordType
          console.error("Invalid swordType:", swordType);
        }
      } else {
        setSwordImageFile(filename);
        console.log("Sword image file path: ", filename);
        setSwordImageFileBackUp(filename);
      }

    } catch (error) {
      console.error(`Error fetching sword digit key data for token`);
    }
  };

  //TODO: updated to use contractDataByID
  const getDuelsWonById = async (theContractDataByID) => {
    console.log("👓 getDuelsWonById ⚔");
    try {
      const duelsWon = theContractDataByID.duelsWon;
      setDuelsWonById(duelsWon.toString());
    } catch (error) {
      console.error(`Error fetching duels won data for token`, error);
    }
  };

  const [duelCount, setDuelCount] = useState(0);
  const [duelCountOffensiveSword, setDuelCountOffensiveSword] = useState(0);
  const [duelCountDefensiveSword, setDuelCountDefensiveSword] = useState(0);

  const getDuelCountByTokenId = async (contractDataByID, swordType = null) => {
    console.log("👓 getDuelCountByTokenId 🤺");
    try {
      const count = contractDataByID.duelCount;
      const countString = count.toString();  // convert the BigNumber to a string
      console.log("🤺 Duel Count Print Statement: ", countString);

      if (swordType) {
        if (swordType === "OffensiveSword") {
          setDuelCountOffensiveSword(countString);
        } else if (swordType === "DefensiveSword") {
          setDuelCountDefensiveSword(countString);
        } else {
          // Handle error: Invalid swordType
          console.error("Invalid swordType:", swordType);
        }
      } else {
        setDuelCount(countString);
      }

    } catch (error) {
      console.error(`Error fetching duel count`, error);
    }
  };

  const [matchUpRating, setMatchUpRating] = useState(null);


  const getDuelMatchUpRating = async (tokenIdOffense, tokenIdDefense) => {
    console.log("👓 getDuelMatchUpRating");
    if (readContracts && readContracts.MerlinsGambitQ1) {
      try {
        const rating = await readContracts.MerlinsGambitQ1.getDuelMatchUpRating(tokenIdOffense, tokenIdDefense);
        setMatchUpRating(rating.toString()); // convert BigNumber to string
        console.log(`Match up rating for token ID ${tokenIdOffense} vs ${tokenIdDefense}:`, rating.toString());
      } catch (error) {
        console.error(`Error fetching match up rating for token ID ${tokenIdOffense} vs ${tokenIdDefense}:`, error);
      }
    }
  };

  const [tokenOwner, setTokenOwner] = useState(null);
  const [tokenOwnerOffensiveSword, setTokenOwnerOffensiveSword] = useState(null);
  const [tokenOwnerDefensiveSword, setTokenOwnerDefensiveSword] = useState(null);

  const getTokenOwner = async (contractDataByID, swordType = null) => {
    console.log("👓 getTokenOwner");
    try {
      const owner = contractDataByID.tokenOwner;

      if (swordType) {
        if (swordType === "OffensiveSword") {
          setTokenOwnerOffensiveSword(owner);
        } else if (swordType === "DefensiveSword") {
          setTokenOwnerDefensiveSword(owner);
        } else {
          // Handle error: Invalid swordType
          console.error("Invalid swordType:", swordType);
        }
      } else {
        setTokenOwner(owner);
        setAddressNFTOwner(owner);
        console.log("Token Owner Print Statement: ", owner);
      }

    } catch (error) {
      console.error(`Error fetching owner of token`, error);
    }
  };

  const [currentTokenId, setCurrentTokenId] = useState(2);


  //TODO: should currentContractData be passed in
  const getCurrentTokenId = async () => {
    console.log("👓 getCurrentTokenId 📰🆔");
    try {
      const tokenId = currentContractData.currentTokenId;
      console.log("tokenId: ", tokenId);
      setCurrentTokenId(tokenId.toString());
      console.log("📰🆔 Current Token ID Print Statement: ", tokenId.toString());
    } catch (error) {
      console.error('Error fetching current token ID:', error);
    }
  };

  const [excaliburFreed, setExcaliburFreed] = useState(false);
  const [gameWinner, setGameWinner] = useState(false);
  const [finalGrandPayoutAmount, setFinalGrandPayoutAmount] = useState(0);
  const [totalEthWonFromEverything, setTotalEthWonFromEverything] = useState(0);


  const getExcaliburFreedStatus = async () => {
    console.log("👓 getExcaliburFreedStatus");
    try {
      const isFreed = currentContractData.isExcaliburFreed;
      setExcaliburFreed(isFreed);
      console.log("🤴 Excalibur Freed Status: ", isFreed);

      if (isFreed) {
        const payoutAmount = currentContractData.finalGrandPayoutAmount;
        const payoutAmountInEther = utils.formatEther(payoutAmount);  // Convert from wei to ether
        setFinalGrandPayoutAmount(payoutAmountInEther);
        console.log("💎 Final Grand Payout Amount: ", payoutAmountInEther, " ETH");
      }

      return isFreed;
    } catch (error) {
      console.error('Error fetching Excalibur freed status:', error);
    }
    return false; // Return false by default if there was an error
  };

  const [swordHilt, setSwordHilt] = useState();
  const [swordGrip, setSwordGrip] = useState();
  const [swordBlade, setSwordBlade] = useState();
  const [swordJewel, setSwordJewel] = useState();
  const [swordHiltOffensiveSword, setSwordHiltOffensiveSword] = useState();
  const [swordGripOffensiveSword, setSwordGripOffensiveSword] = useState();
  const [swordBladeOffensiveSword, setSwordBladeOffensiveSword] = useState();
  const [swordJewelOffensiveSword, setSwordJewelOffensiveSword] = useState();
  const [swordHiltDefensiveSword, setSwordHiltDefensiveSword] = useState();
  const [swordGripDefensiveSword, setSwordGripDefensiveSword] = useState();
  const [swordBladeDefensiveSword, setSwordBladeDefensiveSword] = useState();
  const [swordJewelDefensiveSword, setSwordJewelDefensiveSword] = useState();

  const getSwordProperties = async (contractDataByID, swordType = null) => {
    console.log("👓 getSwordProperties 📓");
    try {
      const properties = contractDataByID.swordPropertiesByTokenId;
      console.log("📓 SwordProperties: ", properties);

      if (swordType) {
        if (swordType === "OffensiveSword") {
          setSwordHiltOffensiveSword(properties[0]);
          setSwordGripOffensiveSword(properties[1]);
          setSwordBladeOffensiveSword(properties[2]);
          setSwordJewelOffensiveSword(properties[3]);
        } else if (swordType === "DefensiveSword") {
          setSwordHiltDefensiveSword(properties[0]);
          setSwordGripDefensiveSword(properties[1]);
          setSwordBladeDefensiveSword(properties[2]);
          setSwordJewelDefensiveSword(properties[3]);
        } else {
          // Handle error: Invalid swordType
          console.error("Invalid swordType:", swordType);
        }
      } else {
        setSwordHilt(properties[0]);
        setSwordGrip(properties[1]);
        setSwordBlade(properties[2]);
        setSwordJewel(properties[3]);
      }

    } catch (error) {
      console.error(`Error fetching sword properties`, error);
    }
  };

  const [displayOwnedNfts, setDisplayOwnedNfts] = useState(false);
  const [displayNftCounter, setDisplayNftCounter] = useState(0);
  const [displayNftCounterAll, setDisplayNftCounterAll] = useState(2);

  const processTheTokenId = (displayOwned) => {
    if (displayOwned) {
      processTokenId(ownedTokenIds[displayNftCounter]);
    } else {
      processTokenId(displayNftCounterAll);
    }
  };

  const handleSelectChangeNFTDropdown = (selectedValue) => {
    selectedValue = Number(selectedValue); // Ensure it's a number
    if (displayOwnedNfts) {
      if (selectedValue < ownedTokenIds.length) {
        setDisplayNftCounter(selectedValue);
        processTokenId(ownedTokenIds[selectedValue]);
      }
    } else {
      if (selectedValue <= currentTokenId) {
        setDisplayNftCounterAll(selectedValue);
        processTokenId(selectedValue); // Assuming this is the correct way to process for the 'all' case
      }
    }
  };


  const incrementDisplayNftCounter = () => {
    console.log("incrementDisplayNftCounter");
    if (displayOwnedNfts) {
      if (displayNftCounter < ownedTokenIds.length - 1) {
        setDisplayNftCounter(prevCounter => {
          const updatedCounter = prevCounter + 1;
          processTokenId(ownedTokenIds[updatedCounter]);
          return updatedCounter;
        });
      }
    } else {
      if (displayNftCounterAll < currentTokenId) {
        setDisplayNftCounterAll(prevCounterAll => {
          const updatedCounterAll = prevCounterAll + 1;
          processTokenId(updatedCounterAll);
          return updatedCounterAll;
        });
      }
    }
  };

  const decrementDisplayNftCounter = () => {
    console.log("decrementDisplayNftCounter");
    if (displayOwnedNfts) {
      if (displayNftCounter > 0) {
        setDisplayNftCounter(prevCounter => {
          const updatedCounter = prevCounter - 1;
          processTokenId(ownedTokenIds[updatedCounter]);
          return updatedCounter;
        });
      }
    } else {
      if (displayNftCounterAll > 2) {
        setDisplayNftCounterAll(prevCounterAll => {
          const updatedCounterAll = prevCounterAll - 1;
          processTokenId(updatedCounterAll);
          return updatedCounterAll;
        });
      }
    }
  };

  {/*
    TODO: update 
            getTokenOwner, //done
            getDuelCountByTokenId, //done
            getDuelsWonById, //done

  */}
  const processTokenId = async (tokenId, swordType = null, fromDuelSlider = false) => {
    console.log("💻 processTokenId: ", tokenId);
    console.log("🆔 tokenId: ", tokenId);

    setTransactionErrorCheck(false);
    setShowCanvas(false);
    setLoadingNFT(false);
    let name;
    if (tokenId != 2) {
      const contractDataByID = await readContracts.MerlinsGambitQ1.getContractDataByID(tokenId);
      name = 'Replica ' + tokenId;
      getSwordDigitKeyData(contractDataByID, swordType);
      getSwordProperties(contractDataByID, swordType);
      getSharpnessData(contractDataByID, swordType);
      getSwordRatingData(contractDataByID, swordType);
      getTokenOwner(contractDataByID, swordType);
      getDuelCountByTokenId(contractDataByID, swordType);

      if (swordType) {
        if (swordType === "OffensiveSword") {
          setTokenIdOffensiveSword(tokenId);
          setNFTNameOffensiveSword(name);
          getDuelsWonById(contractDataByID);
          if (!fromDuelSlider) {
            await getDuelMatchUpRating(tokenId, tokenIdDefensiveSword);
          }
        } else if (swordType === "DefensiveSword") {
          setTokenIdDefensiveSword(tokenId);
          setNFTNameDefensiveSword(name);
          if (!fromDuelSlider) {
            await getDuelMatchUpRating(tokenIdOffensiveSword, tokenId);
          }
        } else {
          // Handle error: Invalid swordType
          console.error("Invalid swordType:", swordType);
        }
      } else {
        setTokenIdNFT(tokenId);
        setNFTName(name);
      }

    } else {
      if (swordType) {
        if (swordType === "OffensiveSword") {
          name = 'Excalibur';
          setNFTNameOffensiveSword(name);
          setSwordHiltOffensiveSword('Gold');
          setSwordGripOffensiveSword('Leather');
          setSwordBladeOffensiveSword('Merlin Steel');
          setSwordJewelOffensiveSword('Green Emerald');
          setSharpnessDataOffensiveSword('Honed');
          setSwordRatingDataOffensiveSword('100');
          setSwordImageFileOffensiveSword(`https://merlinsgambitblob.blob.core.windows.net/sword/0000.png`);

          if (excaliburFreed) {
            // If excaliburFreed is true, call the function with tokenId=2 and swordType='OffensiveSword'
            const contractDataByID = await readContracts.MerlinsGambitQ1.getContractDataByID(2);
            getDuelCountByTokenId(contractDataByID, 'OffensiveSword');
          } else {
            // If excaliburFreed is false, just set it to '100'
            setDuelCountOffensiveSword('100');
          }

          setTokenIdOffensiveSword(tokenId);
          setSwordDigitKeyData(['0', '0', '0', '0']);
          setSwordDigitKeyDataTotal(40);
          const contractDataByID = await readContracts.MerlinsGambitQ1.getContractDataByID(2);
          getTokenOwner(contractDataByID, swordType);
          if (!fromDuelSlider) {
            await getDuelMatchUpRating(tokenId, tokenIdDefensiveSword);
          }
        } else if (swordType === "DefensiveSword") {
          name = 'Excalibur';
          setNFTNameDefensiveSword(name);
          setSwordHiltDefensiveSword('Gold');
          setSwordGripDefensiveSword('Leather');
          setSwordBladeDefensiveSword('Merlin Steel');
          setSwordJewelDefensiveSword('Green Emerald');
          setSharpnessDataDefensiveSword('Honed');
          setSwordRatingDataDefensiveSword('100');
          setSwordImageFileDefensiveSword(`https://merlinsgambitblob.blob.core.windows.net/sword/0000.png`);

          if (excaliburFreed) {
            // If excaliburFreed is true, call the function with tokenId=2 and swordType='DefensiveSword'
            const contractDataByID = await readContracts.MerlinsGambitQ1.getContractDataByID(2);
            getDuelCountByTokenId(contractDataByID, 'DefensiveSword');
          } else {
            // If excaliburFreed is false, just set it to '100'
            setDuelCountDefensiveSword('100');
          }

          setDuelCountDefensiveSword('100');
          setTokenIdDefensiveSword(tokenId);
          setSwordDigitKeyData(['0', '0', '0', '0']);
          setSwordDigitKeyDataTotal(40);
          const contractDataByID = await readContracts.MerlinsGambitQ1.getContractDataByID(2);
          getTokenOwner(contractDataByID, swordType);
          if (!fromDuelSlider) {
            await getDuelMatchUpRating(tokenIdOffensiveSword, tokenId);
          }
        } else {
          // Handle error: Invalid swordType
          console.error("Invalid swordType:", swordType);
        }
      } else {
        //name = 'Excalibur';
        console.log("👑 processing Excalibur on initial Load");
        setNFTName('Excalibur');
        console.log("nftName: ", nftName);
        setSwordHilt('Gold');
        setSwordGrip('Leather');
        setSwordBlade('Merlin Steel');
        setSwordJewel('Green Emerald');
        setSharpnessData('Honed');
        setSwordRatingData('100');
        setSwordImageFile(`https://merlinsgambitblob.blob.core.windows.net/sword/0000.png`);
        setSwordImageFileBackUp(`https://merlinsgambitblob.blob.core.windows.net/sword/0000.png`);

        if (excaliburFreed) {
          // If excaliburFreed is true, call the function with tokenId=2 and swordType='DefensiveSword'
          const contractDataByID = await readContracts.MerlinsGambitQ1.getContractDataByID(2);
          getDuelCountByTokenId(contractDataByID);
        } else {
          // If excaliburFreed is false, just set it to '100'
          setDuelCount('100');
        }

        setSwordDigitKeyData(['0', '0', '0', '0']);
        setSwordDigitKeyDataTotal(40);

        setTokenIdNFT(2);

        const isExcaliburFreed = await getExcaliburFreedStatus();
        if (!isExcaliburFreed) {
          setAddressNFTOwner(null);
          console.log("Excalibur not freed, setAddressNFTOwner(null)");
        } else {
          const contractDataByID = await readContracts.MerlinsGambitQ1.getContractDataByID(2);
          getTokenOwner(contractDataByID, swordType);
        }
      }
    }
  };



  const fetchContractDataByAddress = async (address) => {
    console.log(`👓 inside fetchContractDataByAddress 💻`);
    try {
      const contractDataByAddress = await readContracts.MerlinsGambitQ1.getContractDataByAddress(address);
      const tokenIds = contractDataByAddress.ownedTokenIds;
      console.log(`Setting ownedTokenIds 📚🆔`);
      console.log(`📚🆔 tokenIds:`, tokenIds);
      setOwnedTokenIds(tokenIds);
      setCurrentSpnyBalance(contractDataByAddress.spnyBalance);
      setPenniesMintedSinceUpdate(0);
      return tokenIds; // returning the data
    } catch (error) {
      console.error("Error fetching contract data:", error);
    }
  };



  const fetchCurrentContractData = async () => {
    console.log("👓🟢 fetchCurrentContractData 📄");
    if (readContracts && readContracts.MerlinsGambitQ1) {
      try {
        const data = await readContracts.MerlinsGambitQ1.getContractData();
        setCurrentContractData(data);
        setIsLoading(false);
        // Set the state variables based on the fetched data
        setCurrentTokenId(data.currentTokenId.toString());
        setMintCounterNFT(data.currentTokenId - 2);
        console.log("🟢 Current Token ID Print Statement: ", data.currentTokenId.toString());

        setExcaliburFreed(data.isExcaliburFreed);
        console.log("🟢 Excalibur Freed Status: ", data.isExcaliburFreed);


        setTotalSilverPenniesSupply(data.totalSilverPenniesSupply);
        setTotalSilverPenniesSupplyChangeSinceUpdate(0);

        console.log("🟢 Silver Penny Supply: ", totalSilverPenniesSupply);

        setSwordPullCounter(data.swordPullCounter.toNumber());

        setContractBalance(data.contractBalance);
        setContractBalanceSinceUpdate(0);


        const theGameWinnings = data.gameWinnings;
        const gameWinningsInEther = parseFloat(utils.formatEther(theGameWinnings));  // Convert from wei to ether and then to a number
        setGameWinnings(gameWinningsInEther);
        setGameWinningsSinceUpdate(0);

        if (data.isExcaliburFreed) {
          const payoutAmount = data.finalGrandPayoutAmount;
          const payoutAmountInEther = utils.formatEther(payoutAmount);  // Convert from wei to ether
          setFinalGrandPayoutAmount(payoutAmountInEther);
          console.log("💎 Final Grand Payout Amount: ", payoutAmountInEther, " ETH");
        }

      } catch (error) {
        console.error('Error fetching current contract data:', error);
      }
    }
  };

  // Function to fetch tokenIds owned by the user
  const fetchOwnedTokenIds = async (address) => {
    console.log("👓 fetchOwnedTokenIds 📚🆔");
    //await getExcaliburFreedStatus();
    if (readContracts && readContracts.MerlinsGambitQ1) {
      try {
        let tokenIds;
        if (!address) {
          tokenIds = [];
          setOwnedTokenIds(tokenIds);
        } else {
          tokenIds = await fetchContractDataByAddress(address);
          //setOwnedTokenIds(tokenIds);
        }
        // If the returned array contains at least one tokenId
        if (tokenIds.length > 0) {
          // Get data for the last tokenId
          const lastTokenId = tokenIds[tokenIds.length - 1];
          console.log("👨‍🎤 user's newest tokenId: ", tokenIds[tokenIds.length - 1]);
          const contractDataByID = await readContracts.MerlinsGambitQ1.getContractDataByID(lastTokenId)
          setDisplayOwnedNfts(true);
          getSwordDigitKeyData(contractDataByID);
          getSwordProperties(contractDataByID);
          getSharpnessData(contractDataByID);
          getSwordRatingData(contractDataByID);
          setAddressNFTOwner(address);
          setTokenIdNFT(lastTokenId);
          let nftName;
          if (lastTokenId != 2) {
            nftName = 'Replica ' + lastTokenId;
          } else {
            nftName = 'Excalibur';
          }
          setNFTName(nftName);
          setDisplayNftCounter(tokenIds.length - 1);
          getDuelCountByTokenId(contractDataByID);
        } else {
          processTokenId(2);
        }
      } catch (error) {
        console.error(`Error fetching tokenIds owned by ${address}:`, error);
      }
    }
  };

  const [showLoadingOnchainNFTMessage, setShowLoadingOnchainNFTMessage] = useState(false);


  function applyLocalSwordData(data) {
    setColorString(data);
    drawColorGrid(data);
  }

  const canvasRef = useRef(null);
  const [colorString, setColorString] = useState('');
  const width = 15;
  const height = 50;

  const drawColorGrid = (colorData) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const pixelSize = 4;

    // Split the color data into groups of 8 characters (RGBA)
    const colors = colorData.match(/.{1,8}/g).map((hex) => {
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      const a = parseInt(hex.substring(6, 8), 16) / 255;
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    });

    let x = 0;
    let y = 0;
    colors.forEach((color) => {
      ctx.fillStyle = color;
      ctx.fillRect(x * pixelSize, y * pixelSize, pixelSize, pixelSize);

      x += 1;
      if (x >= width) {
        x = 0;
        y += 1;
      }
    });
  };

  const swordExamplesRef = useRef(null);
  const finePrintRef = useRef(null);
  const aboutRef = useRef(null);
  const bookRef = useRef(null);

  const swordContainerStyle = {
    position: 'fixed',
    right: '8px',
    bottom: '115px', // Adjust this value to position the sword above the silver penny image and balance
    padding: '10px',
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.05)', // Add a background color
    borderRadius: '10px', // Round the corners
    border: '1px solid black', // Add a border
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
    width: '210px', // Set a fixed width
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

  };

  const previewDuelsContainerStyle = {
    position: 'fixed',
    right: '8px',
    bottom: '115px',
    padding: '10px',
    zIndex: 999,
    backgroundColor: 'rgba(0, 0, 0, 0.60)',
    borderRadius: '10px', // Round the corners
    border: '1px solid black', // Add a border
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
    width: '210px', // Set a fixed width
    height: '500px',
    display: 'flex', // Ensure this is a flex container
    flexDirection: 'column', // Arrange children in a column
  };

  const swordExamplesContainerStyle = {
    position: 'fixed',
    top: '50%', // To center vertically
    left: '50%', // To center horizontally
    transform: 'translate(-50%, -50%)', // To perfectly center the container
    padding: '10px',
    zIndex: 2000,
    borderRadius: '10px', // Round the corners
    border: '1px solid black', // Add a border
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
    height: '480px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.90)',
  };

  const finePrintContainerStyle = {
    position: 'fixed',
    top: '50%', // To center vertically
    left: '50%', // To center horizontally
    transform: 'translate(-50%, -50%)', // To perfectly center the container
    padding: '10px',
    zIndex: 2000,
    borderRadius: '10px', // Round the corners
    border: '1px solid black', // Add a border
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
    width: '400px',
    height: '180px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.90)',
  };

  const bookContainerStyle = {
    position: 'fixed',
    top: '50%', // To center vertically
    left: '50%', // To center horizontally
    transform: 'translate(-50%, -50%)', // To perfectly center the container
    padding: '10px',
    zIndex: 2000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const aboutContainerStyle = {
    position: 'fixed',
    top: '50%', // To center vertically
    left: '50%', // To center horizontally
    transform: 'translate(-50%, -50%)', // To perfectly center the container
    padding: '10px',
    zIndex: 2000,
    borderRadius: '10px', // Round the corners
    border: '1px solid black', // Add a border
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
    width: '600px',
    height: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.90)',
  };


  const initialPopUpContainerStyle = {
    position: 'fixed',
    top: '50%', // To center vertically
    left: '50%', // To center horizontally
    transform: 'translate(-50%, -50%)', // To perfectly center the container
    //paddingTop: '10px', // Top padding
    // paddingRight: '10px', // Right padding
    paddingBottom: '10px', // Bottom padding
    // paddingLeft: '10px', // Left padding
    zIndex: 2000,
    //borderRadius: '10px', // Round the corners
    border: '1px solid black', // Add a border
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
    width: windowWidth <= BREAKPOINT ? '450px' : '420px',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0, 0, 0, 0.90)',
  };

  const noPurchaseContainerStyle = {
    position: 'fixed',
    top: '50%', // To center vertically
    left: '50%', // To center horizontally
    transform: 'translate(-50%, -50%)', // To perfectly center the container
    padding: '10px',
    zIndex: 2000,
    borderRadius: '10px', // Round the corners
    border: '1px solid black', // Add a border
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
    height: '640px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.90)',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: 'bold'
  };

  const initialPopupTitleStyle = {
    position: 'absolute', // This will position the title absolutely within the modal
    top: '0px', // Adjust the top value to match your design
    left: '10px', // Adjust the left value to match your design
    margin: '0', // This removes any default margin that might offset the title
    color: '#FFF', // Example color, change as needed
    // You may want to add more styles for font size, font family, etc.
  };

  // Update the initialPopup close button style to ensure it doesn't overlap with the title
  const initialPopupCloseButtonStyle = {
    ...closeButtonStyle,
    position: 'absolute',
    top: '3px',
    right: '10px',
    // Add any additional styles you need for the close button
  };

  const exampleSwordsTitleStyle = {
    position: 'absolute',
    top: '10px',         // Aligns with the vertical position of the 'X' button
    left: '25px',       // On the left side with 10px offset
    fontSize: '18px',   // Matching the font size of the 'X' button
    fontWeight: 'bold',  // Matching the font weight of the 'X' button    
    color: '#b6b5cc'
  };

  const notForUseStyle = {
    position: 'absolute',
    top: '10px',         // Aligns with the vertical position of the 'X' button
    left: '25px',       // On the left side with 10px offset
    fontSize: '18px',   // Matching the font size of the 'X' button
    fontWeight: 'bold',  // Matching the font weight of the 'X' button    
    color: '#eb4034'
  };

  const aboutSectionTitleStyle = {
    position: 'absolute',
    top: '10px',
    left: '25px',
    fontSize: '18px',
    fontWeight: 'bold',
  };

  const selectedSectionStyle = {
    color: '#d19b48',
    cursor: 'pointer',  // Indicating that this item is clickable
  };

  const defaultSectionStyle = {
    color: '#b6b5cc',     // You can choose any default color here
    cursor: 'pointer',  // Indicating that this item is clickable
  };

  const hoverStyle = {
    color: '#d19b48',
    cursor: 'pointer',
  };

  const aboutTitleStyle = (sectionName) => {
    // If this section is being hovered over, return hover style
    if (hoveredSection === sectionName) {
      return hoverStyle;
    }
    // If another section is being hovered over, return default style for all sections
    else if (hoveredSection) {
      return defaultSectionStyle;
    }
    // If no sections are being hovered over, check which section is selected
    else {
      switch (sectionName) {
        case "GettingStarted":
          return showGettingStarted ? selectedSectionStyle : defaultSectionStyle;
        case "ExcaliburBounty":
          return showExcaliburBountyDetails ? selectedSectionStyle : defaultSectionStyle;
        case "Rules":
          return showRulesSection ? selectedSectionStyle : defaultSectionStyle;
        case "Credits":
          return showCreditsSection ? selectedSectionStyle : defaultSectionStyle;
        default:
          return defaultSectionStyle;
      }
    }
  };

  const modalTextStyle = {
    color: 'white',            // Assuming you want a white font color against a dark modal background
    fontSize: '16px',          // You can adjust this as per your design needs
    fontWeight: '500',         // Makes the text slightly bolder
    textAlign: 'center',       // Centers the text within the modal
    marginBottom: '20px',      // Gives some space below the text for the input and button elements
    maxWidth: '80%',           // Limits the width, making it more readable, especially on wide screens
    lineHeight: '1.5',         // Makes the text more readable by adjusting the space between lines
    letterSpacing: '0.5px',    // Slightly increases the spacing between characters for readability
  };

  const magicWordModalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2000,
    backgroundColor: 'rgba(0, 0, 0, 0.90)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'none',
  };

  const [magicWord, setMagicWord] = useState('');
  const correctMagicWord = 'camelot';  // You can change 'magicword' to whatever word you'd like

  const handleSubmit = (e) => {
    e.preventDefault();
    if (magicWord === correctMagicWord) {
      // Hide the modal by setting its display to 'none'
      document.getElementById('magicWordModal').style.display = 'none';
    } else {
      //alert('Incorrect magic word');
      setMagicWord('');
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^A-Za-z]/g, '').toLowerCase();
    setMagicWord(value);
  };

  const magicWordInputBoxStyle = {
    padding: '2px',
    paddingLeft: '10px',
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    width: '120px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    marginBottom: '0px',
    outline: 'none',
    transition: 'border-color 0.3s ease',  // for a smooth transition effect
    backgroundColor: 'black',  // Adding explicit white background
    color: 'rgba(0, 255, 0)',  // Setting the text color to black
    '&:focus': {
      borderColor: '#007BFF'  // changes the border color when the input is focused
    }
  };

  const [isEnterButtonHovered, setIsEnterButtonHovered] = useState(false);

  const inputButtonContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',  // vertically center align the input and button
    justifyContent: 'center',  // This will horizontally align the children in the center
    gap: '10px'  // spacing between input and button
  };

  const buttonContainerStyle = {
    padding: '2px',
    zIndex: 999,
    backgroundColor: 'rgba(0, 0, 0, .60)', // Add a background color
    borderRadius: '10px', // Round the corners
    border: '1px solid black', // Add a border
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
    width: '200px', // Set a fixed width
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const buttonStyle = {
    padding: '2px',
    borderRadius: '10px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    width: '98px'
  };

  const tokenDataStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    color: 'rgba(0, 255, 0, 0.15)',
    wordWrap: 'break-word', // Break long words if they can't fit
    overflowWrap: 'break-word',
    zIndex: 0,
    overflow: 'hidden',
    pointerEvents: 'none' // To prevent mouse events on this div
  };

  const greenTextStyle = {
    color: 'rgb(0, 255, 0)', // Computer console green
  };

  const dataToDisplayBySword = {
    fontSize: '11px', // Example font size, change to your needs
  };

  const mediumPinkTextStyle = {
    fontSize: '17px',
    textAlign: 'left',
    color: 'rgba(199, 108, 204)',
    fontWeight: 'bold',
    marginBottom: '0px',
  };

  const duelContinueButtonStyle = {
    zIndex: 3,
    padding: '4px',
    backgroundColor: isContinueButtonHovered ? buttonHoverBackgroundColor : buttonBackgroundColor,
    borderRadius: '10px',
    border: '1px solid black',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '120px',
    height: '51px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: buttonTextColor,
    marginTop: '5px'
  };

  const enterButtonStyle = {
    zIndex: 3,
    padding: '4px',
    backgroundColor: buttonBackgroundColor,
    borderRadius: '10px',
    border: '1px solid black',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '120px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: buttonTextColor,
    marginTop: '5px'
  };

  const enterButtonHoverStyle = {
    backgroundColor: buttonHoverBackgroundColor,
    color: buttonHoverTextColor
  };

  const [showCanvas, setShowCanvas] = useState(false);
  const rowStyle = { height: '24px', display: 'flex', alignItems: 'center' }
  const CAROUSEL_LENGTH = 4; // Define carousel length as a constant
  const [carouselIndex, setCarouselIndex] = useState(0);

  const nextPage = () => {
    setCarouselIndex((prevIndex) => {
      let nextIndex = prevIndex + 1;
      if (nextIndex >= CAROUSEL_LENGTH) nextIndex = 0; // wraps around to the first page
      return nextIndex;
    });
  };

  const previousPage = () => {
    setCarouselIndex((prevIndex) => {
      let nextIndex = prevIndex - 1;
      if (nextIndex < 0) nextIndex = CAROUSEL_LENGTH - 1; // wraps around to the last page
      return nextIndex;
    });
  };

  const setPage = (index) => {
    // Ensure the index is within valid range
    if (index >= 0 && index < CAROUSEL_LENGTH) {
      // Check if the page is actually changing
      if (carouselIndex !== index) {
        setCarouselIndex(index);
        console.log(`Carousel index should now be ${index}`);

        changeInstructionsDivBorder();
      }
    }
  };

  const setPageByClick = (index) => {
    // Ensure the index is within valid range
    if (index >= 0 && index < CAROUSEL_LENGTH) {
      // Check if the page is actually changing
      if (carouselIndex !== index) {
        setCarouselIndex(index);
        console.log(`Carousel index should now be ${index}`);
      }
    }
  };

  const titleStyle = {
    ...greenTextStyle, // include the properties from greenTextStyle
    textAlign: 'center',
    display: 'inline', // Ensures that it does not cause a line break
    fontWeight: 'bold', // H3 usually has bold text
    fontSize: '14px',
  };

  const dropdownStyle = {
    ...titleStyle, // include the properties from titleStyle
    width: '200px',
    height: '40px',
    backgroundColor: 'transparent',  // change this to transparent
    borderColor: 'transparent',
    borderRadius: '5px',
    padding: '4px',
    cursor: 'pointer',
  };

  const customDropdownStyle = {
    ...dropdownStyle,
    //paddingLeft: '16px',
    outline: 'none',
    color: nftDropdownHovered ? 'rgb(0, 217, 255)' : 'rgb(0, 255, 0)', // Change text color on hover
    transition: 'all 0.3s ease',
    '-webkit-appearance': 'none',  // Hide default arrow for WebKit browsers
    'appearance': 'none',          // Hide default arrow for other browsers
    'backgroundImage': 'none',
  };

  const optionStyle = {
    color: 'rgb(0, 0, 0)',
  };

  const dropdownContainerStyle = {
    position: 'relative',
    width: '200px', // Match the width of your select element
    // You can add other styles as needed
  };

  const arrowStyle = {
    position: 'absolute',
    right: '10px', // Adjust the position as necessary
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none', // Makes the arrow non-interactive
    //stroke: greenTextStyle.color,
  };

  const arrowColor = nftDropdownHovered ? 'rgb(0, 217, 255)' : greenTextStyle.color; // Use state to determine color


  const handleSelectChange = (index, swordType) => {
    if (swordType === "OffensiveSword") {
      processTokenId(ownedTokenIds[index], swordType);
    } else if (swordType === "DefensiveSword") {
      processTokenId(index, swordType);
    } else {
      console.error("Invalid swordType:", swordType);
    }
  };

  let leftValue;
  switch (sharpnessDataOffensiveSword) {
    case 'Dull':
      leftValue = '0px';
      break;
    case 'Sharp':
      leftValue = '-6px';
      break;
    case 'Honed':
      leftValue = '-4px';
      break;
    default:
      leftValue = '-6px';
  }

  const timeoutRef = useRef(null);  //used to prevent knight image flashing back and forth
  const delayTime = 400; // define delay time as a constant
  const timerRefForSilverPennies = useRef(null); // Reference to keep track of the timer

  const handleMouseOverSilverPennies = () => {
    console.log("🖱 handleMouseOverSilverPennies 🐭");
    timerRefForSilverPennies.current = setTimeout(() => {
      setPage(2);
    }, 1000); // Set a timer to call setPage(2) after 1 second (1000 milliseconds)
  };

  const handleMouseOutSilverPennies = () => {
    console.log("🖱 handleMouseOutSilverPennies 🐁");
    // Clear the timer if the mouse leaves the element before 1 second is complete
    clearTimeout(timerRefForSilverPennies.current);
  };

  const handleEasterEggMouseEnter = () => {
    console.log('🐰 Mouse entered 🐁🟠');
    easterEggHoverTime.current = setTimeout(() => {
      console.log('🐰 Mouse has been over for more than 5 seconds');
      hoverInterval.current = setInterval(() => {
        setEasterEggOpacity((prevOpacity) => {
          if (prevOpacity < 1) {
            console.log('Increasing opacity');
            return prevOpacity + 0.1; // Increase opacity every second
          } else {
            console.log('Max opacity reached');
            clearInterval(hoverInterval.current);
            return prevOpacity;
          }
        });
      }, 1000);
    }, 5000);
  };

  const handleEasterEggMouseLeave = () => {
    console.log('🐰 Mouse left 🐁🟡');
    clearTimeout(easterEggHoverTime.current);
    clearInterval(hoverInterval.current);
    easterEggHoverTime.current = null;
    hoverInterval.current = null;
    if (!easterEggRevealed) {
      setEasterEggOpacity(0); // Reset opacity to 0 when mouse leaves before fully revealed
    }
  };

  const easterEggStyle = {
    position: 'fixed',
    right: '2px',
    bottom: '2px',
    width: '30px',
    height: 'auto',
    opacity: easterEggRevealed ? 1 : easterEggOpacity,
    transition: 'opacity 1s linear',
    cursor: easterEggRevealed ? 'pointer' : 'default',
    zIndex: 1000
  };

  const hiltProperties = [
    "Hickory",
    "Iron",
    "Polished Bronze",
    "Stainless Steel",
    "Engraved Silver",
    "Elven Crystal",
    "Meteoric Iron",
    "Dwarvin Titanium",
    "Alien Glass",
    "Gold"
  ];

  const gripProperties = [
    "Walnut",
    "Cloth",
    "Brass Wire",
    "Steel Wire",
    "Silver Wire",
    "Silk",
    "Black Leather",
    "Cork",
    "Rubber",
    "Leather"
  ];

  const bladeProperties = [
    "Oak",
    "Iron",
    "Bronze",
    "Carbon Steel",
    "Silver Alloy",
    "Elven Crystal",
    "Meteoric Iron",
    "Dwarvin Titanium",
    "Alien Glass",
    "Merlin Steel"
  ];

  const jewelProperties = [
    "Purple Amethyst",
    "Blue Sapphire",
    "Dark Red Ruby",
    "Dark Blue Ruby",
    "Red Ruby",
    "Dark Green Emerald",
    "Diamond",
    "Pink Sapphire",
    "Black Obsidian",
    "Green Emerald"
  ];

  const baseSwordNames = [
    "Hardwood",
    "Iron",
    "Bronze",
    "Carbon Steel",
    "Engraved Silver",
    "Elven Crystal",
    "Meteoric Iron",
    "Dwarvin Titanium",
    "Alien Glass",
    "Excalibur"
  ];

  const swordUrls = [
    "https://merlinsgambitblob.blob.core.windows.net/sword/1111.png",
    "https://merlinsgambitblob.blob.core.windows.net/sword/2222.png",
    "https://merlinsgambitblob.blob.core.windows.net/sword/3333.png",
    "https://merlinsgambitblob.blob.core.windows.net/sword/4444.png",
    "https://merlinsgambitblob.blob.core.windows.net/sword/5555.png",
    "https://merlinsgambitblob.blob.core.windows.net/sword/6666.png",
    "https://merlinsgambitblob.blob.core.windows.net/sword/7777.png",
    "https://merlinsgambitblob.blob.core.windows.net/sword/8888.png",
    "https://merlinsgambitblob.blob.core.windows.net/sword/9999.png",
    "https://merlinsgambitblob.blob.core.windows.net/sword/0000.png"
  ];

  const exampleSwordPropertyTextStyle = {
    fontSize: "10px"
  };

  // Create swords data
  const swords = baseSwordNames.map((name, index) => ({
    name: name,
    image: swordUrls[index],
    hilt: hiltProperties[index],
    grip: gripProperties[index],
    blade: bladeProperties[index],
    jewel: jewelProperties[index],
  }));

  // Transform swords data into dataSource for Table
  const dataSource = swords.map((sword, index) => ({
    key: index,
    name: sword.name,
    image: <img src={sword.image} alt={sword.name} style={{ width: "100%" }} />,
    hilt: sword.hilt,
    grip: sword.grip,
    blade: sword.blade,
    jewel: sword.jewel,
  }));

  // Define columns for Table
  const columns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return record.key === 0 ? text : '';
      }
    },
    {
      title: '',
      dataIndex: 'image',
      key: 'image',
      render: (text, record) => {
        return record.key === 0 ? text : '';
      }
    },
    ...['Hilt', 'Grip', 'Blade', 'Jewel'].map((item, index) => ({
      title: item,
      dataIndex: item.toLowerCase(),
      key: item.toLowerCase(),
      render: (text, record) => {
        return record.key === index + 1 ? text : '';
      }
    })),
  ];

  const [openSeaUrl, setOpenSeaUrl] = useState("");



  useEffect(() => {
    if (readContracts && readContracts.MerlinsGambitQ1 && tokenIdNFT) {
      setOpenSeaUrl(`${OPEN_SEA_BASE_URL}/${readContracts.MerlinsGambitQ1.address}/${tokenIdNFT}`);
    }
  }, [tokenIdNFT]);

  const [showNetworkConnectedMessage, setShowNetworkConnectedMessage] = useState(true);

  useEffect(() => {
    let timer;
    if (selectedChainId === 42161 || selectedChainId === 421613 || selectedChainId === 421614) {
      timer = setTimeout(() => {
        setShowNetworkConnectedMessage(false);
      }, 3000);
    }
    // Clean up the timer when the component is unmounted or the chainId changes
    return () => clearTimeout(timer);
  }, [selectedChainId]);

  if (isLoading) {
    return (
      <div style={magicWordModalStyle}>
      </div>
    );
  } else {
    return (
      <div style={{ overflowX: 'hidden' }}>

        {/* <button onClick={handleSquirrelAdvice}>Get Squirrel Advice</button> */}

        {/* Get uri that points to json, for debugging but not needed on site: */}
        {/* <button onClick={handleGetTokenUriClick}>
          Get Token URI
        </button>

        {tokenUri && (
          <div>
            <h3>Token URI:</h3>
            <p>{tokenUri}</p>
          </div>
        )} */}

        <div>
          {showNetworkConnectedMessage && (
            <>
              {selectedChainId === 42161 ? (
                <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px green' }}>
                  <p>Connected to Arbitrum One.</p>
                </div>
              ) : selectedChainId === 421613 ? (
                <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px green' }}>
                  <p>Connected to Arbitrum Goerli.</p>
                </div>
              ) : selectedChainId === 421614 ? (
                <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px green' }}>
                  <p>Connected to Arbitrum Sepolia.</p>
                </div>
              ) : null}
            </>
          )}
          {!selectedChainId && !showInitialPopup && (
            <div style={{ fontSize: '20px', fontWeight: 'bold', textShadow: '0 0 5px blue' }}>
              <p>Connect a wallet to begin.</p>
            </div>
          )}
          {selectedChainId !== 42161 && selectedChainId !== 421614 && selectedChainId && (
            <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px red' }}>
              <p>{SILVER_PENNY_MINT_PRICE === 0.001 ? 'Please change network to Arbitrum Sepolia.' : 'Please change network to Arbitrum One.'}</p>
            </div>
          )}
        </div>



        <div style={copyrightStyle}>
          © 2023 Merlin's Gambit
        </div>

        <div style={finePrintStyle}>
          <span onClick={() => setShowFinePrint(true)} style={{ cursor: 'pointer' }}>
            Not for use if prohibited.
          </span>
        </div>

        <div style={aboutStyle}>
          <span onClick={() => setShowAbout(true)}>
            ABOUT
          </span>
        </div>

        <a href="https://medium.com/@0xmerlinsgambit/welcome-to-merlins-gambit-837e0ead006b" target="_blank" style={mediumArticleLinkStyle}>
          <div>
            <span>
              MEDIUM ARTICLE
            </span>
          </div>
        </a>


        <div id="magicWordModal" style={magicWordModalStyle}>
          <p style={modalTextStyle}>Merlin's Gambit is currently in beta testing.<br />
            Please enter the code to continue.</p>
          <form onSubmit={handleSubmit}>

            <div style={inputButtonContainerStyle}>
              <input
                type="text"
                value={magicWord}
                //onChange={e => setMagicWord(e.target.value)}
                placeholder=""
                style={magicWordInputBoxStyle}
                autoFocus
                onChange={handleInputChange}
                spellCheck="false"

              />
              <button
                onMouseEnter={() => setIsEnterButtonHovered(true)}
                onMouseLeave={() => setIsEnterButtonHovered(false)}
                style={isEnterButtonHovered ? { ...enterButtonStyle, ...enterButtonHoverStyle } : enterButtonStyle}
                type="submit"
              >
                <span style={{ fontSize: '14px' }}>
                  <b>Enter</b>
                </span>
              </button>
            </div>

          </form>
        </div>

        {showSwordExamples && (
          <div style={swordExamplesContainerStyle} ref={swordExamplesRef}>
            <div
              style={closeButtonStyle}
              onClick={() => setShowSwordExamples(false)}
            >
              X
            </div>

            <div style={exampleSwordsTitleStyle}>Base Swords</div>

            {/* Content of the div */}
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ ...exampleSwordPropertyTextStyle, visibility: "hidden" }}>B</div>
                <div style={{ visibility: "hidden" }}><img src={swordUrls[0]} alt="Image Placeholder" style={{ width: "100%" }} /></div> {/* Invisible placeholder for image */}
                <div style={{ ...greenTextStyle, ...exampleSwordPropertyTextStyle, width: "60px" }}>Hilt</div>
                <div style={{ ...greenTextStyle, ...exampleSwordPropertyTextStyle, width: "60px" }}>Grip</div>
                <div style={{ ...greenTextStyle, ...exampleSwordPropertyTextStyle, width: "60px" }}>Blade</div>
                <div style={{ ...greenTextStyle, ...exampleSwordPropertyTextStyle, width: "60px" }}>Jewel</div>
              </div>

              {dataSource.map((item, index) => (
                <div key={index} style={{ width: "90px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div style={{ ...titleStyle, ...exampleSwordPropertyTextStyle }}>{item.name}</div>
                  <div>{item.image}</div>
                  <div style={exampleSwordPropertyTextStyle}>{item.hilt}</div>
                  <div style={exampleSwordPropertyTextStyle}>{item.grip}</div>
                  <div style={exampleSwordPropertyTextStyle}>{item.blade}</div>
                  <div style={exampleSwordPropertyTextStyle}>{item.jewel}</div>
                </div>
              ))}
            </div>
          </div>
        )}

        {showFinePrint && (
          <div style={finePrintContainerStyle} ref={finePrintRef}>
            <div
              style={closeButtonStyle}
              onClick={() => setShowFinePrint(false)}
            >
              X
            </div>

            <div style={notForUseStyle}>Not for use if prohibited</div>

            {/* Content of the div */}
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "95%" }}>
                Participation in Merlin's Gambit is not intended for individuals where such participation would be prohibited or restricted by law.
              </div>

            </div>
          </div>
        )}

        {showBook && (
          <div style={bookContainerStyle} ref={bookRef}>

            {/* Content of the div */}
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "95%" }}>
                <img
                  src={"/bookQ1.png"}
                  alt="Book Q1"
                />
              </div>

            </div>
          </div>
        )}

        {showAbout && (
          <div style={aboutContainerStyle} ref={aboutRef}>
            <div
              style={closeButtonStyle}
              onClick={() => setShowAbout(false)}
            >
              X
            </div>

            <div style={aboutSectionTitleStyle}>

              <span
                onClick={() => handleSectionClick("Rules")}
                onMouseEnter={() => setHoveredSection("Rules")}
                onMouseLeave={() => setHoveredSection(null)}
                style={aboutTitleStyle("Rules")}
              >
                Rules
              </span>

              <span> &nbsp;&nbsp;&nbsp;&nbsp; </span>

              <span
                onClick={() => handleSectionClick("GettingStarted")}
                onMouseEnter={() => setHoveredSection("GettingStarted")}
                onMouseLeave={() => setHoveredSection(null)}
                style={aboutTitleStyle("GettingStarted")}
              >
                Getting Started
              </span>
              <span> &nbsp;&nbsp;&nbsp;&nbsp; </span>
              <span
                onClick={() => handleSectionClick("ExcaliburBounty")}
                onMouseEnter={() => setHoveredSection("ExcaliburBounty")}
                onMouseLeave={() => setHoveredSection(null)}
                style={aboutTitleStyle("ExcaliburBounty")}
              >
                Excalibur Bounty
              </span>

              <span> &nbsp;&nbsp;&nbsp;&nbsp; </span>
              <span
                onClick={() => handleSectionClick("Credits")}
                onMouseEnter={() => setHoveredSection("Credits")}
                onMouseLeave={() => setHoveredSection(null)}
                style={aboutTitleStyle("Credits")}
              >
                Stack
              </span>


            </div>

            {/* Content of the div */}
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "95%" }}>

                {showGettingStarted && <div>

                  <h4 style={aboutSectionSubtitleStyle}>Network Selection:</h4>
                  <p>This Ethereum game utilizes the Arbitrum One network for enhanced performance.</p>

                  <h4 style={aboutSectionSubtitleStyle}>Connect Your Wallet:</h4>
                  <p>To participate, ensure your digital wallet is connected to the Arbitrum One network. If you're unfamiliar with the process, here's a basic guide:</p>

                  <ol style={{ textAlign: 'left' }}>
                    <li>Open your wallet application (e.g., MetaMask).</li>
                    <li>Navigate to the network selection dropdown.</li>
                    <li>Choose "Arbitrum One" from the list. If it's not visible, you might need to add it manually by following your wallet's instructions.</li>
                    <li>Ensure you have ether on the Arbitrum One network to play. If your funds are on the Ethereum mainnet, you'll need to bridge them over to Arbitrum One.</li>
                  </ol>

                  <p>You're all set to begin your quest!</p>

                </div>}

                {showExcaliburBountyDetails && <div>

                  <h3>The smart contract autonomously governs the game funds and the Excalibur Bounty. The contract is not ownable and the deployers do not control the Excalibur Bounty.</h3>
                  <br />
                  <h4 style={aboutSectionSubtitleStyle}>Game Funds Distribution:</h4>
                  <p>98.5% of all game funds are directly channeled to the players.</p>

                  <h4 style={aboutSectionSubtitleStyle}>Ensuring Randomness:</h4>
                  <p>1.5% fee is dedicated to maintaining randomness, powered by Chainlink, to guarantee an impartial and dependable gameplay.</p>

                  <h4 style={aboutSectionSubtitleStyle}>NFT Sales Contribution:</h4>
                  <p>To further amplify the Excalibur Bounty, 10% of all NFT sales proceeds are donated to the Excalibur Bounty, enhancing the potential rewards for our participants.</p>

                </div>}

                {showRulesSection &&
                  <div style={{ maxHeight: '500px', overflowY: 'auto' }}>

                    <h3>Every time you pull Excalibur for .01 ETH, you stand a chance to win the following prizes:</h3>
                    <br />
                    <h4 style={aboutSectionSubtitleStyle}>The Grand Prize:</h4>
                    <p>Each pull triggers the generation of a unique random number in the background. This number is then benchmarked against a grand threshold, reflecting the epic challenge of liberating the Excalibur from its stone. If fortune smiles upon you and the number aligns with this threshold, you hit the mother lode, seizing the entire amassed game prize pool. Your odds for this monumental victory are 1 in 1,000,000. In celebration of your feat, you are awarded an exclusive Excalibur NFT. This achievement not only signifies the liberation of the sword but also earmarks the grand prize as claimed, culminating in the end of Replica NFT and Silver Pennies minting.</p>

                    <h4 style={aboutSectionSubtitleStyle}>The Knight's Bounty:</h4>
                    <p>Should the grand prize remain out of reach, another opportunity beckons. The initial random number is re-evaluated against a more forgiving threshold. Matching this criterion grants you a rewarding .2 ETH. Your chances for this pleasant twist stand at 1 in 25.</p>

                    <h4 style={aboutSectionSubtitleStyle}>The Round Table Reward:</h4>
                    <p>The game's magnanimity extends beyond the Knight's Bounty. On the occasion of a player clinching the .2 ETH reward, a past participant is thrust into the limelight. A prior player, selected randomly, stands to receive an unexpected gift. If they differ from the current achiever, they are enriched with a bonus of .01 ETH. This gesture ensures that yesteryear's participants remain tethered to the game's ongoing tale, bound by ever-present optimism.</p>

                    <h4 style={aboutSectionSubtitleStyle}>Ensuring Randomness:</h4>
                    <p>To maintain the game's integrity and ensure randomness, a Chainlink fee of .008 ETH is applied each time the .2 ETH prize is awarded.</p>

                  </div>
                }

                {showCreditsSection && <div>

                  <img
                    src={"/theStack.png"} // replace with actual path if different
                    alt="Credits Pyramid"
                    style={{ height: '500px' }}
                  />

                </div>}

              </div>

            </div>
          </div>
        )}


        {showInitialPopup && (
          <div ref={initialPopupRef} style={initialPopUpContainerStyle}>


            <div>
              {/* Display the title and content for the current page */}
              <div>
                {/* Title */}
                <div style={{ backgroundColor: 'rgba(37, 89, 62, 0.84)', padding: '17px', width: '100%', }}>
                  <div style={initialPopupCloseButtonStyle} onClick={() => setShowInitialPopup(false)}>
                    X
                  </div>
                  <div style={{ textAlign: 'left' }}>
                    <h2 style={initialPopupTitleStyle}>{carouselPages[popUpCurrentPage].title}</h2> {/* Apply initialPopupTitleStyle here */}
                  </div>
                </div>


                {/* Two Columns Container */}
                <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
                  {/* Popup Content Column */}
                  <div style={{ width: '80%', textAlign: 'left', marginTop: '2px', paddingLeft: '10px', zIndex: 15 }}>
                    <p>{carouselPages[popUpCurrentPage].content}</p>
                  </div>

                  {/* New Image Column */}
                  <div style={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'start', marginTop: '12px', paddingRight: '22px', zIndex: 14 }}>
                    <img src={carouselPages[popUpCurrentPage].image} alt="Page Specific Image" style={{ height: '100px', width: 'auto' }} />
                  </div>
                </div>
              </div>

              {/* Navigation dots */}
              <div style={{
                ...navigationDotContainerStyle,
                position: 'absolute',
                bottom: '0px', // Adjust this value as needed
                left: 0,
                right: 0,
                textAlign: 'center',
              }}>
                {[0, 1, 2, 3].map((dot) => (
                  <span
                    key={dot}
                    style={{
                      ...navigationDotStyle(dot === popUpCurrentPage),
                      cursor: 'pointer', // Make cursor a pointer to indicate clickability
                      transition: 'transform 0.2s', // Smooth transition for hover effect
                    }}
                    onClick={() => setPopUpCurrentPage(dot)} // Update the current page state on click
                  ></span>
                ))}
              </div>


              {/* Conditional rendering for Next and Close buttons */}
              {popUpCurrentPage < 3 ? (
                <button onClick={() => setPopUpCurrentPage(popUpCurrentPage + 1)}
                  onMouseEnter={() => setPopupButtonHovered(true)}
                  onMouseLeave={() => setPopupButtonHovered(false)}
                  style={{
                    ...buttonStyle, position: 'absolute', bottom: '10px', right: '10px', backgroundColor: mode === 'View' ? (buttonHovered === 'Duel' ? 'rgba(115, 66, 183, 0.64)' : 'rgba(115, 66, 183, 0.84)') : 'rgba(0, 0, 0, 0)',
                    color: buttonHovered === 'View' ? 'white' : (mode === 'View' && buttonHovered !== 'Duel' ? 'white' : 'grey'),
                  }}>
                  Next
                </button>
              ) : (
                <button onClick={() => setShowInitialPopup(false)}
                  onMouseEnter={() => setPopupButtonHovered(true)}
                  onMouseLeave={() => setPopupButtonHovered(false)}
                  style={{
                    ...buttonStyle, position: 'absolute', bottom: '10px', right: '10px', backgroundColor: mode === 'View' ? (buttonHovered === 'Duel' ? 'rgba(115, 66, 183, 0.64)' : 'rgba(115, 66, 183, 0.84)') : 'rgba(0, 0, 0, 0)',
                    color: buttonHovered === 'View' ? 'white' : (mode === 'View' && buttonHovered !== 'Duel' ? 'white' : 'grey'),
                  }}>
                  Close
                </button>
              )}
            </div>
          </div>
        )}



        <img
          src={"/easterEgg.png"} // replace with actual path if different
          alt="Easter Egg"
          style={easterEggStyle}
          onMouseEnter={handleEasterEggMouseEnter}
          onMouseLeave={handleEasterEggMouseLeave}
          onClick={() => { if (easterEggRevealed) handleViewOnchainClick() }}
        />

        <div
          style={{
            position: 'absolute',
            zIndex: 3,
            left: '8px',
            bottom: '590px',
          }}
        >
          {!showInitialPopup && (
            <a href="#" onClick={toggleMute} style={{ fontSize: '24px', textDecoration: 'none' }}>
              {isMuted ? '🔇' : '🔊'}
            </a>
          )}

          {!showBook && !showInitialPopup && (
            <a href="#" onClick={(event) => { setShowBook(true); }} style={{ fontSize: '24px', textDecoration: 'none', marginLeft: '10px', cursor: 'pointer' }}>
              📘
            </a>
          )}

          {showBook && (
            <a href="#" onClick={(event) => { setShowBook(false); }} style={{ fontSize: '24px', textDecoration: 'none', marginLeft: '10px', cursor: 'pointer' }}>
              📖
            </a>
          )}

        </div>

        {mode === 'View' && !showInitialPopup && (
          <div style={swordContainerStyle}>
            {showCanvas && tokenData && (
              <div style={tokenDataStyle}>{tokenData}</div>
            )}

            {ownedTokenIds && ownedTokenIds.length > 0 && (
              <div style={buttonContainerStyle}>
                <button
                  style={{
                    ...buttonStyle,
                    backgroundColor: mode === 'View' ? (buttonHovered === 'Duel' ? 'rgba(37, 89, 62, 0.64)' : 'rgba(37, 89, 62, 0.84)') : 'rgba(0, 0, 0, 0)',
                    color: buttonHovered === 'View' ? 'white' : (mode === 'View' && buttonHovered !== 'Duel' ? 'white' : 'grey'),
                  }}
                  onClick={() => {
                    playButtonClickedSound();
                    changeMode('View');
                  }}
                  onMouseOver={() => setButtonHovered('View')}
                  onMouseOut={() => setButtonHovered(null)}

                >
                  👀 View
                </button>
                <button
                  style={{
                    ...buttonStyle,
                    backgroundColor: mode === 'Duel' ? (buttonHovered === 'View' ? 'rgba(37, 89, 62, 0.64)' : 'rgba(37, 89, 62, 0.84)') : 'rgba(0, 0, 0, 0)',
                    color: buttonHovered === 'Duel' ? 'white' : (mode === 'Duel' && buttonHovered !== 'View' ? 'white' : 'grey'),
                  }}
                  onClick={() => {
                    playButtonClickedSound();
                    changeMode('Duel');
                    setPage(3);
                    processTokenId(ownedTokenIds[displayNftCounter], "OffensiveSword", true);
                    setSelectedOffensiveSword(displayNftCounter); // Assuming displayNftCounter is the value you want to set
                    if (displayNftCounterAll === 2) {
                      processTokenId(displayNftCounterAll + 1, "DefensiveSword", true);
                      setSelectedDefensiveSword(displayNftCounterAll + 1);
                      getDuelMatchUpRating(ownedTokenIds[displayNftCounter], displayNftCounterAll + 1);
                    } else {
                      processTokenId(displayNftCounterAll, "DefensiveSword", true);
                      setSelectedDefensiveSword(displayNftCounterAll);
                      getDuelMatchUpRating(ownedTokenIds[displayNftCounter], displayNftCounterAll);
                    }

                  }}




                  onMouseOver={() => setButtonHovered('Duel')}
                  onMouseOut={() => setButtonHovered(null)}
                >
                  ⚔ Duel
                </button>
              </div>
            )}

            <div style={dropdownContainerStyle}>
              <select
                style={customDropdownStyle}
                value={displayOwnedNfts ? displayNftCounter : displayNftCounterAll}
                onChange={(e) => handleSelectChangeNFTDropdown(e.target.value)}
                onMouseEnter={() => setNftDropdownHovered(true)}
                onMouseLeave={() => setNftDropdownHovered(false)}
              >
                {(displayOwnedNfts ? ownedTokenIds : Array.from({ length: currentTokenId - 1 }, (_, i) => i + 2)).map((token, i) => (
                  <option style={optionStyle} key={i} value={displayOwnedNfts ? i : token}>
                    {String(token) === "2" ? "Excalibur" : "Replica " + token}
                  </option>
                ))}
              </select>
              <svg style={arrowStyle} width="16" height="16" viewBox="0 0 16 16">
                <path d="M4 6l4 4 4-4" stroke={arrowColor} fill="none" />
              </svg> {/* SVG arrow icon */}
              {/* <span style={arrowStyle}>▼</span> */}
            </div>


            <div style={{ display: 'flex', width: '100%' }}>

              <div style={{ position: 'relative', width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>

                <Tooltip
                  title={
                    <>
                      <table style={{ width: '100%' }}>
                        <tr style={rowStyle}>
                          <td style={{ ...greenTextStyle, width: '20%', textAlign: 'right' }}>Hilt</td>
                          <td style={{ width: '55%', textAlign: 'left', paddingLeft: '5%' }}>{swordHilt}</td>
                          <td style={{ width: '25%', textAlign: 'right', paddingLeft: '5%' }}>{swordDigitKeyData[0] === '0' ? '10' : swordDigitKeyData[0]} / 10</td>
                        </tr>
                        <tr style={rowStyle}>
                          <td style={{ ...greenTextStyle, width: '20%', textAlign: 'right' }}>Grip</td>
                          <td style={{ width: '55%', textAlign: 'left', paddingLeft: '5%' }}>{swordGrip}</td>
                          <td style={{ width: '25%', textAlign: 'right', paddingLeft: '5%' }}>{swordDigitKeyData[1] === '0' ? '10' : swordDigitKeyData[1]} / 10</td>
                        </tr>
                        <tr style={rowStyle}>
                          <td style={{ ...greenTextStyle, width: '20%', textAlign: 'right' }}>Blade</td>
                          <td style={{ width: '55%', textAlign: 'left', paddingLeft: '5%' }}>{swordBlade}</td>
                          <td style={{ width: '25%', textAlign: 'right', paddingLeft: '5%' }}>{swordDigitKeyData[2] === '0' ? '10' : swordDigitKeyData[2]} / 10</td>
                        </tr>
                        <tr style={rowStyle}>
                          <td style={{ ...greenTextStyle, width: '20%', textAlign: 'right' }}>Jewel</td>
                          <td style={{ width: '55%', textAlign: 'left', paddingLeft: '5%' }}>{swordJewel}</td>
                          <td style={{ width: '25%', textAlign: 'right', paddingLeft: '5%' }}>{swordDigitKeyData[3] === '0' ? '10' : swordDigitKeyData[3]} / 10</td>
                        </tr>
                        <hr />
                        <tr style={rowStyle}>
                          <td style={{ ...greenTextStyle, width: '20%', textAlign: 'right' }}>Total</td>
                          <td style={{ width: '30%', textAlign: 'left', paddingLeft: '5%' }}></td>
                          <td style={{ width: '50%', textAlign: 'right', paddingLeft: '5%' }}>{swordDigitKeyDataTotal} / 40 = {Math.floor((swordDigitKeyDataTotal / 40) * 100)}%</td>
                        </tr>
                        <tr style={rowStyle}>
                          <td style={{ ...greenTextStyle, width: '20%', textAlign: 'right' }}>Rating</td>
                          <td style={{ width: '60%', textAlign: 'left', paddingLeft: '5%' }}></td>
                          <td style={{ width: '20%', textAlign: 'right', paddingLeft: '5%' }}>
                            {Math.floor((swordDigitKeyDataTotal / 40) * 100)}
                          </td>
                        </tr>


                      </table>

                    </>
                  }
                  placement="left"
                  overlayStyle={{ width: '280px', maxWidth: '280px' }}

                >
                  <div>
                    <span style={{ ...greenTextStyle, ...dataToDisplayBySword }}>Rating:&nbsp;</span>
                    <span style={dataToDisplayBySword}>{swordRatingData}</span>
                  </div>
                </Tooltip>


                <div style={{ position: 'absolute', top: '165px', left: '4px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ ...greenTextStyle, ...dataToDisplayBySword }}>Edge:&nbsp;</div>
                </div>

                <div style={{ position: 'absolute', top: '180px', left: '4px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={dataToDisplayBySword}>{sharpnessData}</div>
                  {addressNFTOwner && addressNFTOwner === address && (sharpnessData === 'Dull' || sharpnessData === 'Sharp') && (
                    <Tooltip title="-131 Silver Pennies" placement="top">
                      <button
                        onClick={handleSharpenSwordClick}
                        onMouseOver={() => {
                          document.getElementById('sharpenSwordButton').style.backgroundColor = buttonHoverBackgroundColor;
                          document.getElementById('sharpenSwordButton').style.color = buttonHoverTextColor;
                        }}
                        onMouseOut={() => {
                          document.getElementById('sharpenSwordButton').style.backgroundColor = buttonBackgroundColor;
                          document.getElementById('sharpenSwordButton').style.color = buttonTextColor;
                        }}
                        style={{
                          marginLeft: '2px',
                          backgroundColor: buttonBackgroundColor,
                          borderRadius: '100px',
                          border: '1px solid black',
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          color: buttonTextColor,
                          height: '12px',  // adjust as required
                          fontSize: '12px',  // adjust as required
                          fontWeight: 'bold',
                          padding: '1px'
                        }}
                        id="sharpenSwordButton"
                      >
                        <span style={{ position: 'relative', top: '-1px' }}>+</span>
                      </button>
                    </Tooltip>
                  )}
                </div>
              </div>


              <div style={{ position: 'relative', width: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={confettiWrapperStyle}>
                  <Confetti active={!loadingNFT} config={confettiConfig} />
                </div>
                {showCanvas
                  ? <canvas
                    ref={canvasRef}
                    width={width * 4}
                    height={height * 4}
                    style={{ width: width * 4, height: height * 4 }}
                  />
                  : transactionErrorCheck
                    ? <img src={swordImageFileBackUp} alt="Sword Back Up" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    : swordImageFile && <img src={swordImageFile} alt="Sword" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                }
              </div>

              <div style={{ position: 'relative', width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                <Tooltip title="Duel count" placement="top">
                  <span style={{ ...greenTextStyle, ...dataToDisplayBySword }}>XP:&nbsp;  </span>
                  <span style={dataToDisplayBySword}>{duelCount}</span>
                </Tooltip>
                {addressNFTOwner && (
                  <div style={{ position: 'absolute', top: '170px', left: '4px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Tooltip title="View on OpenSea" placement="top">
                      <a
                        href={openSeaUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        id="viewOnchainLink"
                        onMouseOver={() => {
                          document.getElementById('viewOnchainLink').style.backgroundColor = buttonHoverBackgroundColor;
                          document.getElementById('viewOnchainLink').style.color = buttonHoverTextColor;
                        }}
                        onMouseOut={() => {
                          document.getElementById('viewOnchainLink').style.backgroundColor = buttonBackgroundColor;
                          document.getElementById('viewOnchainLink').style.color = buttonTextColor;
                        }}
                        style={{
                          zIndex: 3,
                          padding: '4px',
                          backgroundColor: buttonBackgroundColor,
                          borderRadius: '10px',
                          border: '1px solid black',
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                          width: '50px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          color: buttonTextColor,
                          textDecoration: 'none', // optional, to remove the underline commonly seen on links
                          textAlign: 'center', // optional, to center the text within the link
                        }}
                      >
                        <span style={{ fontSize: '12px' }}>
                          ⛵🔗
                        </span>
                      </a>

                    </Tooltip>
                  </div>
                )}
              </div>

            </div>


            <div style={{
              ...greenTextStyle,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: '500',
              paddingTop: '6px'
            }}>
              <span
                role="button"
                onClick={decrementDisplayNftCounter}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  left: '10px',
                  fontSize: '20px'
                }}>
                {isIphoneUser ? '←' : '🠘'}
              </span>
              {
                displayOwnedNfts
                  ? `${displayNftCounter + 1} of ${ownedTokenIds.length}`
                  : `${displayNftCounterAll - 1} of ${currentTokenId - 1}`
              }
              <span
                role="button"
                onClick={incrementDisplayNftCounter}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '10px',
                  fontSize: '20px'
                }}>
                {isIphoneUser ? '→' : '🠚'}
              </span>
            </div>

            {ownedTokenIds.length > 0 && (

              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex' }}>
                  <span
                    role="button"
                    onClick={() => {
                      setDisplayOwnedNfts(true);
                      processTheTokenId(true);
                    }}
                    onMouseOver={() => setButtonHovered('Your Collection')}
                    onMouseOut={() => setButtonHovered(null)}
                    style={{
                      ...buttonStyle,
                      cursor: 'pointer',
                      paddingLeft: '2px',
                      color: buttonHovered === 'Your Collection' ? 'white' : (displayOwnedNfts === true && buttonHovered !== 'Browse All' ? 'white' : 'grey'),
                      backgroundColor: displayOwnedNfts === true ? (buttonHovered === 'Browse All' ? 'rgba(37, 89, 62, 0.64)' : 'rgba(37, 89, 62, 0.84)') : 'rgba(0, 0, 0, 0)',
                      fontSize: '10px',
                      borderRadius: '2px',
                    }}
                  >
                    Your Collection
                  </span>
                  <span
                    role="button"
                    onClick={() => {
                      getCurrentTokenId();
                      setDisplayOwnedNfts(false);
                      processTheTokenId(false);
                    }}
                    onMouseOver={() => setButtonHovered('Browse All')}
                    onMouseOut={() => setButtonHovered(null)}
                    style={{
                      ...buttonStyle,
                      cursor: 'pointer',
                      paddingRight: '2px',
                      color: buttonHovered === 'Browse All' ? 'white' : (displayOwnedNfts === false && buttonHovered !== 'Your Collection' ? 'white' : 'grey'),
                      backgroundColor: displayOwnedNfts === false ? (buttonHovered === 'Your Collection' ? 'rgba(37, 89, 62, 0.64)' : 'rgba(37, 89, 62, 0.84)') : 'rgba(0, 0, 0, 0)',
                      fontSize: '10px',
                      borderRadius: '2px',
                    }}
                  >
                    Browse All
                  </span>
                </div>
              </div>


            )}


            <table style={{ width: '100%' }}>
              <tr style={rowStyle}>
                <td style={{ ...greenTextStyle, width: '47px', textAlign: 'right' }}>Owner</td>
                <td style={{ width: '75%', textAlign: 'left', paddingLeft: '5%' }}>
                  {
                    addressNFTOwner
                      ? <Address address={addressNFTOwner} ensProvider={provider} blockExplorer={blockExplorer} fontSize={12} />
                      : nftName === 'Excalibur' && excaliburFreed === true && winningPlayerAddress !== ''
                        ? <Address address={winningPlayerAddress} ensProvider={provider} blockExplorer={blockExplorer} fontSize={12} />
                        : "The Stone"
                  }
                </td>
              </tr>


              <tr style={rowStyle}>
                <td style={{ ...greenTextStyle, width: '47px', textAlign: 'right' }}>Hilt</td>
                <td style={{ width: '75%', textAlign: 'left', paddingLeft: '5%' }}>{swordHilt}</td>
              </tr>
              <tr style={rowStyle}>
                <td style={{ ...greenTextStyle, width: '47px', textAlign: 'right' }}>Grip</td>
                <td style={{ width: '75%', textAlign: 'left', paddingLeft: '5%' }}>{swordGrip}</td>
              </tr>
              <tr style={rowStyle}>
                <td style={{ ...greenTextStyle, width: '47px', textAlign: 'right' }}>Blade</td>
                <td style={{ width: '75%', textAlign: 'left', paddingLeft: '5%' }}>{swordBlade}</td>
              </tr>
              <tr style={rowStyle}>
                <td style={{ ...greenTextStyle, width: '47px', textAlign: 'right' }}>Jewel</td>
                <td style={{ width: '75%', textAlign: 'left', paddingLeft: '5%' }}>{swordJewel}</td>
              </tr>

            </table>


            <button
              id="mintReplicaButton"
              onClick={() => {
                if (!excaliburFreed && !attemptingMint) {
                  handleMintReplica();
                }
              }}
              onMouseOver={() => {
                document.getElementById('mintReplicaButton').style.backgroundColor = buttonHoverBackgroundColorReplica;
                document.getElementById('mintReplicaButton').style.color = buttonHoverTextColor;
                setPage(1);
              }}
              onMouseOut={() => {
                document.getElementById('mintReplicaButton').style.backgroundColor = buttonBackgroundColorReplica;
                document.getElementById('mintReplicaButton').style.color = buttonTextColor;
              }}
              style={{
                zIndex: 3,
                padding: '4px',
                backgroundColor: buttonBackgroundColorReplica,
                borderRadius: '10px',
                border: '1px solid black',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                width: '120px',
                height: '51px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: attemptingMint ? 'default' : 'pointer',
                color: buttonTextColor,
                marginTop: '5px'
              }}
            >
              {excaliburFreed ? (
                <span style={{ fontSize: '14px' }}>
                  <b>Mint Ended</b>
                </span>
              ) : (
                <>
                  <span style={{ fontSize: '14px' }}>
                    <b>{attemptingMint ? "Forging Sword" : "Mint Replica"}</b>
                  </span>
                  {!attemptingMint && (
                    <span style={{ fontSize: '12px' }}>
                      {MINT_REPLICA_SWORD_PRICE.toString().replace(/^0+/, '')} ETH
                    </span>
                  )}
                </>
              )}
            </button>
          </div>
        )}

        {mode === 'Duel' && !showDuelInfo && (
          <div style={swordContainerStyle}>

            <div style={buttonContainerStyle}>
              <button
                style={{
                  ...buttonStyle,
                  backgroundColor: mode === 'View' ? (buttonHovered === 'Duel' ? 'rgba(37, 89, 62, 0.64)' : 'rgba(37, 89, 62, 0.84)') : 'rgba(0, 0, 0, 0)',
                  color: buttonHovered === 'View' ? 'white' : (mode === 'View' && buttonHovered !== 'Duel' ? 'white' : 'grey'),
                }}
                onClick={() => {
                  playButtonClickedSound();
                  changeMode('View');
                  setPage(1);
                }}
                onMouseOver={() => setButtonHovered('View')}
                onMouseOut={() => setButtonHovered(null)}
              >
                👀 View
              </button>
              <button
                style={{
                  ...buttonStyle,
                  backgroundColor: mode === 'Duel' ? (buttonHovered === 'View' ? 'rgba(37, 89, 62, 0.64)' : 'rgba(37, 89, 62, 0.84)') : 'rgba(0, 0, 0, 0)',
                  color: buttonHovered === 'Duel' ? 'white' : (mode === 'Duel' && buttonHovered !== 'View' ? 'white' : 'grey'),
                }}
                onClick={() => changeMode('Duel')}
                onMouseOver={() => setButtonHovered('Duel')}
                onMouseOut={() => setButtonHovered(null)}
              >
                ⚔ Duel
              </button>
            </div>


            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '-18px', }}>
              <div style={{ display: 'flex' }}>
                <span
                  style={{
                    ...buttonStyle,
                    cursor: 'pointer',
                    paddingRight: '2px',
                    color: "white",
                    backgroundColor: "transparent",
                    fontSize: '10px',
                    borderRadius: '2px',
                  }}
                >
                  Your Collection
                </span>
                <span
                  style={{
                    ...buttonStyle,
                    cursor: 'pointer',
                    paddingRight: '2px',
                    color: "white",
                    backgroundColor: "transparent",
                    fontSize: '10px',
                    borderRadius: '2px',
                  }}
                >
                  All Swords
                </span>
              </div>
            </div>

            <div style={{ display: 'flex', width: '100%' }}>


              <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <select
                  style={dropdownStyle}
                  value={selectedOffensiveSword}
                  onChange={e => {
                    handleSelectChange(e.target.value, "OffensiveSword");
                    setSelectedOffensiveSword(e.target.value);
                  }}
                >
                  {ownedTokenIds.map((token, i) =>
                    <option style={optionStyle} key={i} value={i}>
                      {String(token) === "2" ? "Excalibur" : "Replica " + token}
                    </option>
                  )}


                </select>
              </div>

              <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <select
                  style={dropdownStyle}
                  value={selectedDefensiveSword}
                  onChange={e => {
                    handleSelectChange(e.target.value, "DefensiveSword");
                    setSelectedDefensiveSword(e.target.value);
                  }}
                >
                  {[...Array(parseInt(currentTokenId - (excaliburFreed ? 1 : 2)))].map((_, i) => {
                    const value = i + (excaliburFreed ? 2 : 3);
                    const label = value === 2 ? 'Excalibur' : `Replica ${value}`;
                    return (
                      <option style={optionStyle} key={i} value={value}>
                        {label}
                      </option>
                    );
                  })}
                </select>
              </div>

            </div>

            <div style={{ position: 'relative', display: 'flex', width: '100%' }}>

              <div style={{ position: 'absolute', top: 0, left: '2px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span style={{ ...greenTextStyle, ...dataToDisplayBySword }}>Rating:&nbsp;</span>
                <span style={dataToDisplayBySword}>{swordRatingDataOffensiveSword}</span>
              </div>

              <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                {showCanvas
                  ? <canvas
                    ref={canvasRef}
                    width={width * 4}
                    height={height * 4}
                    style={{ width: width * 4, height: height * 4 }}
                  />
                  : swordImageFileOffensiveSword && <img src={swordImageFileOffensiveSword} alt="Sword" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                }
                <div style={{ position: 'absolute', top: 0, right: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <span style={{ ...greenTextStyle, ...dataToDisplayBySword }}>XP:&nbsp;</span>
                  <span style={dataToDisplayBySword}>{duelCountOffensiveSword}</span>
                </div>

                <div style={{ position: 'absolute', top: '155px', left: '2px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ ...greenTextStyle, ...dataToDisplayBySword }}>Edge:&nbsp;</div>
                </div>

                <div style={{ position: 'absolute', top: '170px', left: leftValue, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={dataToDisplayBySword}>{sharpnessDataOffensiveSword}</div>
                  {tokenOwnerOffensiveSword && tokenOwnerOffensiveSword === address && (sharpnessDataOffensiveSword === 'Dull' || sharpnessDataOffensiveSword === 'Sharp') && (
                    <Tooltip title="-131 Silver Pennies" placement="top">
                      <button
                        onClick={handleSharpenOffensiveSwordClick}
                        onMouseOver={() => {
                          document.getElementById('sharpenOffensiveSwordButton').style.backgroundColor = buttonHoverBackgroundColor;
                          document.getElementById('sharpenOffensiveSwordButton').style.color = buttonHoverTextColor;
                        }}
                        onMouseOut={() => {
                          document.getElementById('sharpenOffensiveSwordButton').style.backgroundColor = buttonBackgroundColor;
                          document.getElementById('sharpenOffensiveSwordButton').style.color = buttonTextColor;
                        }}
                        style={{
                          marginLeft: '2px',
                          backgroundColor: buttonBackgroundColor,
                          borderRadius: '100px',
                          border: '1px solid black',
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          color: buttonTextColor,
                          height: '12px',  // adjust as required
                          fontSize: '12px',  // adjust as required
                          fontWeight: 'bold',
                          padding: '1px'

                        }}
                        id="sharpenOffensiveSwordButton"
                      >
                        <span style={{ position: 'relative', top: '-1px' }}>+</span>
                      </button>
                    </Tooltip>
                  )}
                </div>


              </div>


              <div style={{ position: 'absolute', top: 0, left: '98px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span style={{ ...greenTextStyle, ...dataToDisplayBySword }}>Rating:&nbsp;</span>
                <span style={dataToDisplayBySword}>{swordRatingDataDefensiveSword}</span>
              </div>
              <div style={{ position: 'absolute', top: '155px', left: '98px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ ...greenTextStyle, ...dataToDisplayBySword }}>Edge:&nbsp;</div>
              </div>



              <div style={{ position: 'absolute', top: '170px', left: '98px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={dataToDisplayBySword}>{sharpnessDataDefensiveSword}</div>
              </div>

              <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                {showCanvas
                  ? <canvas
                    ref={canvasRef}
                    width={width * 4}
                    height={height * 4}
                    style={{ width: width * 4, height: height * 4 }}
                  />
                  : swordImageFileDefensiveSword && <img src={swordImageFileDefensiveSword} alt="Sword" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                }
                <div style={{ position: 'absolute', top: 0, right: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <span style={{ ...greenTextStyle, ...dataToDisplayBySword }}>XP:&nbsp;</span>
                  <span style={dataToDisplayBySword}>{duelCountDefensiveSword}</span>
                </div>



              </div>

            </div>



            <div style={{ display: 'flex', width: '100%', fontSize: '16px' }}>
              <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {matchUpRating}%
              </div>
              <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {100 - matchUpRating}%
              </div>
            </div>

            <div style={{ display: 'flex', width: '100%', fontSize: '16px' }}>
              <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {
                  <Address address={tokenOwnerOffensiveSword} ensProvider={provider} blockExplorer={blockExplorer} fontSize={10} />
                }
              </div>
              <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {
                  <Address address={tokenOwnerDefensiveSword} ensProvider={provider} blockExplorer={blockExplorer} fontSize={10} />
                }
              </div>
            </div>

            <div style={{ display: 'flex', width: '100%', fontSize: '8px' }}>
              <div style={{ ...greenTextStyle, width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                Hilt
              </div>
              <div style={{ width: '45%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {swordHiltOffensiveSword}
              </div>
              <div style={{ width: '45%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {swordHiltDefensiveSword}
              </div>
            </div>

            <div style={{ display: 'flex', width: '100%', fontSize: '8px' }}>
              <div style={{ ...greenTextStyle, width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                Grip
              </div>
              <div style={{ width: '45%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {swordGripOffensiveSword}
              </div>
              <div style={{ width: '45%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {swordGripDefensiveSword}
              </div>
            </div>

            <div style={{ display: 'flex', width: '100%', fontSize: '8px' }}>
              <div style={{ ...greenTextStyle, width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                Blade
              </div>
              <div style={{ width: '45%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {swordBladeOffensiveSword}
              </div>
              <div style={{ width: '45%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {swordBladeDefensiveSword}
              </div>
            </div>

            <div style={{ display: 'flex', width: '100%', fontSize: '8px' }}>
              <div style={{ ...greenTextStyle, width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                Jewel
              </div>
              <div style={{ width: '45%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {swordJewelOffensiveSword}
              </div>
              <div style={{ width: '45%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {swordJewelDefensiveSword}
              </div>
            </div>

            {duelsWonById &&
              <div style={{ display: 'flex', width: '100%', fontSize: '8px' }}>
                <div style={{ ...greenTextStyle, width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  Offensive Duel Wins by {nftNameOffensiveSword}
                </div>
              </div>
            }
            {duelsWonById &&
              <div style={{ display: 'flex', width: '100%', fontSize: '8px' }}>
                <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {duelsWonById.split(',').map(id => `Replica ${id}`).join(', ')}
                </div>
              </div>
            }





            <button
              id="duelSwordsButton"
              onClick={() => {
                playButtonClickedSound();
                changeButtonBorder('duelSwordsButton');
                console.log(ownedTokenIds[selectedOffensiveSword]);
                console.log(selectedDefensiveSword);
                challengeToDuel(ownedTokenIds[selectedOffensiveSword], selectedDefensiveSword);
              }}
              onMouseOver={() => {
                document.getElementById('duelSwordsButton').style.backgroundColor = buttonHoverBackgroundColor;
                document.getElementById('duelSwordsButton').style.color = buttonHoverTextColor;
                setPage(3);
              }}
              onMouseOut={() => {
                document.getElementById('duelSwordsButton').style.backgroundColor = buttonBackgroundColor;
                document.getElementById('duelSwordsButton').style.color = buttonTextColor;
              }}
              style={{
                zIndex: 3,
                padding: '4px',
                backgroundColor: buttonBackgroundColor,
                borderRadius: '10px',
                border: '1px solid black',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                width: '120px',
                height: '51px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: attemptingDuel ? 'default' : 'pointer',
                color: buttonTextColor,
                marginTop: '5px'
              }}
            >
              <span style={{ fontSize: '14px' }}>
                <b>{attemptingDuel ? "En Garde!" : "Duel Swords"}</b>
              </span>
              {!attemptingDuel && (
                <span style={{ fontSize: '12px' }}>.001 ETH & 12 SPNY</span>
              )}
            </button>

          </div>
        )}


        {showDuelInfo && (
          <div style={previewDuelsContainerStyle}>

            <div style={{ alignSelf: 'flex-start', width: '100%' }}>
              <p style={{ ...greenTextStyle, ...dataToDisplayBySword, textAlign: 'left', cursor: 'pointer' }}
                onClick={() => {
                  changeMode('View');
                  setShowDuelInfo(false);
                }}>
                &lt; Back
              </p>
            </div>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <p style={mediumPinkTextStyle}>Preview Duels:</p>
              <p style={{ marginBottom: '10px', textAlign: 'center' }}>Get a sneak peek at dueling swords, poised for fuller integration in upcoming game stages.</p>
            </div>

            <br></br>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <button style={duelContinueButtonStyle}
                onMouseEnter={handleContinueButtonMouseEnter}
                onMouseLeave={handleContinueButtonMouseLeave}
                onClick={() => setShowDuelInfo(false)}><b>Continue</b></button>
            </div>

          </div>
        )}


        {displayBirdMessage && !displayBirdAdvice && (
          <p style={birdMessageStyle}>
            {birdTalk[currentMessageIndexBird]}
            <div style={birdMessageTailStyle} />
          </p>
        )}

        {displayBirdAdvice && (
          <div>
            <p style={{
              ...birdMessageStyle,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              {birdAdvice[currentBirdAdviceIndex]}
              <button style={twitterShareButtonStyle} onClick={shareTweet}>
                <span style={{ fontSize: 'larger' }}>𝕏</span>&nbsp;&nbsp;
                <span style={{ paddingTop: '1px' }}>Post</span>
              </button>
              <div style={birdMessageTailStyle} />
            </p>
          </div>
        )}

        <img
          src="/bird.png"
          alt="bird"
          style={birdStyle}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            handleBirdMouseOver();
          }}
          onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
        />

        {displaySquirrelMessage && (
          <p style={squirrelMessageStyle}>
            {squirrelTalk[currentMessageIndex]}
            <div style={squirrelMessageTailStyle} />
          </p>
        )}

        {displaySquirrelAdvice && (
          <p style={squirrelMessageStyle}>
            {squirrelAdvice[currentAdviceIndex]}
            <div style={squirrelMessageTailStyle} />
          </p>
        )}

        <div
          style={{
            position: 'absolute',
            zIndex: 3,
            left: '8px',
            bottom: '115px',
            padding: '10px',
            borderRadius: '10px',
            border: '1px solid black',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            width: '210px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'rgba(0, 0, 0, 0.60)',
          }}
        >

          {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '12px', textAlign: 'left', color: 'rgba(96, 161, 96)' }}><b>Game Address</b></div>
            <div style={{ fontSize: '12px', textAlign: 'right' }}><b><span style={{ color: 'rgba(147, 144, 183)' }}>{readContracts && readContracts.MerlinsGambitQ1 ? <Address address={readContracts.MerlinsGambitQ1.address} ensProvider={provider} blockExplorer={blockExplorer} fontSize={10} /> : "loading..."}</span></b></div>
          </div> */}

          <div style={gameAddressStyle}>
            <div style={gameAddressTextStyle}><b>Game Address</b></div>
            <div style={gameValueTextStyle}>
              <b>
                <span>
                  {readContracts && readContracts.MerlinsGambitQ1 ?
                    <Address address={readContracts.MerlinsGambitQ1.address} ensProvider={provider} blockExplorer={blockExplorer} fontSize={10} />
                    : "loading..."}
                </span>
              </b>
            </div>
          </div>


          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '10px', textAlign: 'left', color: 'rgba(199, 108, 204)' }}><b>Sword Pulls</b></div>
            <div style={{ fontSize: '10px', textAlign: 'right', color: 'rgba(147, 144, 183)' }}><b>{swordPullCounter !== null && swordPullCounter !== undefined ? swordPullCounter : "loading..."}</b></div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '10px', textAlign: 'left', color: 'rgba(199, 108, 204)' }}><b>ETH Won</b></div>
            <div style={{ fontSize: '10px', textAlign: 'right', color: 'rgba(147, 144, 183)' }}><b>
              {Number(gameWinnings) + Number(finalGrandPayoutAmount) + Number(gameWinningsSinceUpdate)}
              {/*  GameWinnings: {gameWinnings} finalGrandPayoutAmount: {finalGrandPayoutAmount} gameWinningsSinceUpdate: {gameWinningsSinceUpdate}
             */}
            </b></div>
          </div>


          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '10px', textAlign: 'left', color: 'rgba(199, 108, 204)' }}><b>Replicas Minted</b></div>
            <div style={{ fontSize: '10px', textAlign: 'right', color: 'rgba(147, 144, 183)' }}><b>{mintCounterNFT !== null && mintCounterNFT !== undefined ? mintCounterNFT : "loading..."}</b></div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '10px', textAlign: 'left', color: 'rgba(199, 108, 204)' }}><b>Silver Pennies Supply</b></div>
            <div style={{ fontSize: '10px', textAlign: 'right', color: 'rgba(147, 144, 183)' }}><b>{totalSilverPenniesSupply ? Math.floor(totalSilverPenniesSupply.toString()) + totalSilverPenniesSupplyChangeSinceUpdate : "loading..."}</b></div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <a href="https://arbiscan.io/address/0x96DFAf04664ff11189BE6208b46Ca7C311CC8c19#code" target="_blank"
              style={{
                marginTop: '5px',  // Adjust this value as needed
                marginBottom: '-4px'
              }}>
              <div style={{ fontSize: '10px', textAlign: 'left', color: 'rgba(200, 200, 200)', transition: 'color 0.3s' }}
                onMouseOver={(e) => e.currentTarget.style.color = '#3242a8'}
                onMouseOut={(e) => e.currentTarget.style.color = 'rgba(200, 200, 200)'}>
                <b>Read the verified contract</b></div>
            </a>
          </div>
        </div>

        {!showInitialPopup && (
          <div
            ref={instructionsDivRef}
            style={{
              position: 'absolute',
              zIndex: 3,
              left: '8px',
              bottom: '242px',
              padding: '10px',
              borderRadius: '10px',
              border: '1px solid black',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              width: '210px',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'rgba(0, 0, 0, 0.60)',
            }}
          >


            {carouselIndex === 0 && (
              <div>
                <span style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  color: 'rgba(96, 161, 96)',
                }}>
                  <b>How to Play</b>
                </span>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ fontSize: '17px', textAlign: 'left', color: 'rgba(199, 108, 204)' }}><b>Pull Excalibur</b></div>
                  <div style={{ fontSize: '17px', textAlign: 'right', color: 'rgba(147, 144, 183)' }}><b>.01 ETH</b></div>
                </div>

                <div style={{ fontSize: '14px', textAlign: 'left', position: 'relative', marginTop: '10px' }}>Free the Sword from the Stone to earn the Excalibur Bounty & NFT <span style={{ fontSize: '9px', color: 'orange' }}>&nbsp;(Odds 1 : Million)</span></div>




                <div style={{ fontSize: '14px', textAlign: 'left', position: 'relative', marginTop: '10px' }}>Participate for a chance to receive .2 ETH <span style={{ fontSize: '9px', textAlign: 'left', color: 'orange' }}>&nbsp;(Odds 1 : 25)</span></div>




                <div style={{ fontSize: '14px', textAlign: 'left', position: 'relative', marginTop: '10px' }}>Random previous participant gets .01 ETH <span style={{ fontSize: '9px', textAlign: 'left', color: 'orange' }}>&nbsp;(Odds 1 : 25)</span></div>
                <div style={{ fontSize: '14px', textAlign: 'center', marginTop: '10px', color: 'rgba(117, 117, 224)' }}><b>Each pull mints 100 Silver Pennies</b></div>
              </div>
            )}

            {carouselIndex === 1 && (
              <div>
                <span style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  color: 'rgba(96, 161, 96)',
                }}>
                  <b>Replica NFTs</b>
                </span>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ fontSize: '17px', textAlign: 'left', color: 'rgba(199, 108, 204)' }}><b>Mint Replica</b></div>
                  <div style={{ fontSize: '17px', textAlign: 'right', color: 'rgba(147, 144, 183)' }}><b>.1 ETH</b></div>
                </div>

                <div style={{ fontSize: '14px', textAlign: 'left', position: 'relative', marginTop: '10px', color: 'orange' }}>Generative onchain NFTs </div>

                <div style={{ fontSize: '14px', textAlign: 'left', position: 'relative', marginTop: '10px' }}>
                  Each sword has a Blade, Grip, Hilt, and Jewel. <span onClick={() => setShowSwordExamples(true)} style={{ cursor: 'pointer', color: '#7777f8' }}> See Examples</span>
                </div>

                <div style={{ fontSize: '14px', textAlign: 'left', position: 'relative', marginTop: '10px' }}>Sharpen your sword to improve its Edge </div>

                <div style={{ fontSize: '14px', textAlign: 'left', position: 'relative', marginTop: '10px' }}>Compete in Duels to earn XP </div>

                <div style={{ fontSize: '14px', textAlign: 'center', marginTop: '10px', color: 'rgba(184, 78, 78)' }}><b>Minting ends when Excalibur is freed</b></div>
              </div>
            )}

            {carouselIndex === 2 && (
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{
                    fontSize: '20px',
                    textAlign: 'left',
                    color: 'rgba(96, 161, 96)',
                  }}>
                    <b>Silver Pennies</b>
                  </div>
                  <div style={{ fontSize: '12px', textAlign: 'right', color: 'rgba(147, 144, 183)', paddingTop: '7px' }}>
                    <b>$SPNY</b>
                  </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ fontSize: '17px', textAlign: 'left', color: 'rgba(199, 108, 204)' }}><b>Each pull mints tokens</b></div>
                </div>

                <div style={{ fontSize: '14px', textAlign: 'left', position: 'relative', marginTop: '10px' }}><span style={{ color: 'orange' }}>100 Silver Pennies </span>are minted to each user who pulls Excalibur </div>

                <div style={{ fontSize: '14px', textAlign: 'left', position: 'relative', marginTop: '10px' }}><span style={{ color: 'orange' }}>131 Silver Pennies </span>are burned to sharpen your sword  </div>

                <div style={{ fontSize: '14px', textAlign: 'left', position: 'relative', marginTop: '10px' }}><span style={{ color: 'orange' }}>12 Silver Pennies </span>are paid to dueling opponent </div>

                <div style={{ fontSize: '14px', textAlign: 'center', marginTop: '10px', color: 'rgba(184, 78, 78)' }}><b>Minting ends when Excalibur is freed</b></div>
              </div>
            )}

            {carouselIndex === 3 && (
              <div>
                <span style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  color: 'rgba(96, 161, 96)',
                }}>
                  <b>Sword Duels</b>
                </span>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ fontSize: '15px', textAlign: 'left', color: 'rgba(199, 108, 204)' }}><b>Duel</b></div>
                  <div style={{ fontSize: '15px', textAlign: 'right', color: 'rgba(147, 144, 183)' }}><b>.001 ETH & 12 SPNY</b></div>
                </div>

                <div style={{ fontSize: '14px', textAlign: 'left', position: 'relative', marginTop: '10px' }}><span style={{ color: 'orange' }}>Winner </span>- determined by Rating, XP, Edge, and luck </div>

                <div style={{ fontSize: '14px', textAlign: 'left', position: 'relative', marginTop: '10px' }}><span style={{ color: 'orange' }}>XP </span>- participate in duels to gain experience points</div>

                <div style={{ fontSize: '14px', textAlign: 'left', position: 'relative', marginTop: '10px' }}><span style={{ color: 'orange' }}>Earn </span>- receive 12 Silver Pennies when dueled </div>

                <div style={{ fontSize: '14px', textAlign: 'center', marginTop: '10px', color: 'rgba(117, 117, 224)' }}><b>Buy a Replica NFT to compete in duels</b></div>

              </div>
            )}

            {/* Navigation Buttons */}

            <div style={{
              color: 'rgba(237, 250, 237)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: '500',
              paddingTop: '6px'
            }}>
              <span
                role="button"
                onClick={previousPage}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  left: '10px',
                  fontSize: '20px'
                }}>
                {isIphoneUser ? '←' : '🠘'}
              </span>
              {Array(CAROUSEL_LENGTH).fill().map((_, i) =>
                <span
                  key={i}
                  style={{ margin: '0 5px', fontSize: '8px', cursor: 'pointer' }}
                  onClick={() => setPageByClick(i)}
                >
                  {i === carouselIndex ? '⚪' : '⚫'}
                </span>
              )}

              <span
                role="button"
                onClick={nextPage}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '10px',
                  fontSize: '20px'
                }}>
                {isIphoneUser ? '→' : '🠚'}
              </span>
            </div>


          </div>
        )}

        <img
          src="/squirrel.png"
          alt="squirrel"
          style={squirrelStyle}
          //onClick={handleMintReplica}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            handleSquirrelMouseOver();
          }}
          onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
        />

        <div
          style={{
            margin: 8,
            position: 'absolute',
            bottom: '80px',
            display: 'flex',
            justifyContent: 'center',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        >

          <div style={{ position: 'relative', height: '200px' }}>
            <div
              id="imageWrapper" // Add an ID to this wrapper
              onClick={handleMineSilverPennies}
              onMouseOver={(e) => {
                if (timeoutRef.current) {
                  clearTimeout(timeoutRef.current);
                }
                e.currentTarget.style.transform = 'scale(1.02)';
                document.getElementById('swordPullButton').style.backgroundColor = buttonHoverBackgroundColor;
                document.getElementById('swordPullButton').style.color = buttonHoverTextColor;
                setSwordButtonHovered(true);
              }}
              onMouseOut={() => {
                document.getElementById('swordPullButton').style.backgroundColor = buttonBackgroundColor;
                document.getElementById('swordPullButton').style.color = buttonTextColor;
                timeoutRef.current = setTimeout(() => {
                  document.getElementById('imageWrapper').style.transform = 'scale(1)';
                  setSwordButtonHovered(false);
                }, delayTime); // 500ms delay
              }}
              style={{
                position: 'relative',
                height: '160px',
                top: '10px', // Adjust this value to move the images lower on the page
                cursor: 'pointer',
              }}
            >

              {
                // Show when excalibur is not freed
                !excaliburFreed ? (
                  <img
                    src="/Excalibur.png"
                    alt="Excalibur"
                    style={{
                      position: 'absolute',
                      zIndex: 1,
                      top: '4%',
                      left: '-52px',
                      transform: 'translateY(-50%)',
                    }}
                  />
                ) : null
              }

              {
                // Show when gameWinner is true
                gameWinner ? (
                  <img
                    src="/Excalibur_up.png"
                    alt="Excalibur Up"
                    style={{
                      position: 'absolute',
                      zIndex: 1,
                      top: '-115px',  // You can adjust this as needed
                      left: '-50.5px',  // You can adjust this as needed
                      transform: 'translateY(-50%)',
                    }}
                  />
                ) : null
              }

              <img
                src="/stone.png"
                alt="Stone"
                style={{
                  position: 'absolute',
                  width: '180px',
                  zIndex: 2,
                  left: '-92px',
                  bottom: '0px',
                }}
              />


              <img
                src="/mossyTablet.png"
                alt="Tablet"
                style={{
                  position: 'absolute',
                  width: '96px',
                  zIndex: 3,
                  left: '-68px',
                  bottom: '49px',
                }}
              />

              <span
                style={{
                  position: 'absolute',
                  width: '96px',
                  left: '-68px', // Set to the same value as the image for alignment
                  bottom: '51px', // Adjust to position the text where you want it relative to the image
                  color: '#1c1c23',
                  zIndex: 4,
                  background: 'transparent', // Optional: semi-transparent background for readability
                  padding: '5px',
                  display: 'flex', // Flex container
                  justifyContent: 'center', // Center horizontally
                  alignItems: 'center', // Center vertically
                  fontFamily: 'Righteous',
                  fontSize: '13px',  // Here's the added font size
                  marginBottom: '2px'
                }}
              >

                {/* <Balance size="13px" address={readContracts && readContracts.MerlinsGambitQ1 ? readContracts.MerlinsGambitQ1.address : null} provider={provider} price={price} /> */}
                {/*<MyBalance size="13px" balance={parseFloat(currentContractData.contractBalance) + contractBalanceSinceUpdate} price={price} /> */}
                <MyBalance size="13px" balance={contractBalance} balanceSinceUpdate={contractBalanceSinceUpdate} price={price} />

                {/*
                contractBalanceSinceUpdate: {contractBalanceSinceUpdate}
                contractBalance: {parseFloat(utils.formatEther(contractBalance))}
                sum: {parseFloat(utils.formatEther(contractBalance)) + contractBalanceSinceUpdate}
              */}
              </span>

              <div
                style={{
                  position: 'absolute',
                  width: '96px',
                  left: '-68px', // Set to the same value as the image for alignment
                  bottom: '54px', // Adjust to position the text where you want it relative to the image
                  color: '#1c1c23',
                  zIndex: 4,
                  background: 'transparent', // Optional: semi-transparent background for readability
                  padding: '5px',
                  display: 'flex', // Flex container
                  justifyContent: 'center', // Center horizontally
                  alignItems: 'center', // Center vertically
                  fontFamily: 'Righteous',
                  fontSize: '8px',  // Here's the added font size

                }}
              >

                EXCALIBUR BOUNTY

              </div>

            </div>


            <button
              id="swordPullButton"
              onClick={() => {
                if (!excaliburFreed && !attemptingPull) {
                  handleMineSilverPennies();
                }
              }}
              onMouseOver={() => {
                if (timeoutRef.current) {
                  clearTimeout(timeoutRef.current);
                }
                document.getElementById('imageWrapper').style.transform = 'scale(1.02)';
                document.getElementById('swordPullButton').style.backgroundColor = buttonHoverBackgroundColor;
                document.getElementById('swordPullButton').style.color = buttonHoverTextColor;
                setSwordButtonHovered(true);
                setPage(0);
              }}
              onMouseOut={() => {
                document.getElementById('imageWrapper').style.transform = 'scale(1)';
                document.getElementById('swordPullButton').style.backgroundColor = buttonBackgroundColor;
                document.getElementById('swordPullButton').style.color = buttonTextColor;
                setSwordButtonHovered(false);
              }}
              style={{
                position: 'absolute',
                zIndex: 3,
                left: '50%',
                bottom: '-70px',
                transform: 'translateX(-50%)',
                padding: '10px',
                backgroundColor: buttonBackgroundColor,
                borderRadius: '10px',
                border: '1px solid black',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                width: '180px',
                height: '76px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: attemptingPull ? 'default' : 'pointer',
                color: buttonTextColor,
              }}
            >
              {excaliburFreed ? (
                <span style={{ fontSize: '20px' }}>
                  <b>Mint Ended</b>
                </span>
              ) : (
                <>
                  <span style={{ fontSize: '20px' }}>
                    <b>{attemptingPull ? "Attempting Pull" : "Pull Excalibur"}</b>
                  </span>
                  {!attemptingPull && (
                    <span style={{ fontSize: '14px' }}>
                      {SILVER_PENNY_MINT_PRICE.toString().replace(/^0+/, '')} ETH
                    </span>
                  )}
                </>
              )}
            </button>

          </div>

          <div
            id="knightImages"
            onClick={handleMineSilverPennies}
            onMouseOver={() => {
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
              }
              document.getElementById('imageWrapper').style.transform = 'scale(1.02)';
              document.getElementById('swordPullButton').style.backgroundColor = buttonHoverBackgroundColor;
              document.getElementById('swordPullButton').style.color = buttonHoverTextColor;
              setSwordButtonHovered(true);
            }}
            onMouseOut={() => {
              document.getElementById('swordPullButton').style.backgroundColor = buttonBackgroundColor;
              document.getElementById('swordPullButton').style.color = buttonTextColor;
              timeoutRef.current = setTimeout(() => {
                document.getElementById('imageWrapper').style.transform = 'scale(1)';
                setSwordButtonHovered(false);
              }, delayTime); // 500ms delay
            }}
          >
            <img
              src="/knight_1.png"
              alt="Knight Pixel Art"
              style={{
                ...knightStyle1,
                display: (attemptingPull || swordButtonHovered || gameWinner) ? 'none' : 'block',
              }}
            />
            <img
              src="/knight_2.png"
              alt="Knight Pixel Art"
              style={{
                ...knightStyle2,
                display: (attemptingPull || swordButtonHovered || gameWinner) ? 'block' : 'none',
              }}
            />

          </div>
          <br></br>
          <br></br>

        </div>

        <div
          style={{
            position: 'absolute',
            top: '22%', // Adjust this value to move the container up or down
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >


          <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px yellow' }}>
            {attemptingPull && !swordStaysPut && <div>You grasp the hilt and pull...</div>}
          </div>
          <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px red' }}>
            {swordStaysPut && !regularMiningRewardWon && !tokensMinted && !tryAgainMessage && <div>The sword refuses to budge.</div>}
          </div>
          <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px green' }}>
            {regularMiningRewardWon && <div>By the grace of God, thou hast been granted .2 ETH!</div>}
          </div>
          <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px blue' }}>
            {grandMiningRewardWon && <div>You won the Excalibur Mining Reward!</div>}
          </div>
          <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px lavender' }}>
            {randomMiningRewardWon && <div>You won the Round Table Reward!</div>}
          </div>
          <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px orange' }}>
            {tokensMinted && <div>+100 Silver Pennies</div>}
          </div>
          <div style={{
            fontSize: '32px',
            fontWeight: 'bold',
            textShadow: tryAgainOrCongrats ? '0 0 5px blue' : '0 0 5px brown'
          }}>
            {tryAgainMessage && !regularMiningRewardWon && (
              <div>{tryAgainOrCongrats ? "Congratulations!" : "Try again"}</div>
            )}
          </div>

          <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px tan' }}>
            {notEnoughFunds && <div>12 Silver Pennies required to duel</div>}
          </div>
          <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px purple' }}>
            {NFTMinted && <div>NFT Minted</div>}
          </div>
          <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px teal' }}>
            {mintReplicaTransactionSubmitted && <div>Transaction submitted! Waiting for confirmation...</div>}
          </div>
          <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px crimson' }}>
            {showTransactionErrorMessage && <div>There was a problem processing your transaction.</div>}
          </div>
          <div style={{ fontSize: '32px', fontWeight: 'bold', textShadow: '0 0 5px grey' }}>
            {showLoadingOnchainNFTMessage && <div>Requesting onchain data and building sword.</div>}
            {showLoadingOnchainNFTMessage && <div>This takes a minute.</div>}
          </div>

          <div style={{ fontSize: '32px', fontWeight: 'bold' }}>
            {displayDuelResults && duelResultsFromEvent && (
              <div>
                {duelResultsFromEvent.winningSword === duelResultsFromEvent.offensiveSword ?
                  <div style={{ textShadow: '0 0 5px green' }}>
                    Congratulations! Replica {duelResultsFromEvent.offensiveSword} has successfully bested Replica {duelResultsFromEvent.defensiveSword}
                  </div>
                  :
                  <div style={{ textShadow: '0 0 5px red' }}>
                    Ouch! Replica {duelResultsFromEvent.offensiveSword} was defeated by Replica {duelResultsFromEvent.defensiveSword}
                  </div>
                }
              </div>
            )}
          </div>
        </div>

        <div style={{ position: "fixed", textAlign: "left", left: 0, bottom: 15, padding: 10, zIndex: 999 }}>

          <Row align="middle" gutter={[4, 4]}>
            <Col span={24}>
              <span
                style={{
                  color: '#bdd12b', // Change the color as desired
                  fontFamily: 'Righteous', // Use the 'Bangers' font
                  textShadow: '1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000',
                }}
              >
                {/* <Balance
                size="48px"
                address={readContracts && readContracts.MerlinsGambitQ1 ? readContracts.MerlinsGambitQ1.address : null}
                provider={provider}
                price={price}
              /> */}
                <MyBalance size="48px" balance={contractBalance} balanceSinceUpdate={contractBalanceSinceUpdate} price={price} initialDollarMode={true} />

              </span>
            </Col>
          </Row>
          <Row align="middle" gutter={[4, 4]}>
            <Col span={24} style={{ textAlign: "center", marginTop: -12 }}>
              <span
                style={{
                  color: '#bdd12b', // Change the color as desired
                  fontFamily: 'Righteous', // Use the 'Bangers' font
                  textShadow: '1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000',
                }}
              >
                Excalibur Bounty
              </span>
            </Col>
          </Row>

        </div>

        {
          address && (

            <div
              style={{ position: "fixed", right: 8, bottom: 15, padding: 10, zIndex: 999 }}
              onMouseOver={handleMouseOverSilverPennies}
              onMouseOut={handleMouseOutSilverPennies}
            >

              <Row align="middle" gutter={[4, 4]} style={{ marginBottom: '10px' }} >
                <Col span={24} style={{
                  fontSize: "24px", // Change the color as desired
                  fontFamily: 'Righteous', // Use the 'Bangers' font
                  textShadow: '1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000',
                  color: '#d3e1d7',
                }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src="/silverPenny.png"
                      alt="Silver Penny"
                      style={{
                        width: "30px", marginRight: "8px", marginTop: "0px",
                      }} // Adjust width and marginRight as needed
                    />
                    {currentSpnyBalance
                      // ? Math.floor(utils.formatEther(currentSpnyBalance.toString())) + penniesMintedSinceUpdate
                      ? Math.floor(currentSpnyBalance) + penniesMintedSinceUpdate
                      : "loading..."} SPNY
                  </div>
                </Col>
              </Row>

              <Row align="middle" gutter={[4, 4]}>
                <Col span={24} style={{ textAlign: "center", marginTop: -12 }}>
                  <span
                    style={{
                      color: '#d3e1d7', // Change the color as desired
                      fontFamily: 'Righteous', // Use the 'Bangers' font
                      textShadow: '1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000',
                    }}
                  >
                    Silver Pennies Balance
                  </span>
                </Col>
              </Row>
            </div>
          )
        }

      </div >
    );
  }
}