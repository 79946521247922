import React, { useEffect } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";

export default function ThemeSwitcher() {
  const { switcher, themes } = useThemeSwitcher();

  useEffect(() => {
    switcher({ theme: themes.dark });
  }, [switcher, themes]);

  return null;
}

{/*}
import { Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";

export default function ThemeSwitcher() {
  const theme = window.localStorage.getItem("theme");
  //const [isDarkMode, setIsDarkMode] = useState(!(!theme || theme === "light"));
  const [isDarkMode, setIsDarkMode] = useState(true);
  const { switcher, currentTheme, themes } = useThemeSwitcher();

  useEffect(() => {
    window.localStorage.setItem("theme", currentTheme);
  }, [currentTheme]);

  const toggleTheme = isChecked => {
    setIsDarkMode(isChecked);
    switcher({ theme: isChecked ? themes.dark : themes.light });
  };

  return null;
*/}

  // return (
  //  <div className="main fade-in" style={{ position: "fixed", right: 8, bottom: 8 }}>
  //    <span style={{ padding: 8 }}>{currentTheme === "light" ? "☀️" : "🌜"}</span>
  //    <Switch checked={isDarkMode} onChange={toggleTheme} />
  //  </div>
  // );
//}
